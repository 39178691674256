import { SpinePlayer } from '@esotericsoftware/spine-player';
import React, { useEffect, useRef, useState } from 'react';
import useJourneyStore from '../../store/useJourneyStore';
import useGameStore from 'src/store/useGameStore';

const debug = false;
const SpineAnimation = ({
  atlasUrl,
  jsonUrl,
}: {
  atlasUrl: string;
  jsonUrl: string;
}) => {
  const { animationState, characterSpeed } = useJourneyStore();
  const { setIsCharacterLoading } = useGameStore();
  const [player, setPlayer] = useState<SpinePlayer | undefined>();
  const playerContainerRef = useRef(null);

  useEffect(() => {
    setIsCharacterLoading(true);
    if (
      playerContainerRef.current &&
      !player &&
      document.getElementById('player-container')?.childElementCount === 0
    ) {
      const player = new SpinePlayer('player-container', {
        jsonUrl: jsonUrl,
        atlasUrl: atlasUrl,
        animations: ['walk_flat', 'walk_uphill', 'walk_downhill'],
        showControls: false,
        preserveDrawingBuffer: true,
        defaultMix: 0.2,
        backgroundColor: '#00000000',
        alpha: true,
        viewport: {
          debugRender: debug,
        },
        debug: {
          bones: debug,
          regions: debug,
          meshes: debug,
          bounds: debug,
          paths: debug,
          clipping: debug,
          points: debug,
          hulls: debug,
        },
      });
      setPlayer(player);
    }

    setIsCharacterLoading(false);

    return () => {
      player?.dispose();
    };
  }, []);

  useEffect(() => {
    if (player) {
      try {
        player.setAnimation(animationState, true);
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [animationState]);

  useEffect(() => {
    if (player) {
      player.speed = characterSpeed;
    }
  }, [characterSpeed]);

  return (
    <div
      id="player-container"
      ref={playerContainerRef}
      style={{
        width: '130px',
        height: '130px',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
    />
  );
};
export default SpineAnimation;
