import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'src/utils';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  background-color: #0c3e35;
  color: white;
  padding: 20px;
  padding-bottom: 120px;
  max-width: 400px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  margin: auto;
`;
const BackButton = styled.button`
  position: fixed;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 8px;
  margin-bottom: 24px;
  margin-top: 50px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
`;

const SubTitle = styled.p`
  color: #c2efe2;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const DeviceOption = styled.div<{ selected: boolean }>`
  background-color: #ffffff;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  color: #2e2e33;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;

const Icon = styled.div<{ icon: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
  margin-right: 10px;
`;

const SubDeviceOption = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CustomRadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  &:checked {
    border-color: #d2d2d4;
  }

  &:checked::before {
    content: '';
    width: 8px; /* Adjust the size of the inner green circle */
    height: 8px; /* Adjust the size of the inner green circle */
    background-color: #0bab7c;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const GradientButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  background: linear-gradient(180deg, rgba(0, 66, 47, 0) 0%, #00422f 100%);
  padding: 24px 16px 56px 16px;
  display: flex;
  justify-content: center;
`;

const ContinueButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#C2EFE2' : '#0BAB7C')};
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  transition: background-color 0.3s;
`;

const IconTextContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.selected ? '16px' : '0px')};
`;

const devices = {
  libre: ['Abbott Libre 1', 'Abbott Libre 2', 'Abbott Libre 3'],
  dexcom: ['Dexcom G6', 'Dexcom G7'],
};

const icons = {
  libre: '/icons/icon-libre.svg', // replace with actual icon URL
  dexcom: '/icons/icon-dexcom.svg', // replace with actual icon URL
  other: '/icons/icon-cgm.svg', // replace with actual icon URL
  bgm: '/icons/icon-bgm.svg', // replace with actual icon URL
  nonDiabetic: '/icons/icon-dot3.svg', // replace with actual icon URL
};

const Signup = () => {
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const [selectedSubDevice, setSelectedSubDevice] = useState<string | null>(
    null,
  );
  const navigate = useNavigate();

  useEffect(() => {
    logEvent('view_web_signup_start', {});
  }, []);

  const handleSelectDevice = (device: string) => {
    setSelectedDevice(device);
    setSelectedSubDevice(null); // reset subdevice selection when changing device
  };

  const handleSelectSubDevice = (subDevice: string) => {
    setSelectedSubDevice(subDevice);
  };

  const isContinueDisabled = () => {
    if (!selectedDevice) return true;
    if (selectedDevice === 'libre' || selectedDevice === 'dexcom') {
      return !selectedSubDevice;
    }
    return false;
  };

  const handleContinueClick = () => {
    if (selectedDevice === 'libre') {
      // navigate(
      //   `/signup-check-libre-link-up?device=${selectedDevice}&subDevice=${selectedSubDevice}`,
      // );
      navigate(
        `/login?device=${selectedDevice}&subDevice=${selectedSubDevice}&isLibreLinkUpUser=true`,
      );
    } else {
      navigate(
        `/signup-form?device=${selectedDevice}&subDevice=${selectedSubDevice}`,
      );
    }
  };

  return (
    <Container>
      {/* <BackButton onClick={() => navigate(-1)}>← Back</BackButton> */}
      <TitleContainer>
        <Title>Please select the glucose monitoring device you use.</Title>
        <SubTitle>
          We currently support full gameplay experience only for LibreLinkUp
          users, but we are working hard to support all devices.
        </SubTitle>
      </TitleContainer>

      <DeviceOption
        selected={selectedDevice === 'libre'}
        onClick={() => handleSelectDevice('libre')}
      >
        <IconTextContainer selected={selectedDevice === 'libre'}>
          <Icon icon={icons.libre} />
          Freestyle Libre
        </IconTextContainer>
        {selectedDevice === 'libre' &&
          devices.libre.map((device) => (
            <SubDeviceOption
              key={device}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent onClick
                handleSelectSubDevice(device);
              }}
            >
              <CustomRadioButton
                type="radio"
                checked={selectedSubDevice === device}
                readOnly
              />
              {device}
            </SubDeviceOption>
          ))}
      </DeviceOption>

      <DeviceOption
        selected={selectedDevice === 'dexcom'}
        onClick={() => handleSelectDevice('dexcom')}
      >
        <IconTextContainer selected={selectedDevice === 'dexcom'}>
          <Icon icon={icons.dexcom} />
          Dexcom
        </IconTextContainer>
        {selectedDevice === 'dexcom' &&
          devices.dexcom.map((device) => (
            <SubDeviceOption
              key={device}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the parent onClick
                handleSelectSubDevice(device);
              }}
            >
              <CustomRadioButton
                type="radio"
                checked={selectedSubDevice === device}
                readOnly
              />
              {device}
            </SubDeviceOption>
          ))}
      </DeviceOption>

      <DeviceOption
        selected={selectedDevice === 'other'}
        onClick={() => handleSelectDevice('other')}
      >
        <IconTextContainer selected={false}>
          <Icon icon={icons.other} />
          Other CGM
        </IconTextContainer>
      </DeviceOption>

      <DeviceOption
        selected={selectedDevice === 'bgm'}
        onClick={() => handleSelectDevice('bgm')}
      >
        <IconTextContainer selected={false}>
          <Icon icon={icons.bgm} />
          Finger Prick (Strips)
        </IconTextContainer>
      </DeviceOption>

      <DeviceOption
        selected={selectedDevice === 'Non-diabetic'}
        onClick={() => handleSelectDevice('Non-diabetic')}
      >
        <IconTextContainer selected={false}>
          <Icon icon={icons.nonDiabetic} />
          Non-diabetic
        </IconTextContainer>
      </DeviceOption>

      <GradientButtonContainer>
        <ContinueButton
          disabled={isContinueDisabled()}
          onClick={handleContinueClick}
        >
          Continue
        </ContinueButton>
      </GradientButtonContainer>
    </Container>
  );
};

export default Signup;
