import { create } from 'zustand';
import { User, UserData } from '../models';
import { updateUser } from 'src/api/UserApiService';
import useGameStore from './useGameStore';

interface State {
  user: User | null;
  getState: () => State;
  setUser: (user: User) => void;
  setUserNotSync: (user: User | null) => void;
  logout: () => void;
}

export const DEFAULT_USER_DATA: UserData = {
  lastGlucoseTime: '',
  glucoseGraphData: [],
  currentGlucose: null,
  currentCoins: 0,
  lastEarnedDailyGlucoseRewardAt: 0,
  tasks: [],
  lifelogs: [],
  gameState: null,
  authTicket: null,
};

const useUserStore = create<State>((set, get) => ({
  user: null,
  setUser: async (newData: User | null) => {
    if (newData === null) {
      return;
    }
    // console.log('Updating user state:', newData);
    set((state) => ({ ...state, user: newData }));
    // Trigger API call when state is updated
    if (newData) {
      try {
        const response = await updateUser(newData);
        // console.log('API call response:', response);
      } catch (error) {
        console.error('API call error:', error);
      }
    }
  },
  setUserNotSync: (newData: User | null) => {
    set({ user: newData });
  },
  getState: () => get(),
  logout: () => {
    const { reset } = useGameStore.getState();
    reset();
    set({ user: null });
  },
}));

export default useUserStore;
