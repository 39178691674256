import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signup } from 'src/api/UserApiService';
import * as amplitude from '@amplitude/analytics-browser';
import useAuthStore from 'src/store/useAuthStore';
import { logEvent, setUserProperties } from 'src/utils';
import { setIsTutorialCompleted } from 'src/storage/user.localstorage';
import useUserStore, { DEFAULT_USER_DATA } from 'src/store/useUserStore';

const Container = styled.div`
  background-color: #00422f;
  color: white;
  padding: 20px;
  max-width: 400px;
  height: 100vh;
  margin: auto;
  position: relative;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

const SubTitle = styled.p`
  color: var(--Green-200, #c2efe2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const InputLabel = styled.label`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ isValid: boolean }>`
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0 24px 0;
  border: 1px solid ${({ isValid }) => (isValid ? '#ccc' : 'red')};
  border-radius: 8px;
  padding-right: 40px; /* space for the icon */
`;

const TogglePasswordIcon = styled.img`
  position: absolute;
  right: 12px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: var(--Red-300, #fa907f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: -20px;
  margin-bottom: 16px;
`;

const GradientButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  background: linear-gradient(180deg, rgba(0, 66, 47, 0) 0%, #00422f 100%);
  padding: 24px 16px 56px 16px;
  display: flex;
  justify-content: center;
`;

const ContinueButton = styled.button<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#C2EFE2' : '#0BAB7C')};
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

const BottomText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: #b4b4b8;
  margin-bottom: 8px;
`;

const SigninText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
`;

const SignUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const authStore = useAuthStore();
  const { user, setUser } = useUserStore();

  const device = searchParams.get('device');
  const subDevice = searchParams.get('subDevice');

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return (
      password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber
    );
  };

  const handleContinue = async (event: React.FormEvent) => {
    setEmailIsValid(validateEmail(email));
    setPasswordIsValid(validatePassword(password));

    if (validateEmail(email) && validatePassword(password)) {
      const result = await signup(email, password, subDevice || '');

      if (result.error && result.error.indexOf('duplicate key') !== -1) {
        setEmailIsValid(false);
        setEmailError('An account with this email address already exists.');
      } else {
        // alert('Account created successfully');
        // navigate('/login'); // Adjust the path as needed

        amplitude.setUserId(email);
        setUser({
          email,
          id: 0,
          data: DEFAULT_USER_DATA,
        });
        setUserProperties('userCgmType', `${device} ${subDevice}`);
        logEvent('login_success', { email: email });
        authStore.login(email);
        setIsTutorialCompleted(true);
        navigate('/');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickSignin = () => {
    navigate('/login');
  };

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>← Back</BackButton>
      <TitleContainer>
        <Title>Sign Up</Title>
        <SubTitle>
          Enter your email address and password to get started.
        </SubTitle>
      </TitleContainer>
      <form onSubmit={handleContinue}>
        <InputLabel>Email</InputLabel>
        <Input
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailIsValid(true);
            setEmailError('');
          }}
          isValid={emailIsValid}
        />
        {!emailIsValid && (
          <ErrorMessage>
            {emailError || 'Please enter a valid email address'}
          </ErrorMessage>
        )}
        <InputLabel>Password</InputLabel>
        <InputContainer>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isValid={passwordIsValid}
          />
          <TogglePasswordIcon
            src={
              showPassword
                ? '/icons/icon-eye.svg'
                : '/icons/icon-cancel-eye.svg'
            }
            alt="Toggle Password Visibility"
            onClick={(e) => {
              e.preventDefault();
              togglePasswordVisibility();
            }}
          />
        </InputContainer>
        {!passwordIsValid && (
          <ErrorMessage>
            Password must be at least 8 characters and contain one upper case,
            one lower case and a number.
          </ErrorMessage>
        )}
      </form>
      <BottomText>Already have an account?</BottomText>
      <SigninText onClick={handleClickSignin}>Log in</SigninText>
      <GradientButtonContainer>
        <ContinueButton disabled={!email || !password} onClick={handleContinue}>
          Continue
        </ContinueButton>
      </GradientButtonContainer>
    </Container>
  );
};

export default SignUp;
