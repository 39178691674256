import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GNB } from 'src/components/common/GNB';
import useAudio from 'src/hooks/useAudio';
import { WORLDS } from 'src/models/worlds';
import useGameStore from 'src/store/useGameStore';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled, { css, keyframes } from 'styled-components';

// Define the keyframes for the tilting animation
const tiltAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(-5deg);
  }
  50% {
    transform: translateY(-10px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(-5deg);
  }
`;

// Jump animation keyframes
const jumpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const COORDINATES = [
  {
    index: 0,
    left: '30%',
    bottom: 'calc(56px + 2%)',
  },
  {
    index: 1,
    left: '77%',
    bottom: 'calc(56px + 18%)',
  },
  {
    index: 2,
    left: '10%',
    bottom: 'calc(56px + 40%)',
  },
  {
    index: 3,
    left: '77%',
    bottom: 'calc(56px + 60%)',
  },
  {
    index: 4,
    left: '10%',
    bottom: 'calc(56px + 84%)',
  },
];

interface Props {
  isShowGNB?: boolean;
}

const Roadmap = ({ isShowGNB = true }: Props) => {
  const navigate = useNavigate();
  const { currentWorldIndex } = useGameStore();
  const [roadmapIndex, setRoadmapIndex] = useState(0);
  const roadmapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const displayName = WORLDS[currentWorldIndex].displayName;

    // if displayName starts with 'Honolulu', set the roadmapIndex to 1
    // if displayName starts with 'San Francisco', set the roadmapIndex to 2

    if (displayName.startsWith('Forest')) {
      setRoadmapIndex(0);
    } else if (displayName.startsWith('Hawaii')) {
      setRoadmapIndex(1);
    } else if (displayName.toLowerCase().startsWith('san francisco')) {
      setRoadmapIndex(2);
    } else if (displayName.startsWith('Future')) {
      setRoadmapIndex(4);
    }
  }, [currentWorldIndex]);

  const [isJumping, setIsJumping] = useState(false);
  const [isDuckPlaying, toggleDuck] = useAudio('/audio/duck.wav', false);
  const { user } = useUserStore();

  const handleDuckClick = () => {
    logEvent('touch_duck_roadmap', user);
    setIsJumping(true);
    toggleDuck();
    setTimeout(() => setIsJumping(false), 500); // Reset jumping state after animation
  };

  useEffect(() => {
    // when loaded, start at the bottom of the page
    window.scrollTo(0, document.body.scrollHeight);
    if (roadmapRef.current) {
      roadmapRef.current.scrollTop = roadmapRef.current.scrollHeight;
    }
    logEvent('view_web_roadmap', user);
  }, []);

  const handleClickSection = (index: number) => () => {
    if (index > roadmapIndex) return;
    navigate('/');
  };

  return (
    <Div isShowGNB={isShowGNB} ref={roadmapRef}>
      <ImageContainer>
        <Row showBackground={roadmapIndex >= 4} onClick={handleClickSection(4)}>
          <img src="/roadmap/future.svg" alt="" />
        </Row>
        <Row showBackground={roadmapIndex >= 3} onClick={handleClickSection(3)}>
          <img src="/roadmap/locked.svg" alt="" />
        </Row>
        <Row showBackground={roadmapIndex >= 2} onClick={handleClickSection(2)}>
          <img src="/roadmap/sanfrancisco.svg" alt="" />
        </Row>
        <Row showBackground={roadmapIndex >= 1} onClick={handleClickSection(1)}>
          <img src="/roadmap/honolulu.svg" alt="" />
        </Row>
        <Row showBackground={roadmapIndex >= 0} onClick={handleClickSection(0)}>
          <img src="/roadmap/start.svg" alt="" />
        </Row>
        <DuckImage
          src={'/roadmap/duck_top.svg'}
          alt=""
          isJumping={isJumping}
          onClick={handleDuckClick}
          coordinates={
            COORDINATES.find((c) => c.index === roadmapIndex) || COORDINATES[0]
          }
        />
      </ImageContainer>
      {isShowGNB && <GNB />}
    </Div>
  );
};

export default Roadmap;

const Div = styled.div<{ isShowGNB?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100dvh;
  overflow-y: scroll;
  margin-bottom: ${(props) => (props.isShowGNB ? '56px' : '0')};
  position: relative;
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  position: relative;
  background: url('/roadmap/background.svg');
  background-size: cover;
`;
const Row = styled.div<{
  showBackground?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  /* if showBackground is true, show the background image. Else, make it grayscale and make the background a grayish color. */
  background: ${({ showBackground }) =>
    showBackground ? 'transparent' : '#dedede '};

  img {
    width: 100%;
    object-fit: contain;

    /* if showBackground is false, make the image grayscale */
    filter: ${({ showBackground }) =>
      showBackground ? 'none' : 'grayscale(1)'};
  }
`;

const DuckImage = styled.img<{
  coordinates: {
    left: string;
    bottom: string;
  };
  isJumping: boolean;
}>`
  position: absolute;
  left: ${({ coordinates }) => coordinates.left};
  bottom: ${({ coordinates }) => coordinates.bottom};
  width: 48px;
  height: 48px;
  object-fit: contain;
  z-index: 1;
  cursor: pointer;
  animation: ${({ isJumping }) =>
    isJumping
      ? css`
          ${jumpAnimation} 0.5s ease
        `
      : css`
          ${tiltAnimation} 1s infinite ease-in-out
        `};
`;
