import React from 'react';
import { useParams } from 'react-router-dom';
import { ActivityLog } from './ActivityLog';
import { MealLog } from './MealLog';
import { MedicineLog } from './MedicineLog';
import { GlucoseLevelLog } from './GlucoseLevelLog';

const Lifelog = () => {
  // useParams
  const { type } = useParams();

  // re-route to different log pages based on type
  if (type === 'meal') {
    return <MealLog />;
  } else if (type === 'activity') {
    return <ActivityLog />;
  } else if (type === 'medicine') {
    return <MedicineLog />;
  } else if (type === 'glucoseLevel') {
    return <GlucoseLevelLog />;
  } else {
    return <div>404</div>;
  }
};

export default Lifelog;
