import { LifelogData, User } from 'src/models';
import { get, post, put, remove } from './ApiService';

export const getLifelog = async (
  email: string,
  type: string,
): Promise<Array<LifelogData>> => {
  return await get<Array<LifelogData>>(`/lifelog?email=${email}&type=${type}`);
};

export const updateLifelog = async (
  email: string,
  data: Partial<LifelogData>,
): Promise<User> => {
  return await post<User>(`/lifelog?email=${email}`, data);
};
