import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from './store/useAuthStore';

const PrivateRoute = () => {
  const { isLoggedIn } = useAuthStore();

  if (isLoggedIn()) {
    return <Outlet />;
  }

  return <Navigate to="/waitlist" replace />;
};

export default PrivateRoute;
