import React from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Container = styled.div`
  background-color: #00422f;
  color: white;
  padding: 20px;
  max-width: 400px;
  height: 100vh;
  margin: auto;
  position: relative;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
`;

const SubTitle = styled.p`
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Button = styled.button`
  background-color: white;
  color: black;
  padding: 16px;
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
`;

const CheckLibreLinkUp = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const device = searchParams.get('device');
  const subDevice = searchParams.get('subDevice');

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>← Back</BackButton>
      <TitleContainer>
        <Title>Do you have a LibreLinkUp account?</Title>
        <SubTitle>
          If so, let’s go ahead and sign in now. If not, head over to Libre
          Linkup and create an account to log in to Sugar Walkin'.
        </SubTitle>
      </TitleContainer>
      <Button
        onClick={() => {
          alert('Sign in with your Libre Link Up account');
          navigate('/login');
        }}
      >
        Yes, I do
      </Button>
      <Button onClick={() => navigate('/signup-form')}>
        No, I don’t / I'm not sure
      </Button>
    </Container>
  );
};

export default CheckLibreLinkUp;
