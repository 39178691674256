import React from 'react';
import styled from 'styled-components';

interface SyncPopupProps {
  handler: () => void;
}

export const SyncPopup: React.FC<SyncPopupProps> = ({ handler }) => {
  return (
    <Div>
      <Inner>
        <Text>Looks like you've updated your glucose levels!</Text>
        <Button
          onClick={() => {
            handler();
            console.log('Sync');
          }}
        >
          Start Journey
        </Button>
      </Inner>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - 2rem);
  height: 290px;
  margin: 0 auto;
  padding: 64px 24px;
  border-radius: 40px;

  backdrop-filter: blur(10px);
  background: linear-gradient(
      0deg,
      rgba(131, 131, 131, 0.3),
      rgba(131, 131, 131, 0.3)
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  border: 2px solid white;
`;
const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: white;

  margin-bottom: 16px;
`;
const Button = styled.button`
  display: flex;
  flex-direction: column;
  background-color: #0bab7c;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #0bab7c4d;
  border-radius: 14px;

  width: 100%;
  height: 48px;
  padding: 12px 20px;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fbfaf3;
`;
