export const WORLDS = [
  {
    displayName: 'Forest 1',
    distance: 0.01,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['bush_sprout_day', 'flower_sprout_day', 'tree_sprout_day'],
      platform: 'grass_day',
      planet: 'sun',
    },
  },
  {
    displayName: 'Forest 2',
    distance: 0.02,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['bush_medium_day', 'flower_medium_day', 'tree_medium_day'],
      platform: 'grass_day',
      planet: 'sun',
    },
  },
  {
    displayName: 'Forest 3',
    distance: 0.04,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['bush_day', 'flower_day', 'tree_day'],
      platform: 'grass_day',
      planet: 'sun',
    },
  },
  {
    displayName: 'Forest Night 1',
    distance: 0.08,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'bush_sprout_night',
        'flower_sprout_night',
        'tree_sprout_night',
      ],
      platform: 'grass_night',
      planet: 'moon_crescent',
    },
  },
  {
    displayName: 'Forest Night 2',
    distance: 0.12,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'bush_medium_night',
        'flower_medium_night',
        'tree_medium_night',
      ],
      platform: 'grass_night',
      planet: 'moon_half',
    },
  },
  {
    displayName: 'Forest Night 3',
    distance: 0.18,
    sprites: {
      sky: 'sky_night',
      bgLayers: ['bush_night', 'flower_night', 'tree_night'],
      platform: 'grass_night',
      planet: 'moon_full',
    },
  },
  {
    displayName: 'Hawaii 1',
    distance: 0.4,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_S_plant', 'hawaii_S_flower', 'hawaii_S_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 2',
    distance: 0.5,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_M_plant', 'hawaii_S_flower', 'hawaii_S_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 3',
    distance: 0.6,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_M_plant', 'hawaii_M_flower', 'hawaii_S_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 4',
    distance: 0.8,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_M_plant', 'hawaii_M_flower', 'hawaii_M_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 5',
    distance: 1,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_L_plant', 'hawaii_M_flower', 'hawaii_M_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 6',
    distance: 1.2,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_L_plant', 'hawaii_L_flower', 'hawaii_M_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii 7',
    distance: 1.4,
    sprites: {
      sky: 'sky_day',
      bgLayers: ['hawaii_L_plant', 'hawaii_L_flower', 'hawaii_L_tree'],
      platform: 'hawaii_ground',
      planet: 'sun',
    },
  },
  {
    displayName: 'Hawaii Night 1',
    distance: 1.6,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_S_plant_night',
        'hawaii_S_flower_night',
        'hawaii_S_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_crescent',
    },
  },
  {
    displayName: 'Hawaii Night 1',
    distance: 1.8,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_M_plant_night',
        'hawaii_M_flower_night',
        'hawaii_S_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_crescent',
    },
  },
  {
    displayName: 'Hawaii Night 2',
    distance: 1.8,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_M_plant_night',
        'hawaii_M_flower_night',
        'hawaii_S_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_crescent',
    },
  },
  {
    displayName: 'Hawaii Night 3',
    distance: 1.8,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_M_plant_night',
        'hawaii_M_flower_night',
        'hawaii_M_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_half',
    },
  },
  {
    displayName: 'Hawaii Night 4',
    distance: 1.8,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_L_plant_night',
        'hawaii_L_flower_night',
        'hawaii_M_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_half',
    },
  },
  {
    displayName: 'Hawaii Night 5',
    distance: 20,
    sprites: {
      sky: 'sky_night',
      bgLayers: [
        'hawaii_L_plant_night',
        'hawaii_L_flower_night',
        'hawaii_L_tree_night',
      ],
      platform: 'hawaii_ground_night',
      planet: 'moon_full',
    },
  },
];
