import styled, { keyframes, css } from 'styled-components';
import { OnboardingNextProps } from './';
import CircleProfile from '../../components/common/CircleProfile';
import React from 'react';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  height: 100dvh;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin: 20px 0;
`;

const Description = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: #666;
  margin: 10px 0 30px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
  margin: auto;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

export const ActionButton = styled.button<{
  isAnimated?: boolean;
  isLoading?: boolean;
}>`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  background-color: #0bab7c;
  border: none;
  border-bottom: 4px solid #008356;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none; // prevent text selection
  margin: 1rem auto;
  display: flex; // flex를 사용하여 스피너와 텍스트를 중앙에 정렬합니다.
  align-items: center;
  justify-content: center;
  position: relative; // 스피너를 중앙에 배치하기 위해 필요합니다.

  &:active,
  &.pressed {
    border-bottom: 1px solid #008356;
    transform: translateY(
      3px
    ); // gives the effect of the button being pressed down
  }

  &:focus {
    outline: none; // removes outline on focus for a cleaner look
  }

  &:disabled {
    background-color: #ccc;
    color: white;
    cursor: not-allowed;
    background: var(--Green-200, #c2efe2);
    box-shadow: 0px 4px 20px 0px rgba(11, 171, 124, 0.3);
    border-bottom: 4px solid #a2cfc2;
  }

  animation: ${(props) =>
    props.isAnimated
      ? css`
          ${pulseAnimation} 1s infinite
        `
      : 'none'};

  ${(props) =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      color: transparent; // 텍스트를 숨깁니다.
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-top: 2px solid white;
        border-radius: 50%;
        animation: ${spin} 1s linear infinite;
      }
    `}
`;

const PrivacyLink = styled.a`
  font-size: 14px;
  color: lightgray;
  text-decoration: none;
`;

const EntrancePage = ({ onClickNext }: OnboardingNextProps) => {
  return (
    <PageContainer>
      <Title>Glucopet</Title>
      <CircleProfile
        imageUrl="pets/img-duck-front.png"
        size={120}
        margin={16}
      />
      <Description>
        your diabetes best friend,
        <br />a continuous glucose pet
      </Description>
      <ActionButton
        style={{ marginTop: '100px' }}
        onClick={onClickNext}
        isAnimated={true}
      >
        <span>Meet your new friend</span>
      </ActionButton>
      <PrivacyLink href="https://endohealth.ai/legal/privacy">
        privacy policy
      </PrivacyLink>
    </PageContainer>
  );
};

export default EntrancePage;
