import { AuthTicket } from '../models';

export const setLocalStorageAuthTicket = (authTicket: AuthTicket) => {
  localStorage.setItem('authTicket', JSON.stringify(authTicket));
};

export const getLocalStorageAuthTicket = (): AuthTicket | null => {
  const authTicket = localStorage.getItem('authTicket');
  if (authTicket) {
    return JSON.parse(authTicket);
  }
  return null;
};

export const removeLocalStorageAuthTicket = () => {
  localStorage.removeItem('authTicket');
};

export const setLocalStorageEmail = (email: string) => {
  localStorage.setItem('email', email);
};

export const getLocalStorageEmail = (): string | null => {
  const email = localStorage.getItem('email');
  if (email) {
    return email;
  }
  return null;
};

export const setIsTutorialCompleted = (isTutorialCompleted: boolean) => {
  localStorage.setItem(
    'isTutorialCompleted',
    JSON.stringify(isTutorialCompleted),
  );
};

export const getIsTutorialCompleted = (): boolean | null => {
  const isTutorialCompleted = localStorage.getItem('isTutorialCompleted');
  if (isTutorialCompleted) {
    return JSON.parse(isTutorialCompleted);
  }
  return null;
};
