import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import Onboarding from './pages/Onboarding';
import Character from './pages/Character';
import Home from './pages/Home';
import JourneyPage from './pages/Journey/JourneyPage';
import Profile from './pages/Profile';
import LinkupLoginPage from './pages/LinkupLogin';
import Lifelog from './pages/Lifelog';
import Roadmap from './pages/Roadmap';
import Login from './pages/Login';
import Shop from './pages/Shop';
import useGameStore from './store/useGameStore';
import useUserStore from './store/useUserStore';
import PrivateRoute from './PrivateRoute';
import { getUser, updateUserGameState } from './api/UserApiService';
import { getLocalStorageEmail } from './storage/user.localstorage';
import Signup from './pages/Signup';
import SignUpForm from './pages/Signup/SignUpFormPage';
import CheckLibreLinkUp from './pages/Signup/CheckLibreLinkUp';
import useAuthStore from './store/useAuthStore';
import ReportPage from './pages/Report';
import TravelTutorialPage from './pages/Onboarding/TravelTutorialPage';
import LogRocket from 'logrocket';
import WaitListPage from './pages/WaitList';
import TycoonReport from './pages/TycoonReport';

function App() {
  const { handleTick, initialize, resum, initialized, isPaused } =
    useGameStore();
  const { isLoggedIn: checkIsLoggedIn, loginWithLibreLinkUp } = useAuthStore();
  const email = getLocalStorageEmail();
  const isLoggedIn = checkIsLoggedIn();
  const {
    user,
    getState: getUserState,
    setUserNotSync,
    logout,
  } = useUserStore();
  const { getState } = useGameStore();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoggedIn) {
        return;
      }
      handleTick();
    }, 500);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [isLoggedIn, isPaused, initialized]);

  useEffect(() => {
    if (!email) {
      return;
    }
    getUser(email).then((res) => {
      console.log('initializing user', getState().initialized);
      // console.log(res?.authTicket);
      if (res?.authTicket && email) {
        loginWithLibreLinkUp(res.authTicket, email);
      }

      setUserNotSync(res);
      initialize(res?.gameState || res?.data?.gameState || {});
      console.log('iniialized? ', getState().initialized);
    });
  }, [email]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { coins, initialized, isPaused } = getState();
      const { user: userState } = getUserState();
      if (initialized && !isLoggedIn) {
        logout();
        return;
      }
      if (email && userState && coins !== 0 && initialized && !isPaused) {
        updateUserGameState(email, getState()).then((res) => {
          console.log('updated game state');
        });
      }
    }, 1000 * 5);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [initialized, isLoggedIn, user, email]);

  useEffect(() => {
    const email = getLocalStorageEmail();
    if (isLoggedIn && email) {
      LogRocket.identify('SUGAR_WALKIN_USER', {
        email: email,
      });
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup-form" element={<SignUpForm />} />
          <Route
            path="/sugar-master"
            element={<WaitListPage gameName="Sugar Master" />}
          />
          <Route
            path="/sugar-cat"
            element={<WaitListPage gameName="Sugar Cat" />}
          />
          <Route
            path="/sugar-tycoon"
            element={<WaitListPage gameName="Sugar Tycoon" />}
          />
          <Route
            path="/waitlist"
            element={<WaitListPage gameName="This game" />}
          />
          <Route
            path="/signup-check-libre-link-up"
            element={<CheckLibreLinkUp />}
          />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/login" element={<Login />} />
          <Route path="/tycoon-report" element={<TycoonReport />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/journey" element={<JourneyPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/lifelog/:type" element={<Lifelog />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/character" element={<Character />} />
            <Route path="/libre-login" element={<LinkupLoginPage />} />
            <Route
              path="/worlds-test"
              element={<TravelTutorialPage onClickNext={() => {}} />}
            />
          </Route>
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  max-width: 500px;
  margin: 0 auto;
`;
