import { AuthTicket, User } from 'src/models';
import { get, post, put, remove } from './ApiService';
import { GameState } from 'src/store/useGameStore';
import { v4 as uuidv4 } from 'uuid';

export const createUser = async (user: Partial<User>): Promise<User> => {
  return await post<User>(`/users/walk?email=${user.email}`, user);
};

export const getUser = async (
  email: string,
): Promise<User & { gameState: GameState; authTicket: AuthTicket }> => {
  return await get<any>(`/users/walk?email=${email}`);
};

export const updateUser = async (user: Partial<User>): Promise<User> => {
  return await post<User>(`/users/walk?email=${user.email}`, user.data);
};

export const updateUserGameState = async (
  email: string,
  gameState: Partial<GameState>,
): Promise<User> => {
  return await post<User>(`/users/walk/game-state?email=${email}`, gameState);
};

export const signup = async (
  email: string,
  password: string,
  userCgmType?: string,
): Promise<LoginResponse> => {
  return await post<LoginResponse>('/users/walk/signup', {
    email,
    password,
    userCgmType,
  });
};

export const signupAnonymous = async (
  petName: string,
  userCgmType?: string,
): Promise<LoginResponse> => {
  const uniqueId = uuidv4().replace(/-/g, '').slice(0, 10); // Remove dashes and take the first 10 characters
  const annoymousEmail = `${petName}@${uniqueId}`;
  return await post<LoginResponse>('/users/walk/signup', {
    email: annoymousEmail,
    userCgmType,
  });
};

export const login = async (email: string, password: string): Promise<any> => {
  return await post<any>('/users/walk/login', {
    email,
    password,
  });
};

export interface LoginResponse {
  message: string;
  error?: string;
  user: Partial<User>;
}
