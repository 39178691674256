import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OnboardingQueryParams } from './Onboarding';
import { useNavigate, useParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { LoginResponse, User } from 'src/models';
import { colors } from 'src/components/colors';
import CircleProfile from 'src/components/common/CircleProfile';
import { libreLinkupLogin } from 'src/api/LibreProxyApiService';
import useAuthStore from 'src/store/useAuthStore';
import useUserStore from 'src/store/useUserStore';
import { createUser, updateUser } from 'src/api/UserApiService';
import { VERSION } from 'src/environment';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100dvh;
  width: 100%;
  background-color: white;
`;

const LoginHeader = styled.h1`
  color: #333;
  font-size: 28px;
  text-align: center;
  margin-bottom: 5%;
`;

const Input = styled.input`
  width: 85%;
  padding: 12px;
  margin: 10px 0;
  font-size: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-family: 'Mouse Memoirs, sans-serif';

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0px 0px 8px rgba(50, 50, 255, 0.5);
  }
`;

const Button = styled.button`
  width: 85%;
  padding: 15px;
  margin: 0.5rem auto;
  font-size: 1.5rem;
  background-color: ${colors.primary};
  color: white;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const AccountButton = styled.button`
  background: none;
  color: #afafaf;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const Select = styled.select`
  width: 85%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.5rem;
  border-radius: 1rem;
  margin: 0.5rem auto;

  option {
    font-size: 1.5rem;
    border-radius: 1rem;
    font-family: 'Patrick Hand, Mouse Memoirs, sans-serif';
  }
`;

const LinkupLoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [petsName, setPetsName] = useState('');
  const [message, setMessage] = useState('');

  const authStore = useAuthStore();

  const { petName } = useParams() as Partial<OnboardingQueryParams>;

  useEffect(() => {
    if (petName) {
      setPetsName(petName);
    }
  }, [petName]);

  const handleLogin = async (
    email: string,
    password: string,
    country: string = 'US',
  ) => {
    const data = await libreLinkupLogin(email, password, country);
    const createUserResponse = await createUser({ email });
    return data;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const data = await handleLogin(email, password);
      // Assuming status 0 indicates success
      if (data.status === 0 && data.data?.authTicket) {
        console.log('Login successful, data: ', data);
        // Set user details and token
        amplitude.setUserId(email);
        amplitude.logEvent('login_success', {
          email: email,
          version: VERSION,
        });
        authStore.loginWithLibreLinkUp(data.data.authTicket, email);

        // navigate('/home', { replace: true });
      } else {
        setMessage('Login failed');
      }
    } catch (error) {
      console.error('Failed to submit:', error);
      setMessage('Login failed! try again later');
    }
  };

  return (
    <PageContainer>
      <LoginHeader>{petsName} wants to know your glucose levels.</LoginHeader>
      <CircleProfile
        imageUrl="/pets/img-duck-front.png"
        size={200}
        margin={16}
      />
      <Input
        style={{
          fontFamily: 'Patrick Hand, Mouse Memoirs, sans-serif',
          letterSpacing: '1px',
        }}
        type="text"
        placeholder="Username"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        style={{
          fontFamily: 'Patrick Hand, Mouse Memoirs, sans-serif',
          letterSpacing: '1px',
        }}
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        onClick={handleSubmit}
        disabled={!email || !password}
      >
        Login
      </Button>
      <AccountButton onClick={() => navigate('/home', { replace: true })}>
        I don't have a LibreLinkUp account
      </AccountButton>
      {message && (
        <p
          style={{
            textAlign: 'center',
            color: 'rgb(230,70,30)',
            marginTop: '12px',
          }}
        >
          {message}
        </p>
      )}
    </PageContainer>
  );
};

export default LinkupLoginPage;
