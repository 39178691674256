import { create } from 'zustand';
import { AuthTicket } from '../models';
import {
  getLocalStorageAuthTicket,
  getLocalStorageEmail,
  removeLocalStorageAuthTicket,
  setLocalStorageAuthTicket,
  setLocalStorageEmail,
} from '../storage/user.localstorage';

interface State {
  isLoggedIn: () => boolean;
  token: () => string;
  loginWithLibreLinkUp: (authTicket: AuthTicket, email: string) => void;
  login: (email: string) => void;
  logout: () => void;
}

const useAuthStore = create<State>((set) => ({
  isLoggedIn: () => !!getLocalStorageEmail(),
  token: () => {
    const authTicket = getLocalStorageAuthTicket();
    return authTicket?.token || '';
  },
  loginWithLibreLinkUp: (authTicket: AuthTicket, email: string) =>
    set((state) => {
      setLocalStorageAuthTicket(authTicket);
      setLocalStorageEmail(email);
      // console.log('loginWithLibreLinkUp - authStore');
      return {
        ...state,
      };
    }),
  login: (email: string) => {
    setLocalStorageEmail(email);
  },
  logout: () => {
    removeLocalStorageAuthTicket();
  },
}));

export default useAuthStore;
