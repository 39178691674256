import React, { useEffect, useState } from 'react';
import usePlayerStore from 'src/store/usePlayerStore';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

export const PetInfo = () => {
  const { pet, dailyStreak, maxDailyStreak } = usePlayerStore();
  const navigate = useNavigate();

  const [age, setAge] = useState(0);
  useEffect(() => {
    // set age = today - pet.birthday in days / weeks / months - whatever is most appropriate

    // Calculate age in days
    const today = new Date();
    const birthday = new Date(pet.birthday);
    const diffTime = Math.abs(today.getTime() - birthday.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setAge(diffDays);
  }, []);

  return (
    <Div>
      <LevelContainer>
        <LevelText>Level {pet.level}</LevelText>
      </LevelContainer>
      <ContentContainer>
        <LeftContainer>
          <NameContainer>
            <NameText>{pet.name}</NameText>
          </NameContainer>
          <DailyStreakContainer>
            <DailyStreakIcon src="/pets/medal.svg" alt="" />
            <DailyStreakText>{dailyStreak} day streak</DailyStreakText>
          </DailyStreakContainer>
        </LeftContainer>
        <ImageContainer>
          <img src={'/pets/dummy.png'} alt="" />
        </ImageContainer>
      </ContentContainer>
      <InfoContainer>
        <InfoRow>
          <KeyText>Age</KeyText>
          <ValueText>{age} days old</ValueText>
        </InfoRow>
        <InfoRow>
          <KeyText>Max Streak</KeyText>
          <ValueText>{maxDailyStreak} days 🎉</ValueText>
        </InfoRow>
      </InfoContainer>
      <ButtonContainer>
        <Button>Upgrade</Button>
        <RequirementContainer></RequirementContainer>
      </ButtonContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 60px);
  margin: 2rem auto 1rem auto;
  position: relative;
  border: 3px solid #000;
  border-radius: 30px;
  background-color: #fafafa;
  padding-bottom: 3rem;
`;
const LevelContainer = styled.div`
  position: absolute;
  top: -1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  height: 2rem;
  min-width: 6rem;
`;
const LevelText = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 8rem);
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 8rem;

  img {
    width: calc(100% - 1rem);
    object-fit: contain;
  }
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
`;
const NameText = styled.p`
  color: #0b6579;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4rem);
  margin: 0 auto;
`;
const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0.45rem auto;
`;
const KeyText = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 8rem;
  color: #8fb6bf;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin: 0;
`;
const ValueText = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 8rem);
  color: #0b6579;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin: 0;
`;
const DailyStreakContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.875rem 0.375rem 0.625rem;
  border: none;
  border-radius: 1.125rem;
  background: rgba(99, 160, 179, 0.85);
`;
const DailyStreakIcon = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  object-fit: contain;
  margin-right: 0.625rem;
`;
const DailyStreakText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
`;
const ButtonContainer = styled.div`
  position: absolute;
  bottom: -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Button = styled.button`
  color: #fff;
  border: none;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  height: 4rem;
  min-width: 6rem;
  font-size: 20px;
  font-weight: bold;
  background-color: #007bff;
  border-bottom: 4px solid #0056b3;
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: uppercase;

  &:active,
  &.pressed {
    border-bottom: 1px solid #0056b3;
    transform: translateY(
      3px
    ); // gives the effect of the button being pressed down
  }

  &:focus {
    outline: none; // removes outline on focus for a cleaner look
  }
`;
const RequirementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
`;
