import { GlucoseGraphRecord, User } from '../models';

export const formatDate = (d: Date) => {
  return `${
    d.getMonth() + 1
  }/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
};

export const getMissionTier = (currentGlucose: number) => {
  // Tier is S, A, B, C, D.
  switch (true) {
    case currentGlucose >= 220:
      return 'S';
    case currentGlucose >= 190:
      return 'A';
    case currentGlucose >= 160:
      return 'B';
    case currentGlucose >= 140:
      return 'C';
    default:
      return 'D';
  }
};

export const updateCoins = (user: User, coins: number) => {
  return {
    ...user,
    data: {
      ...user.data,
      currentCoins: user.data?.currentCoins || 0 + coins,
    },
  };
};

// Function to determine glucose trend by returning the difference between the last two values
export function determineGlucoseTrend(records: GlucoseGraphRecord[]): number {
  // Filter out records with null values
  const validRecords = records.filter((record) => record.Value !== null);

  // Check if we have at least 2 valid records
  if (validRecords.length < 2) {
    return 0;
  }

  // Get the last two records
  const lastTwoRecords = validRecords.slice(-2);

  // Extract the values
  const [first, second] = lastTwoRecords.map(
    (record) => record.Value as number,
  );

  // Determine the change
  const change = second - first;

  return change;
}

export function numberToGameUnits(num: number): string {
  if (num < 9999) return num.toString();

  const units: string[] = [];
  let current = num;
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  while (current >= 1000) {
    const remainder = current % 1000;
    units.push(remainder.toString().padStart(3, '0')); // Always pad the remainders
    current = Math.floor(current / 1000);
  }

  units.push(current.toString());

  let result = '';
  for (let i = 0; i < units.length; i++) {
    if (i === 0) {
      result = units[i];
    } else {
      result = units[i] + alphabet[i - 1] + result;
    }
  }

  return trimAfterFirstAlphabet(result);
}

function trimAfterFirstAlphabet(input: string): string {
  const regex = /[a-z]/; // Match any lowercase alphabet
  const match = input.match(regex);

  if (match) {
    const index = match.index! + 1; // Include the alphabet itself
    return input.slice(0, index);
  }

  return input; // If no alphabet is found, return the original string
}
