import { WORLDS } from 'src/models/worlds';
import { create } from 'zustand';

interface Mission {
  id: number;
  description: string;
  completed: boolean;
}

export interface World {
  displayName: string;
  distance: number;
  sprites: {
    sky: string;
    bgLayers: string[];
    platform: string;
    planet: string;
  };
}

export interface GameState {
  xp: number;
  coins: number;
  missions: Mission[];
  journeyActive: boolean;
  journeyEndTime: number | null; // UNIX timestamp for when the journey ends
  displayCharacterSpeed: number;
  currentWorldIndex: number;
  totalDistanceTraveled: number;
  currentDistanceTraveled: number;
  currentProgress: number;
  currentLeftDistance: number;
  tickCoins: number;
  isPaused: boolean;
  isBackgroundLoading: boolean;
  isCharacterLoading: boolean;
  initialized: boolean;
  isShowNextWorldModal: boolean;
  isShowJourneyRewardAnimation: boolean;
  journeyReward: number;
  isLoading: () => boolean;
  addXp: (amount: number) => void;
  handleTick: () => void;
  addCoins: (amount: number) => void;
  completeMission: (missionId: number) => void;
  startJourney: (duration: number) => void; // duration in milliseconds
  endJourney: () => void;
  setDisplayCharacterSpeed: (speed: number) => void;
  pause: () => void;
  resum: () => void;
  setIsBackgorundLoading: (loading: boolean) => void;
  setIsCharacterLoading: (loading: boolean) => void;
  initialize: (initialData: Partial<GameState>) => void;
  setIsShowNextWorldModal: (show: boolean) => void;
  setIsJourneyRewardAnimation: (show: boolean) => void;
  setJourneyReward: (minutes: number) => void;
  resetJourneyReward: () => void;
  getState: () => GameState;
  petName: string;
  setPetName: (name: string) => void;
  reset: () => void;
}

const DEFAULT_DISPLAY_CHARACTER_SPEED = 6;

const useGameStore = create<GameState>((set, get) => ({
  xp: 0,
  coins: 500,
  missions: [
    { id: 1, description: 'Complete a 10-minute meditation', completed: false },
    { id: 2, description: 'Read a book for 20 minutes', completed: false },
  ],
  journeyActive: false,
  journeyEndTime: null,
  displayCharacterSpeed: DEFAULT_DISPLAY_CHARACTER_SPEED,
  currentWorldIndex: 0,
  totalDistanceTraveled: 0,
  currentDistanceTraveled: 0,
  currentProgress: 0,
  currentLeftDistance: 0,
  tickCoins: 1.5 / DEFAULT_DISPLAY_CHARACTER_SPEED,
  isPaused: false,
  isBackgroundLoading: true,
  isCharacterLoading: true,
  initialized: false,
  isShowNextWorldModal: false,
  isShowJourneyRewardAnimation: false,
  journeyReward: 0,
  handleTick: () => {
    const state = get();
    // console.log('tick ', state);
    if (state.isPaused || !state.initialized) {
      return;
    }
    const currentWorld = WORLDS[state.currentWorldIndex];
    const distanceIncrement = state.displayCharacterSpeed / 7200; // Assume speed is in units per second

    const newTotalDistanceTraveled =
      state.totalDistanceTraveled + distanceIncrement;
    const newCurrentDistanceTraveled =
      state.currentDistanceTraveled + distanceIncrement;

    let newCurrentWorldIndex = state.currentWorldIndex;
    let newCurrentDistance = newCurrentDistanceTraveled;
    let newTickCoins = state.tickCoins;

    let isShowNextWorldModal = false;
    // Check if the current world has been fully traveled
    if (newCurrentDistanceTraveled >= currentWorld.distance) {
      newCurrentWorldIndex += 1;
      newCurrentDistance = 0;
      newTickCoins *= 1.1;
      isShowNextWorldModal = true;
    }

    const newCurrentProgress = newCurrentDistance / currentWorld.distance;
    const newCurrentLeftDistance = currentWorld.distance - newCurrentDistance;

    set((state) => ({
      ...state,
      coins: Math.floor(
        state.coins + state.tickCoins * state.displayCharacterSpeed,
      ),
      totalDistanceTraveled: newTotalDistanceTraveled,
      currentDistanceTraveled: newCurrentDistance,
      currentWorldIndex: newCurrentWorldIndex,
      currentProgress: newCurrentProgress,
      currentLeftDistance: newCurrentLeftDistance,
      tickCoins: newTickCoins,
      isShowNextWorldModal,
    }));
  },
  addXp: (amount) =>
    set((state) => ({
      ...state,
      xp: state.xp + amount,
    })),
  addCoins: (amount) =>
    set((state) => ({
      ...state,
      coins: state.coins + amount,
    })),
  completeMission: (missionId) =>
    set((state) => {
      const mission = state.missions.find((m) => m.id === missionId);
      if (mission && !mission.completed) {
        mission.completed = true;
        state.xp += 50; // Assume completing a mission always gives 50 XP
      }
      return state;
    }),
  startJourney: (duration) =>
    set((state) => {
      state.journeyActive = true;
      state.journeyEndTime = Date.now() + duration;
      return state;
    }),
  endJourney: () =>
    set((state) => {
      state.journeyActive = false;
      state.journeyEndTime = null;
      return state;
    }),
  setDisplayCharacterSpeed: (speed) => {
    const { isPaused } = get();
    set((state) => {
      state.displayCharacterSpeed = speed;
      return {
        ...state,
      };
    });
  },
  setIsJourneyRewardAnimation: (value) => {
    set((state) => {
      state.isShowJourneyRewardAnimation = value;
      return {
        ...state,
      };
    });
  },
  pause: () => {
    const { initialized } = get();
    if (!initialized) {
      return;
    }
    set((state) => {
      return {
        ...state,
        isPaused: true,
        displayCharacterSpeed: 0,
      };
    });
  },
  resum: () => {
    const { initialized } = get();
    if (!initialized) {
      return;
    }
    set((state) => {
      return {
        ...state,
        isPaused: false,
        displayCharacterSpeed: DEFAULT_DISPLAY_CHARACTER_SPEED,
      };
    });
  },
  setIsBackgorundLoading: (value) =>
    set((state) => {
      state.isBackgroundLoading = value;
      return {
        ...state,
      };
    }),
  setIsCharacterLoading: (value) =>
    set((state) => {
      state.isCharacterLoading = value;
      return {
        ...state,
      };
    }),
  isLoading: () => {
    const { isBackgroundLoading, isCharacterLoading } = get();
    return isBackgroundLoading || isCharacterLoading;
  },
  initialize: (initialData: Partial<GameState>) =>
    set((state) => ({
      ...state,
      ...initialData,
      initialized: true,
      displayCharacterSpeed: DEFAULT_DISPLAY_CHARACTER_SPEED,
      isPaused: false,
      journeyReward: 0,
    })),
  setIsShowNextWorldModal: (value) =>
    set((state) => {
      state.isShowNextWorldModal = value;
      return {
        ...state,
      };
    }),
  setJourneyReward: (minutes: number) => {
    console.log('setJourneyReward', minutes);
    const { tickCoins } = get();
    const newJourneyReward = Math.floor(
      tickCoins * DEFAULT_DISPLAY_CHARACTER_SPEED * 60 * minutes,
    );
    set((state) => {
      return {
        ...state,
        journeyReward: newJourneyReward,
      };
    });
  },
  resetJourneyReward: () => {
    console.log('resetJourneyReward', get().journeyReward);
    set((state) => {
      return {
        ...state,
        coins: state.coins + state.journeyReward,
        journeyReward: 0,
      };
    });
  },
  getState: () => get(),
  petName: '',
  setPetName: (name) => {
    set((state) => {
      return {
        ...state,
        petName: name,
      };
    });
  },
  reset: () =>
    set((state) => ({
      xp: 0,
      coins: 500,
      missions: [
        {
          id: 1,
          description: 'Complete a 10-minute meditation',
          completed: false,
        },
        { id: 2, description: 'Read a book for 20 minutes', completed: false },
      ],
      journeyActive: false,
      journeyEndTime: null,
      displayCharacterSpeed: DEFAULT_DISPLAY_CHARACTER_SPEED,
      currentWorldIndex: 0,
      totalDistanceTraveled: 0,
      currentDistanceTraveled: 0,
      currentProgress: 0,
      currentLeftDistance: 0,
      tickCoins: 1.5 / DEFAULT_DISPLAY_CHARACTER_SPEED,
      isPaused: false,
      isBackgroundLoading: true,
      isCharacterLoading: true,
      initialized: false,
      isShowNextWorldModal: false,
      isShowJourneyRewardAnimation: false,
      journeyReward: 0,
    })),
}));

export default useGameStore;
