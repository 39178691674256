import React, { useEffect, useState } from 'react';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled from 'styled-components';
import { ShopHeader } from './components/ShopHeader';
import { TabBar } from './components/TabBar';
import { ShopItem } from './components/ShopItem';
import useGameStore from 'src/store/useGameStore';
import { toast } from 'react-toastify';

const Shop = () => {
  const { user } = useUserStore();
  const { coins } = useGameStore();
  const [selectedTab, setSelectedTab] = useState<string>('Outfits');

  useEffect(() => {
    logEvent('view_web_store', user);
  }, []);

  const TAB_LIST = ['Outfits', 'Vehicles'];
  const ITEM_LIST = [
    {
      title: 'Straw Hat',
      imageUrl: '/shop/item_strawhat.svg',
      description: 'Gain 6 mph speed on sunny days!',
      price: 9000,
    },
    {
      title: 'Headphones',
      imageUrl: '/shop/item_headphones.svg',
      description: 'Makes your duck dance!',
      price: 20000,
    },
    {
      title: 'Bunny Ears',
      imageUrl: '/shop/item_bunnyears.svg',
      description: 'Transforms your duck into a bunny-duck!',
      price: 30000,
    },
    {
      title: 'Raincoat',
      imageUrl: '/shop/item_raincoat.svg',
      description: 'Speed up to 6 mph in the rain!',
      price: 900000,
    },
  ];

  return (
    <Div>
      <ShopHeader />
      <TabBar tabs={TAB_LIST} />
      <ItemsContainer>
        {ITEM_LIST.map((item) => (
          <ShopItem
            key={item.title}
            title={item.title}
            imageUrl={item.imageUrl}
            description={item.description}
            price={item.price}
            isPurchasable={coins >= item.price}
          />
        ))}
      </ItemsContainer>
    </Div>
  );
};

export default Shop;

const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100dvh;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background-color: #fbfaf3;
`;
const ItemsContainer = styled.div`
  display: grid;
  width: 100%;
  // 2 columns
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0 16px;
  margin-top: 16px;
`;
