import React from 'react';
import styled from 'styled-components';

interface Props {
  type: string;
  handleClose: () => void;
}

export const LogHeader: React.FC<Props> = ({ type, handleClose }) => {
  return (
    <Div>
      <Inner>
        <Text>Add {type}</Text>
        <Button onClick={handleClose}>
          <img src={'/navigation/header_close.svg'} alt="close" />
        </Button>
      </Inner>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;
const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #4b4b4f;
  margin: 0;
  padding: 1rem;
  width: 100%;
  text-align: center;
`;
const Button = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
