import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LogHeader } from './components/LogHeader';
import { LogTime } from './components/LogTime';
import { LogInput } from './components/LogInput';
import { useNavigate } from 'react-router-dom';
import { LogSubmitButton } from './components/LogSubmitButton';
import useUserStore from 'src/store/useUserStore';
import { DEFAULT_USER_EMAIL, LifelogData } from 'src/models';
import { updateLifelog } from 'src/api/LifelogService';
import { logEvent } from 'src/utils';
import { toast } from 'react-toastify';
import { getUser } from 'src/api/UserApiService';

export const ActivityLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { getState } = useUserStore();
  const now = new Date();
  const [data, setData] = useState<LifelogData>({
    type: 'activity',
    datetime: now.toISOString(),
    content: '',
  } as LifelogData);

  useEffect(() => {
    const { user } = getState();
    logEvent('view_web_lifelog', { user: user, type: 'activity' });
  }, []);

  const handleClose = () => {
    // if there's data, confirm the user wants to close the log
    if (data.content.length > 0) {
      if (
        window.confirm(
          'Are you sure you want to close? Any unsaved data will be lost.',
        )
      ) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const handleSelectDatetime = (datetime: string) => {
    // set "datetime" key in data object
    setData({ ...data, datetime });
  };

  const handleChangeContent = (content: string) => {
    // set "content" key in data object
    setData({ ...data, content });
  };

  const handleSubmit = async () => {
    if (data.content.length === 0) {
      alert('Please fill out the form.');
      return;
    }
    try {
      setIsLoading(true);
      const { user } = getState();
      // submit data to the server
      if (user && user.email) {
        await updateLifelog(user.email, data);
      }

      // log event
      logEvent('submit_lifelog', { user: user, type: 'activity' });

      // show toast
      toast('✅ Added Activity!');

      // navigate back to home
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Div>
      <LogHeader type="Activity" handleClose={handleClose} />
      <LogTime type="Activity" handleSelectDatetime={handleSelectDatetime} />
      <LogInput type="Activity" handleChangeContent={handleChangeContent} />
      <LogSubmitButton handleSubmit={handleSubmit} isLoading={isLoading} />
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100dvh;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background-color: #fbfaf3;
  padding-top: 40px;
`;
