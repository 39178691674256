import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getLifelog } from 'src/api/LifelogService';
import useUserStore from 'src/store/useUserStore';
import { DEFAULT_USER_EMAIL } from 'src/models';
import { logEvent } from 'src/utils';

interface Props {
  type: string;
  handleChangeContent: (subtype: string) => void;
}

interface StaticInputProps {
  type: string;
  title: string;
  inputType: 'input' | 'textarea';
  placeholder: string;
  defaultTags: Array<string>;
}

export const LogInput: React.FC<Props> = ({ type, handleChangeContent }) => {
  const [content, setContent] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [userTags, setUserTags] = useState<string[]>([]);
  const { user } = useUserStore();

  const STATIC_DESCRIPTIONS: Array<StaticInputProps> = [
    {
      type: 'Glucose Level',
      title: 'Glucose Level',
      inputType: 'input',
      placeholder: 'Enter your glucose level in mg/dL',
      defaultTags: [],
    },
    {
      type: 'Meal',
      title: 'Describe Your Meal',
      inputType: 'textarea',
      placeholder:
        'A plate of spaghetti, a side of garlic bread, and a glass of red wine',
      defaultTags: ['spaghetti', 'garlic bread', 'red wine'],
    },
    {
      type: 'Activity',
      title: 'Describe Your Activity',
      inputType: 'textarea',
      placeholder: 'A 30-minute walk in the park',
      defaultTags: [
        'Walk / 30 minutes',
        'Basketball / 1 hour',
        'Yoga / 45 minutes',
      ],
    },
    {
      type: 'Medicine',
      title: 'What Medicine Did You Take?',
      inputType: 'textarea',
      placeholder: '1 pill of Metformin',
      defaultTags: [
        'Metformin 500mg',
        'Insulin Lispro, 1U',
        'Dapagliflozin 10mg',
      ],
    },
  ];

  const handleClickTag = (text: string) => {
    setContent(`${content ? `${content}, ` : ''}${text}`);
    logEvent('touch_lifelog_tag', {
      user: user,
      type: type.toLowerCase(),
      tag: text,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      //add try-catch to getLifelog
      try {
        const data = await getLifelog(
          user?.email || DEFAULT_USER_EMAIL,
          type.toLowerCase(),
        );

        const typeFilteredLogs = data.filter(
          (item) => item.type === type.toLowerCase(),
        );

        // extract tags from logs, remove duplicates
        const uniqueUserTags = typeFilteredLogs
          ?.map((item) => item.content.split(',').map((txt) => txt.trim()))
          .flat();
        setUserTags(uniqueUserTags ?? []);
      } catch (e) {
        console.log(e);
        setUserTags([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const newTags = content
      .split(',')
      .filter((txt) => txt.trim().length > 0)
      .map((item) => item.trim())
      .reverse();

    const combinedTags = [
      ...newTags,
      ...userTags,
      ...(STATIC_DESCRIPTIONS.find((item) => item.type === type)?.defaultTags ??
        []),
    ];

    // remove duplicates
    const uniqueCombinedTags = Array.from(new Set(combinedTags));

    setTags(uniqueCombinedTags);
  }, [content, type, userTags]);

  useEffect(() => {
    handleChangeContent(content);
  }, [content]);

  return (
    <Div>
      <Title>
        {STATIC_DESCRIPTIONS.find((item) => item.type === type)?.title}
      </Title>
      {STATIC_DESCRIPTIONS.find((item) => item.type === type)?.inputType ===
        'textarea' && (
        <>
          <Textarea
            placeholder={
              STATIC_DESCRIPTIONS.find((item) => item.type === type)
                ?.placeholder
            }
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            hasError={content.length > 200}
          />
          <BottomTextContainer>
            <Text>Please separate each item with a comma.</Text>
            <Text hasError={content.length > 200}>{content.length}/200</Text>
          </BottomTextContainer>
        </>
      )}
      {STATIC_DESCRIPTIONS.find((item) => item.type === type)?.inputType ===
        'input' && (
        <Input
          type="number"
          placeholder={
            STATIC_DESCRIPTIONS.find((item) => item.type === type)?.placeholder
          }
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          hasError={content.length > 200}
        />
      )}
      <TagContainer>
        {tags.map((item, index) => (
          <Tag onClick={() => handleClickTag(item)} key={index}>
            {item}
          </Tag>
        ))}
      </TagContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  margin: 16px auto;
`;
const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 16px;
`;
const Textarea = styled.textarea<{
  hasError?: boolean;
}>`
  width: 100%;
  height: 120px;
  padding: 8px;
  border: ${(props) =>
    props.hasError ? '1px solid #ff0000' : '1px solid #dbdde1'};
  border-radius: 8px;
  padding: 1rem;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  resize: none;
  outline: none;

  &:focus {
    border: ${(props) =>
      props.hasError ? '1px solid #ff0000' : '1px solid #000000'};
  }
  /* placeholder */
  &::placeholder {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #abb0ba;
  }
`;
const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;
const Text = styled.p<{
  hasError?: boolean;
}>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${(props) => (props.hasError ? '#ff0000' : '#848b9a')};
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 8px;
`;
const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e3e3e5;
  padding: 4px 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #2e2e33;
  cursor: pointer;

  max-width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Input = styled.input<{ hasError: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  width: 100%;
  padding: 12px 16px;
  margin: 8px 0 24px 0;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#dbdde1')};
  border-radius: 8px;
  background: var(--White, #fff);
  padding-right: 40px; /* space for the icon */
`;
