import {
  DuckAnimation,
  GlucoseAnimationRecord,
  GlucoseGraphData,
  GlucoseGraphRecord,
} from '../models';
import * as d3 from 'd3'; // D3 라이브러리 사용

export const calculateTimeInRange = (data: GlucoseGraphData[]): number => {
  const rangeMin = 70;
  const rangeMax = 180;
  let inRangeCount = 0;

  data.forEach((record) => {
    if (record.Value >= rangeMin && record.Value <= rangeMax) {
      inRangeCount++;
    }
  });

  const timeInRangePercentage = (inRangeCount / data.length) * 100;
  return timeInRangePercentage;
};

export function roundUpToTwoDecimalPlaces(num: number): number {
  return Math.ceil(num * 100) / 100;
}

export const formatMilliseconds = (ms: number) => {
  const hours = Math.floor(ms / 3600000); // 밀리초를 시간으로 변환
  const minutes = Math.floor((ms % 3600000) / 60000); // 남은 밀리초를 분으로 변환

  let timeString = '';
  if (hours > 0) {
    timeString += `${hours} Hour${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    timeString += `${timeString ? ' ' : ''}${minutes} Minute${
      minutes > 1 ? 's' : ''
    }`;
  }
  return timeString || '0 Minutes';
};

export const formatMillisecondsToMinutesAndSeconds = (ms: number): string => {
  const totalSeconds = Math.floor(ms / 1000); // 밀리초를 초로 변환
  const minutes = Math.floor(totalSeconds / 60); // 전체 초를 분으로 변환
  const seconds = totalSeconds % 60; // 남은 초 계산

  // 두 자리 숫자 형식으로 포맷팅
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

export function generateGlucoseData(): GlucoseGraphData[] {
  const data: GlucoseGraphData[] = [];
  let currentValue = 100;
  const now = new Date();

  for (let i = 0; i < 32; i++) {
    const timestamp = new Date(
      now.getTime() - (31 - i) * 15 * 60000,
    ).toISOString();
    const factoryTimestamp = new Date(
      now.getTime() - (31 - i) * 15 * 60001,
    ).toISOString();

    // Generate the next glucose value
    const change = Math.floor(Math.random() * 61) - 30; // Range from -30 to +30
    let nextValue = currentValue + change;
    if (nextValue < 60) nextValue = 60;
    if (nextValue > 250) nextValue = 250;

    // Determine MeasurementColor
    let measurementColor = 1;
    if (nextValue < 80 || (nextValue > 150 && nextValue <= 180)) {
      measurementColor = 2;
    } else if (nextValue > 180) {
      measurementColor = 3;
    }

    const glucoseData: GlucoseGraphData = {
      FactoryTimestamp: factoryTimestamp,
      Timestamp: timestamp,
      type: 1,
      ValueInMgPerDl: nextValue,
      MeasurementColor: measurementColor,
      GlucoseUnits: 1,
      Value: nextValue,
      isHigh: nextValue > 150,
      isLow: nextValue < 80,
    };

    // Adjust the currentValue for the next iteration
    currentValue = nextValue;

    data.push(glucoseData);
  }

  return data;
}

export const mergeGlucoseData = (
  existingData: GlucoseGraphRecord[],
  newData: GlucoseGraphRecord[],
): GlucoseGraphRecord[] => {
  if (!existingData.length) return newData;
  const lastTimestamp = new Date(
    existingData[existingData.length - 1].Timestamp,
  );
  const filteredNewData = newData.filter(
    (record) => new Date(record.Timestamp) > lastTimestamp,
  );
  return [...existingData, ...filteredNewData];
};

export function generateUserGlucoseSampleData(): GlucoseGraphRecord[] {
  const dataPoints: GlucoseGraphRecord[] = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 1000 * 60 * 60 * 8); // 8시간전
  const intervalMinutes = 15; // 15분 간격
  const durationHours = 8; // 총 8시간

  let value = 100;
  for (let i = 0; i < (durationHours * 60) / intervalMinutes; i++) {
    const dateTime = new Date(
      startDate.getTime() + i * intervalMinutes * 60000,
    );
    value = Math.min(
      230,
      Math.max(60, value + Math.floor(Math.random() * 25 + 1) - 9),
    );
    dataPoints.push({
      Value: value,
      Timestamp: dateTime.toISOString(),
    });
  }

  return dataPoints;
}

export function generateGlucoseGraphData(): GlucoseGraphRecord[] {
  const records: GlucoseGraphRecord[] = [];
  const now = new Date();
  const baselineValue = 100; // Starting glucose level in mg/dL
  const duration = 8 * 60; // Total duration in minutes
  const interval = 15; // Interval between records in minutes
  const mealTime = Math.floor(Math.random() * (duration / interval / 2)); // Random meal time in the first half

  let currentValue = baselineValue;

  for (let i = 0; i < duration / interval; i++) {
    const timestamp = new Date(
      now.getTime() - (duration - i * interval) * 60000,
    ).toISOString();

    // Apply a meal effect
    if (i === mealTime) {
      currentValue += Math.floor(Math.random() * 30) + 20; // Smoother increase for a meal spike
    } else if (i > mealTime && i <= mealTime + 4) {
      currentValue -= Math.floor(Math.random() * 15); // Smoother and smaller decrease post-meal
    } else {
      // Normal fluctuation within +/- 5 mg/dL
      const fluctuation = Math.floor(Math.random() * 11) - 5;
      currentValue = Math.max(60, Math.min(250, currentValue + fluctuation)); // Keep within reasonable limits
    }

    records.push({
      Timestamp: timestamp,
      Value: currentValue,
    });
  }

  return records;
}

const calculateRotation = (current: number, next: number): number => {
  let delta = next - current;
  delta *= 5;
  if (delta > 25) {
    delta = 25;
  } else if (delta < -25) {
    delta = -25;
  }
  return delta;
};

const getAnimation = (current: number, next: number): DuckAnimation =>
  next - current >= 15
    ? 'walk_uphill'
    : next - current <= -10
      ? 'walk_downhill'
      : 'walk_flat';

const adjustAnimationSpeed = (
  data: GlucoseAnimationRecord[],
): GlucoseAnimationRecord[] => {
  let upcomingUphillIndex = -1;
  let previousSpeed = 1;

  // First pass to find all uphill indices
  const uphillIndices = data
    .map((record, index) => (record.animation === 'walk_uphill' ? index : -1))
    .filter((index) => index !== -1);

  // Adjust speeds
  return data.map((record, index) => {
    if (uphillIndices.length > 0) {
      // Find the next upcoming uphill index
      const nextUphillIndex = uphillIndices.find(
        (uphillIndex) => uphillIndex >= index,
      );

      if (nextUphillIndex !== undefined) {
        const distanceToUphill = nextUphillIndex - index;
        if (distanceToUphill >= 0 && distanceToUphill <= 5) {
          record.animationSpeed =
            distanceToUphill === 0
              ? 0
              : Math.max(1, data[index - 1].animationSpeed - 1);
        } else if (distanceToUphill === 0) {
          record.animationSpeed = 0;
        } else {
          record.animationSpeed = Math.min(5, Math.max(previousSpeed + 1, 1));
        }
      } else {
        record.animationSpeed = Math.min(5, Math.max(previousSpeed + 1, 1));
      }
    } else {
      record.animationSpeed = Math.min(5, Math.max(previousSpeed + 1, 1));
    }

    previousSpeed = record.animationSpeed;
    return record;
  });
};

const parseData = (rawData: GlucoseGraphData[]): GlucoseAnimationRecord[] =>
  rawData
    .map((data, index, array) => {
      const nextValue =
        index < array.length - 1 ? array[index + 1].Value : data.Value;
      return {
        Timestamp: new Date(data.Timestamp).toISOString(),
        Value: data.Value,
        animation: 'walk_flat' as DuckAnimation,
        animationSpeed: 5,
        rotate: calculateRotation(data.Value, nextValue),
      };
    })
    .sort(
      (a, b) =>
        new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime(),
    );

const interpolate = (
  start: GlucoseAnimationRecord,
  end: GlucoseAnimationRecord,
): GlucoseAnimationRecord[] => {
  const startTime = new Date(start.Timestamp).getTime();
  const endTime = new Date(end.Timestamp).getTime();
  const timeDifference = (endTime - startTime) / 120000; // Total minutes difference
  const valueDifference = end.Value! - start.Value!;
  const steps = Math.floor(timeDifference / 5); // Number of n-minute intervals
  const valueStep = valueDifference / steps;
  const speedStep = (end.animationSpeed - start.animationSpeed) / steps;
  const rotateStep = (end.rotate - start.rotate) / steps;

  return Array.from({ length: steps }, (_, i) => ({
    Timestamp: new Date(startTime + (i + 1) * 5 * 60000).toISOString(),
    Value: start.Value! + valueStep * (i + 1),
    animation: start.animation,
    animationSpeed: start.animationSpeed + speedStep * (i + 1),
    rotate: start.rotate + rotateStep * (i + 1),
  }));
};

const generateInterpolatedData = (
  data: GlucoseAnimationRecord[],
): GlucoseAnimationRecord[] =>
  data.flatMap((current, i, array) =>
    i < array.length - 1
      ? [current, ...interpolate(current, array[i + 1])]
      : [current],
  );

const interpolateGlucoseData = (
  data: GlucoseGraphData[],
  numberOfPoints: number,
): GlucoseGraphData[] => {
  console.log('Interpolating glucose data...', data);
  if (data.length < 2) {
    throw new Error('At least two data points are required for interpolation.');
  }

  // Convert timestamps to Date objects
  const parseDate = d3.timeParse('%m/%d/%Y %I:%M:%S %p');
  const dataWithParsedDates = data.map((d) => ({
    ...d,
    parsedTimestamp: parseDate(d.Timestamp) as Date,
  }));

  // Calculate the total time range
  const totalDuration =
    dataWithParsedDates[
      dataWithParsedDates.length - 1
    ].parsedTimestamp.getTime() -
    dataWithParsedDates[0].parsedTimestamp.getTime();

  // Determine the time step for interpolation
  const timeStep = totalDuration / (numberOfPoints - 1);

  // Perform interpolation
  const interpolatedData: GlucoseGraphData[] = [];
  for (let i = 0; i < numberOfPoints; i++) {
    const targetTime =
      dataWithParsedDates[0].parsedTimestamp.getTime() + i * timeStep;
    const targetDate = new Date(targetTime);

    // Check if targetTime is exactly at an existing data point
    const exactMatch = dataWithParsedDates.find(
      (d) => d.parsedTimestamp.getTime() === targetTime,
    );

    if (exactMatch) {
      // If there is an exact match, use the existing data point
      interpolatedData.push({
        ...exactMatch,
        Timestamp: d3.timeFormat('%m/%d/%Y %I:%M:%S %p')(targetDate),
      });
    } else {
      // Find the two data points surrounding the target time
      let lowerIndex = 0;
      let upperIndex = dataWithParsedDates.length - 1;
      for (let j = 0; j < dataWithParsedDates.length - 1; j++) {
        if (
          dataWithParsedDates[j].parsedTimestamp.getTime() <= targetTime &&
          dataWithParsedDates[j + 1].parsedTimestamp.getTime() >= targetTime
        ) {
          lowerIndex = j;
          upperIndex = j + 1;
          break;
        }
      }

      const lowerData = dataWithParsedDates[lowerIndex];
      const upperData = dataWithParsedDates[upperIndex];

      // Linear interpolation
      const t =
        (targetTime - lowerData.parsedTimestamp.getTime()) /
        (upperData.parsedTimestamp.getTime() -
          lowerData.parsedTimestamp.getTime());
      const interpolatedValue =
        lowerData.Value + t * (upperData.Value - lowerData.Value);

      interpolatedData.push({
        ...upperData,
        Timestamp: d3.timeFormat('%m/%d/%Y %I:%M:%S %p')(targetDate),
        Value: interpolatedValue,
        ValueInMgPerDl: interpolatedValue,
      });
    }
  }

  return interpolatedData.sort(
    (a, b) => new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime(),
  );
};

export const convertGlucoseRawDataToGlucoseAnimationRecords = (
  rawData: GlucoseGraphData[],
): GlucoseAnimationRecord[] => {
  // Interpolate the data to 64 points
  const previousInterpolatedData = interpolateGlucoseData(rawData, 64);
  // Parse the data (animation)
  const parsedData = parseData(previousInterpolatedData);

  let cumulativeDrop = 0;
  let animationIndex = [parsedData.length - 2];
  let lastAnimationIndex = parsedData.length - 1;
  let isFirstUphillCondition = true;
  let isLinearGraph = true;

  for (let i = parsedData.length - 2; i >= 0; i--) {
    const drop = parsedData[i + 1].Value - parsedData[i].Value;
    if (drop > 0) {
      lastAnimationIndex = i;
      if (cumulativeDrop >= 25 && isFirstUphillCondition) {
        animationIndex.push(i);
        isFirstUphillCondition = false;
      }
      cumulativeDrop += drop;
    } else {
      cumulativeDrop = 0;
      isLinearGraph = false;
    }
  }

  if (cumulativeDrop >= 25 && lastAnimationIndex === 0 && isLinearGraph) {
    parsedData[animationIndex[0]].animation = 'walk_uphill';
  } else if (animationIndex.length > 1) {
    parsedData[animationIndex[1]].animation = 'walk_uphill';
  }

  return parsedData;
};
