import React from 'react';
import styled from 'styled-components';
import { CharacterNextProps } from '.';

const CharacterProfilePage = ({ onClickNext }: CharacterNextProps) => {
  return <Div>CharacterProfilePage</Div>;
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export default CharacterProfilePage;
