import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Overlay } from './Overlay';
import { toast } from 'react-toastify';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';

export const GNB = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const asPath = location.pathname;
  const [selected, setSelected] = useState<string>('home');
  const [isCenterButtonActive, setIsCenterButtonActive] =
    useState<boolean>(false);

  useEffect(() => {
    if (asPath.startsWith('/report')) {
      setSelected('report');
    } else if (asPath.startsWith('/roadmap')) {
      setSelected('roadmap');
    } else if (asPath.startsWith('/setting')) {
      setSelected('setting');
    } else {
      setSelected('home');
    }
  }, [asPath]);

  const gnbList = [
    { displayName: 'Home', title: 'home', type: 'icon' },
    { displayName: 'Report', title: 'report', type: 'icon' },
    { displayName: '', title: '', type: 'center' },
    { displayName: 'Roadmap', title: 'roadmap', type: 'icon' },
    { displayName: 'Setting', title: 'setting', type: 'icon' },
  ];
  return (
    <>
      {isCenterButtonActive && (
        <Overlay handleClose={() => setIsCenterButtonActive(false)} />
      )}
      <Div>
        {gnbList.map((item) =>
          item.type === 'icon' ? (
            <GNBIcon
              key={item.title}
              title={item.title}
              displayName={item.displayName}
              isSelected={selected === item.title}
            />
          ) : (
            <CenterButton onClick={() => setIsCenterButtonActive((a) => !a)}>
              <img
                src={
                  isCenterButtonActive
                    ? '/navigation/gnb_main_active.svg'
                    : '/navigation/gnb_main_inactive.svg'
                }
                alt="+"
              />
            </CenterButton>
          ),
        )}
      </Div>
    </>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #fbfaf3;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 60px;

  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
const CenterButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 0.75rem;
  background: var(--Green-500, #0bab7c);
  box-shadow: 0px 4px 20px 0px rgba(11, 171, 124, 0.3);

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;

export const GNBIcon = ({
  displayName,
  title,
  isSelected,
}: {
  displayName: string;
  title: string;
  isSelected: boolean;
}) => {
  const navigate = useNavigate();

  const { user } = useUserStore();
  const UNAVAILABLE_MENUS = ['setting'];

  const handleClickIcon = (title: string) => {
    if (UNAVAILABLE_MENUS.includes(title)) {
      logEvent(`view_web_${title}`, user);
      toast('🚧 Coming Soon!');
    } else {
      title === 'home' ? navigate('/') : navigate(`/${title}`);
    }
  };

  return (
    <IconContainer onClick={() => handleClickIcon(title)}>
      <Icon
        src={
          isSelected
            ? `/navigation/gnb_${title}_dark.svg`
            : `/navigation/gnb_${title}_light.svg`
        }
        alt={title}
      />
      <IconText isSelected={isSelected}>{displayName}</IconText>
    </IconContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: transparent !important;
`;
const Icon = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-bottom: 0.5rem;
  color: white;
  background-color: transparent !important;
`;
const IconText = styled.div<{ isSelected: boolean }>`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.isSelected ? '#0BAB7C' : '#909093')};
  font-weight: ${(props) => (props.isSelected ? '600' : '400')};
  text-align: center;
`;
