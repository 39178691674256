import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled from 'styled-components';

export const Overlay = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const { user } = useUserStore();

  const handleClickLifelogButton = (type: string) => {
    logEvent('gnb_event_start', { type: type, user: user });
    navigate(`/lifelog/${type}`);
  };

  // handleClose, if an area outside the buttonsContainer is clicked
  return (
    <Div onClick={handleClose}>
      <RelativeContainer>
        <ButtonsContainer onClick={(e) => e.stopPropagation()}>
          <Button onClick={() => handleClickLifelogButton('glucoseLevel')}>
            <Icon src={'/navigation/gnb_overlay_pulse.svg'} alt="" />
            <Text>Add Glucose Level</Text>
          </Button>
          <Button onClick={() => handleClickLifelogButton('meal')}>
            <Icon src={'/navigation/gnb_overlay_meal.svg'} alt="" />
            <Text>Add a Meal</Text>
          </Button>
          <Button onClick={() => handleClickLifelogButton('activity')}>
            <Icon src={'/navigation/gnb_overlay_activity.svg'} alt="" />
            <Text>Add Activity</Text>
          </Button>
          <Button onClick={() => handleClickLifelogButton('medicine')}>
            <Icon src={'/navigation/gnb_overlay_medication.svg'} alt="" />
            <Text>Add Medicine Intake</Text>
          </Button>
        </ButtonsContainer>
      </RelativeContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const RelativeContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: calc(60px + 1rem);
  left: 1rem;
  width: calc(100% - 2rem);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  gap: 10px;
`;

const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Icon = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #4b4b4f;
`;
