export const backgrounds = {
  images: [
    'moon_full.png',
    'flower_sprout_day.png',
    'hawaii_M_flower_night.png',
    'hawaii_L_tree_night.png',
    'hawaii_L_tree.png',
    'hawaii_M_tree.png',
    'grass_night.png',
    'hawaii_M_plant_night.png',
    'hawaii_L_plant.png',
    'hawaii_S_tree.png',
    'hawaii_M_tree_night.png',
    'sky_night.png',
    'bush_night.png',
    'bush_medium_day.png',
    'tree_night.png',
    'hawaii_ground_night.png',
    'bush_medium_night.png',
    'hawaii_S_flower_night.png',
    'hawaii_L_flower.png',
    'hawaii_L_flower_night.png',
    'hawaii_M_plant.png',
    'moon_half.png',
    'bush_sprout_night.png',
    'flower_day.png',
    'hawaii_S_plant.png',
    'tree_sprout_day.png',
    'flower_night.png',
    'hawaii_L_plant_night.png',
    'cloud_rain_light.png',
    'hawaii_ground.png',
    'bush_sprout_day.png',
    'tree_medium_night.png',
    'hawaii_S_plant_night.png',
    'moon_crescent.png',
    'tree_sprout_night.png',
    'hawaii_S_tree_night.png',
    'grass_rainny.png',
    'sky_rainny.png',
    'flower_medium_day.png',
    'cloud_rain_dark.png',
    'tree_medium_day.png',
    'sky_day.png',
    'grass_day.png',
    'sun.png',
    'flower_sprout_night.png',
    'cloud_dark.png',
    'flower_medium_night.png',
    'hawaii_M_flower.png',
    'cloud_light.png',
    'tree_day.png',
    'bush_day.png',
    'hawaii_S_flower.png',
  ],
};
