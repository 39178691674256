import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useGameStore from 'src/store/useGameStore';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import { numberToGameUnits } from 'src/utils/game';
import styled from 'styled-components';

interface TopBarProps {
  coins: number;
  showStore?: boolean;
  highlightShadow?: boolean;
}

export const TopBar = ({
  coins,
  highlightShadow,
  showStore = true,
}: TopBarProps) => {
  const navigate = useNavigate();

  const handleClickCoin = () => {
    navigate('/shop');
  };

  const handleClickStore = () => {
    navigate('/shop');
  };

  return (
    <Div>
      <CoinContainer
        onClick={handleClickCoin}
        highlightShadow={highlightShadow || false}
      >
        <CoinIcon src={'/home/coin_header.svg'} alt="" />
        <CoinText>{numberToGameUnits(coins)}</CoinText>
      </CoinContainer>
      <StoreContainer
        onClick={handleClickStore}
        highlightShadow={highlightShadow || false}
      >
        {showStore && <StoreIcon src={'/home/shop.svg'} alt="" />}
      </StoreContainer>
    </Div>
  );
};

const Div = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  flex-direction: row;
  width: 100% !important;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: transparent;
  z-index: 100;
`;
const CoinContainer = styled.div<{ highlightShadow: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  height: 2rem;
  width: 6rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-left: 0.5rem;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: ${(props) =>
    props.highlightShadow
      ? '0px 4px 12px 0px rgba(95, 72, 230, 0.70)'
      : 'none'};
`;
const CoinIcon = styled.img`
  position: absolute;
  top: -0.25rem;
  left: -0.5rem;
  z-index: 101;
  width: 2.5rem;
  height: 2.5rem;
`;
const CoinText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: black;
`;
const StoreContainer = styled.div<{ highlightShadow: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
  width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.highlightShadow
      ? '0px 4px 12px 0px rgba(95, 72, 230, 0.70)'
      : 'none'};
`;
const StoreIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
