import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TimeScale,
} from 'chart.js';
import React, { useEffect, useRef } from 'react';
import { GlucoseAnimationRecord, GlucoseGraphData } from 'src/models';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

type GlucoseGraphRecord = {
  Timestamp: string;
  Value: number | null;
};

const GreenBoxPlugin = {
  id: 'greenBoxPlugin',
  beforeDraw: (chart: any, args: any, options: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
      scales: { y },
    } = chart;
    const { minY, maxY } = options;

    ctx.save();
    ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
    ctx.fillRect(
      left,
      y.getPixelForValue(maxY),
      right - left,
      y.getPixelForValue(minY) - y.getPixelForValue(maxY),
    );
    ctx.restore();
  },
};

export default function GlucoseCharts({
  glucoseData,
  highlightIndex,
}: {
  glucoseData: GlucoseAnimationRecord[];
  highlightIndex: number;
}) {
  const [graphData, setData] = React.useState<GlucoseGraphRecord[]>([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const filledData = glucoseData?.map((data) => ({
      Timestamp: data.Timestamp,
      Value: data.Value,
    }));

    setData(filledData);
  }, [glucoseData]);

  useEffect(() => {
    if (chartRef.current) {
      const { chartInstance } = chartRef.current as any;
      if (highlightIndex !== null && chartInstance) {
        const ctx = chartInstance.ctx;
        const x = chartInstance.scales.x.getPixelForValue(highlightIndex);
        const topY = chartInstance.scales.y.top;
        const bottomY = chartInstance.scales.y.bottom;

        // Clear previous vertical line
        chartInstance.update();

        // Draw new vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
        ctx.stroke();
        ctx.restore();
      }
    }
  }, [highlightIndex, graphData]);

  const data = {
    labels: graphData?.map((data) => formatTimeLabel(data.Timestamp)),

    datasets: [
      {
        data: graphData?.map((data) => data.Value),
        fill: true,
        borderColor: '#0BAB7C',
        tension: 0.4,
        borderWidth: 2,
        pointRadius: graphData?.map((_, index) =>
          index === highlightIndex ? 6 : 0,
        ), // 강조할 포인트만 반지름을 크게
        pointBackgroundColor: graphData?.map((_, index) =>
          index === highlightIndex ? 'white' : 'rgba(0, 0, 0, 0)',
        ), // 강조할 포인트만 색상을 흰색으로 변경
        pointBorderColor: graphData?.map((_, index) =>
          index === highlightIndex ? 'black' : 'rgba(0, 0, 0, 0)',
        ), // 강조할 포인트 테두리를 검정색으로
        pointBorderWidth: graphData?.map((_, index) =>
          index === highlightIndex ? 3 : 0,
        ), // 강조할 포인트 테두리 두께
      },
    ],
  };

  const options: ChartOptions<'line'> | any = {
    animation: false,
    scales: {
      y: {
        beginAtZero: false,
        display: true,
        min: 30,
        suggestedMax: 200,
        ticks: {
          maxTicksLimit: 5,
        },
      },
      x: {
        display: true,
        ticks: {
          maxTicksLimit: 4, // 최대 표시할 라벨 수
        },
      },
    },
    plugins: {
      legend: {
        display: false, // 이 옵션을 통해 레전드를 숨깁니다.
      },
      greenBoxPlugin: {
        minY: 70,
        maxY: 180,
      },
    },
    elements: {
      point: {
        radius: 0, // 데이터 포인트를 숨깁니다.
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Line
        ref={chartRef}
        data={data}
        options={options}
        plugins={[GreenBoxPlugin]}
      />
    </div>
  );
}

const formatTimeLabel = (timestamp: string) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}${ampm}`;
};
