import React from 'react';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
`;

const TycoonReport: React.FC = () => {
  const sendMessageToUnity = () => {
    window.location.href = 'unity://accesstoken?key=value';
  };

  return (
    <AppContainer>
      <h1>React WebView Example</h1>
      <Button onClick={sendMessageToUnity}>Send Message to Unity</Button>
    </AppContainer>
  );
};

export default TycoonReport;
