import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useBottomSheetStore from 'src/store/useBottomSheetStore';

interface BottomsheetProps {
  contentComponent: ReactNode;
  title?: string;
}

export const Bottomsheet = ({ title, contentComponent }: BottomsheetProps) => {
  const { setIsBottomsheetVisible } = useBottomSheetStore();

  const handleBackgroundClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsBottomsheetVisible(false);
  };

  return (
    <>
      <LayerContainer onClick={(e) => handleBackgroundClick(e)} />
      <Div>
        <BottomsheetContainer>
          {title && <Title>{title}</Title>}
          <ContentContainer>{contentComponent}</ContentContainer>
        </BottomsheetContainer>
      </Div>
    </>
  );
};

const Div = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  margin: 0;
  height: 100%;
  z-index: 2000;
  bottom: -100%;
  transition: transform 0.3s ease;
  transform: translateY(-100%);
  background-color: transparent;
`;
const LayerContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100;
`;
const BottomsheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25rem;
  border-radius: 2rem 2rem 0 0;
  background-color: white;
  z-index: 1200;
`;
const Title = styled.p`
  width: 100%;
  height: 4rem;
  text-align: center;

  font-size: 1.5rem;
  line-height: 4rem;
  font-weight: 600;
  color: 'gray';
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
