// src/useAudio.ts
import { useState, useEffect } from 'react';

const useAudio = (url: string, isLooped: boolean) => {
  const [audio] = useState(new Audio(url));
  const [isPlaying, setIsPlaying] = useState(false);

  const toggle = () => setIsPlaying(!isPlaying);

  useEffect(() => {
    audio.loop = isLooped;
    isPlaying ? audio.play() : audio.pause();
  }, [isPlaying, audio, isLooped]);

  useEffect(() => {
    audio.addEventListener('ended', () => setIsPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [audio]);

  return [isPlaying, toggle] as const;
};

export default useAudio;
