import { GlucoseGraphData, LoginResponse } from '../models';
import { post } from './ApiService';

export const libreLinkupLogin = async (
  email: string,
  password: string,
  country: string = 'US',
): Promise<LoginResponse> => {
  const response = await post<LoginResponse>('/proxy/login', {
    email,
    password,
    country,
  });

  const data = response;
  //   console.log('data----- ', data);
  if (data.status === 0) {
    return data;
  }
  return data;
};

export const libreGlucoseGraphData = async (
  libreToken: string,
): Promise<any> => {
  try {
    const connResponse = await post<any>('/proxy/connections', {
      accessToken: libreToken,
    });
    const patientId = connResponse.data[0]?.patientId;

    if (!patientId) {
      console.log('Unable to get the patient id');
      return null;
    }

    const graphResponse = await post<any>('/proxy/connectionGraph', {
      connectionId: patientId,
      accessToken: libreToken,
    });

    // console.log('graphResponse: ', graphResponse);

    const glucoseMeasurement =
      graphResponse?.data?.connection?.glucoseMeasurement;
    const glucoseGraphData = graphResponse?.data?.graphData;

    if (!glucoseMeasurement && !glucoseGraphData) {
      console.log('Unable to get the glucose measurement');
      return null;
    }
    if (glucoseMeasurement) {
      return {
        currentGlucose: glucoseMeasurement,
        lastGlucoseTime: glucoseMeasurement.Timestamp,
        glucoseGraphData: glucoseGraphData,
      };
    }
  } catch (error) {
    console.log('Unable to get the token: ', error);
  }

  return null;
};
