import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import useAuthStore from 'src/store/useAuthStore';
import { logEvent, setUserProperties } from 'src/utils';
import useGameStore from 'src/store/useGameStore';
import { signupAnonymous } from 'src/api/UserApiService';
import { toast } from 'react-toastify';
import useUserStore, { DEFAULT_USER_DATA } from 'src/store/useUserStore';

const Container = styled.div`
  background-color: #00422f;
  color: white;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  height: 100dvh;
  margin: auto;
  position: relative;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  height: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 1.5rem);
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 8px;
  margin-bottom: 48px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const Button = styled.button`
  background-color: white;
  color: black;
  padding: 16px;
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;

  &:hover {
    background-color: #dedede;
  }
`;

const CheckLibre = () => {
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { setUser } = useUserStore();
  const { petName } = useGameStore();

  useEffect(() => {
    logEvent('view_web_check_libre', {});
  }, []);

  const handleSkipToHome = async () => {
    const email = 'anonymous';
    amplitude.setUserId(email);
    logEvent('login_success', { email: email });
    const result = await signupAnonymous(petName);
    // console.log('anonumous login result', result);
    const { user } = result;
    if (!user) {
      toast('Something went wrong. Please try again later.');
    }
    if (user.email) {
      setUser({
        ...user,
        email: user.email,
        data: DEFAULT_USER_DATA,
        id: user.id || 0,
      });
      authStore.login(user.email);
    }

    navigate('/');
  };

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>← Back</BackButton>
      <ContentContainer>
        <TitleContainer>
          <Title>
            Are you using the
            <br />
            Freestyle Libre?
          </Title>
        </TitleContainer>
        <ButtonContainer>
          <Button onClick={async () => handleSkipToHome()}>No</Button>
          <Button
            onClick={() => {
              navigate('/login?isLibreLinkUpUser=true');
            }}
          >
            Yes
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

export default CheckLibre;
