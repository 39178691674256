import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

// Define the props for the TabBar component
interface TabBarProps {
  tabs: string[];
}

export const TabBar: React.FC<TabBarProps> = ({ tabs }) => {
  const [activeTabName, setActiveTabName] = useState<string>(tabs[0]);
  const handleTabClick = (tab: string) => {
    if (tab === 'Vehicles') {
      toast('🚧 Coming Soon!');
    } else {
      setActiveTabName(tab);
    }
  };

  return (
    <TabContainer>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          isActive={tab === activeTabName}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </Tab>
      ))}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  margin: 0 16px;
  background: #0f0f140a;
  padding: 4px;
  border-radius: 10px;
  gap: 4px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? 'white' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#363D49' : '#909093')};

  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  &:hover {
    background-color: #fafafa;
  }
`;
