import { post } from './ApiService';

export const addWaitlist = async (
  email: string,
  serviceName: string,
): Promise<any> => {
  return await post<any>(`/waitlist`, {
    email,
    serviceName,
  });
};
