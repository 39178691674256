import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const API_BASE_URL = 'https://glucopet.com/server/api';
// const API_BASE_URL = 'http://localhost:3300/server/api';

// Initialize Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to handle GET requests with generic type for response
export const get = async <T>(url: string, params?: object): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.get<T>(url, { params });
    return response.data;
  } catch (error) {
    console.error('HTTP GET Request Failed: ', error);
    throw error;
  }
};

// Function to handle POST requests with generic type for response
export const post = async <T>(
  url: string,
  data?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.post<T>(url, data, config);
    return response.data;
  } catch (error) {
    console.error('HTTP POST Request Failed: ', error);
    throw error;
  }
};

// Function to handle PUT requests with generic type for response
export const put = async <T>(url: string, data?: object): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.put<T>(url, data);
    return response.data;
  } catch (error) {
    console.error('HTTP PUT Request Failed: ', error);
    throw error;
  }
};

// Function to handle DELETE requests with generic type for response
export const remove = async <T>(url: string): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.delete<T>(url);
    return response.data;
  } catch (error) {
    console.error('HTTP DELETE Request Failed: ', error);
    throw error;
  }
};
