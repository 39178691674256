import React, { useEffect, useState } from 'react';
import useGameStore from 'src/store/useGameStore';
import useUserStore from 'src/store/useUserStore';
import styled, { css, keyframes } from 'styled-components';
import { logEvent } from 'src/utils';
import { formatDate } from 'src/utils/strings';
import { numberToGameUnits } from 'src/utils/game';
import { WORLDS } from 'src/models/worlds';
import useJourneyStore from 'src/store/useJourneyStore';
import { getLocalStorageEmail } from 'src/storage/user.localstorage';
import { getLifelog } from 'src/api/LifelogService';
import { LifelogData } from 'src/models';

interface GameCardProps {
  onClickRefresh: () => void;
  showRefresh: boolean;
  isRefreshLoading: boolean;
}

export const GameCard: React.FC<GameCardProps> = ({
  onClickRefresh,
  showRefresh,
  isRefreshLoading,
}) => {
  const energyBoostPrice = 500;
  const [isBoost, setIsBoost] = useState(false);
  const [lifelogs, setLifelogs] = useState<LifelogData[]>([]);
  const { user } = useUserStore();
  const {
    displayCharacterSpeed,
    currentProgress,
    currentLeftDistance,
    currentWorldIndex,
    setDisplayCharacterSpeed,
    coins,
    addCoins,
  } = useGameStore();

  const { setSpeed, animationState } = useJourneyStore();

  const handleClickRefresh = () => {
    onClickRefresh();
  };

  const handleClickEnergyBoost = () => {
    if (isBoost) {
      console.log('Already Boosted');
      return;
    }
    if (coins >= energyBoostPrice) {
      addCoins(-energyBoostPrice);
    } else {
      alert('Not enough coins');
      return;
    }
    setIsBoost(true);
    const originSpeed = displayCharacterSpeed;
    const boostSpeed = originSpeed * 5;

    setDisplayCharacterSpeed(boostSpeed);

    logEvent('touch_energy_boost', user);

    setTimeout(() => {
      setDisplayCharacterSpeed(originSpeed);
      setIsBoost(false);
    }, 5000);
  };

  const getGlucoseScore = () => {
    const currentGlucose = user?.data?.currentGlucose;
    const currentGlucoseValue = currentGlucose?.ValueInMgPerDl;
    const currentGlucoseTime = currentGlucose
      ? new Date(currentGlucose.Timestamp).getTime()
      : 0;

    const lastGlucoseLog = lifelogs
      .filter((log) => log.type === 'glucoseLevel')
      .sort(
        (a, b) =>
          new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
      )[0];

    const lastGlucoseLogTime = lastGlucoseLog
      ? new Date(lastGlucoseLog.datetime).getTime()
      : 0;

    if (!currentGlucoseValue || lastGlucoseLogTime > currentGlucoseTime) {
      return lastGlucoseLog ? parseInt(lastGlucoseLog.content) : 0;
    }

    return currentGlucoseValue;
  };

  const getLastGlucoseTime = () => {
    const currentGlucoseTime = user?.data?.currentGlucose?.Timestamp;
    const lastGlucoseLog = lifelogs
      .filter((log) => log.type === 'glucoseLevel')
      .sort(
        (a, b) =>
          new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
      )[0];

    if (lastGlucoseLog) {
      const lastLogTime = new Date(lastGlucoseLog.datetime).getTime();
      if (
        !currentGlucoseTime ||
        lastLogTime > new Date(currentGlucoseTime).getTime()
      ) {
        return lastGlucoseLog.datetime;
      }
    }
    return currentGlucoseTime || '';
  };

  const glucoseScore = getGlucoseScore();

  const calculateGlucoseStatus = (glucoseScore: number) => {
    if (glucoseScore === 0) return '-';
    if (glucoseScore <= 70 || glucoseScore >= 180) return 'Poor';
    return 'Good';
  };

  useEffect(() => {
    setSpeed(Math.max(displayCharacterSpeed, 6) / 6);
  }, [displayCharacterSpeed]);

  useEffect(() => {
    if (isBoost) return;
    if (animationState === 'walk_uphill') {
      setDisplayCharacterSpeed(3);
    } else {
      setDisplayCharacterSpeed(6);
    }
  }, [displayCharacterSpeed]);

  useEffect(() => {
    const email = getLocalStorageEmail();
    const get = async () => {
      if (email) {
        const result = await getLifelog(email, 'all');
        setLifelogs(result);
      }
    };
    get();
  }, []);

  return (
    <Div>
      <HeaderContainer status={calculateGlucoseStatus(glucoseScore)}>
        <TitleContainer>
          <Row>
            <Title>Glucose Level</Title>
            {showRefresh && (
              <RefreshButton onClick={handleClickRefresh}>
                <RefreshIcon
                  src={'/home/refresh.svg'}
                  alt="refresh"
                  isRefreshLoading={isRefreshLoading}
                />
              </RefreshButton>
            )}
          </Row>
          <Row>
            <TimeText>
              Last{' '}
              {getLastGlucoseTime() ? formatDate(getLastGlucoseTime()) : '-'}
            </TimeText>
          </Row>
        </TitleContainer>
        <GlucoseContainer>
          <NumberContainer>
            <NumberText>{glucoseScore}</NumberText>
          </NumberContainer>
          <StatusContainer>
            <StatusText status={calculateGlucoseStatus(glucoseScore)}>
              {calculateGlucoseStatus(glucoseScore)}
            </StatusText>
            <UnitText>mg/dL</UnitText>
          </StatusContainer>
        </GlucoseContainer>
      </HeaderContainer>
      <BodyContainer>
        <ProgressBarContainer>
          <DuckIcon
            progress={currentProgress}
            src={'/home/mini_duck.svg'}
            alt="Duck"
          />
          <LocationIconContainer>
            <LocationIcon src={`/home/mini_hawaii.svg`} alt="" />
          </LocationIconContainer>
          <ProgressTrack>
            <ProgressFill progress={currentProgress} />
          </ProgressTrack>
          <LocationContainer>
            <LocationItem>
              <LocationText>
                {WORLDS[currentWorldIndex].displayName}
              </LocationText>
            </LocationItem>
            <LocationItem>
              <LocationText>
                {WORLDS[currentWorldIndex + 1].displayName}
              </LocationText>
            </LocationItem>
          </LocationContainer>
          <SpeedContainer>
            <SpeedText>{displayCharacterSpeed}mph Speed</SpeedText>
            <SpeedText>{currentLeftDistance.toFixed(3)} miles left</SpeedText>
          </SpeedContainer>
        </ProgressBarContainer>
        <EnergyBoostContainerBackground>
          <EnergyBoostContainer onClick={handleClickEnergyBoost}>
            <SmallIcon src={'/home/energy.svg'} alt="energy" />
            <EnergyText>Energy Boost</EnergyText>
            <EnergyBoostButton>
              <SmallIcon src={'/home/coin_header.svg'} alt="" />
              <CostText>{numberToGameUnits(energyBoostPrice)}</CostText>
            </EnergyBoostButton>
          </EnergyBoostContainer>
        </EnergyBoostContainerBackground>
      </BodyContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  padding: 0;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 5rem;
  left: 1rem;
`;

const HeaderContainer = styled.div<{ status: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  background-color: ${(props) => {
    if (props.status === 'Good') return '#C7F4C2';
    if (props.status === 'Poor') return '#FFEBD2';
    return '#EBE7FF';
  }};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
  color: #4b4b4f;
`;

const RefreshButton = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled.img<{ isRefreshLoading: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${(props) =>
    props.isRefreshLoading &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`;

const TimeText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1rem;
  color: #909093;
`;

const GlucoseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const NumberText = styled.p`
  font-size: 34px;
  line-height: 41px;
  font-weight: 700;
  color: #4b4b4f;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatusText = styled.span<{ status: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) =>
    props.status === 'Good'
      ? '#0BAB7C'
      : props.status === 'Poor'
        ? '#E0533D'
        : '#FFB74D'};
  margin-right: 4px;
`;

const UnitText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1rem;
  color: #909093;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  position: relative;
`;

const LocationIconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 20px;
`;

const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 16px;
`;

const LocationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LocationText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4b4b4f;
`;

const DuckIcon = styled.img<{ progress: number }>`
  position: absolute;
  width: 20px;
  height: 20px;
  object-fit: contain;
  left: ${(props) => props.progress * 100}%;
  top: 4px;
  transform: translateX(-50%);
  z-index: 101;
`;

const LocationIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  object-fit: contain;
`;

const ProgressTrack = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 8px 0;
`;

const ProgressFill = styled.div<{ progress: number }>`
  height: 100%;
  width: ${(props) => props.progress * 100}%;
  background-color: #2e2e33;
  border-radius: 4px;
`;

const SpeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SpeedText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #909093;
`;

const EnergyBoostContainerBackground = styled.div`
  margin-top: 16px;
  border-radius: 10px;
  background: #e4e4e4;
  box-shadow: 0px 3px 0px 0px #c4c4c4;

  &:active,
  &.pressed {
    transform: translateY(3px);
  }
`;

const EnergyBoostContainer = styled.div`
  margin: 4px;
  padding: 7px 12px;
  background-color: #5f48e6;
  box-shadow: 0px -4px 0px 0px #240cb5 inset;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const SmallIcon = styled.img`
  height: 14px;
  width: 14px;
  object-fit: contain;
  margin-right: 4px;
  fill: white;
`;

const EnergyBoostButton = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #5f48e6;
  padding: 5px 6px;
  border-radius: 6px;
  cursor: pointer;
`;

const EnergyText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--Gray-White, #fff);
  margin-left: 4px;
  margin-right: 8px;
`;

const CostText = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: white;
`;

export default GameCard;
