import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  type: string;
  handleSelectSubtype: (subtype: string) => void;
}

export const LogSubtype: React.FC<Props> = ({ type, handleSelectSubtype }) => {
  const [selectedSubtype, setSelectedSubtype] = useState<string>('');

  useEffect(() => {
    handleSelectSubtype(selectedSubtype);
  }, [selectedSubtype]);

  const toggleSelectedSubtype = (subtype: string) => {
    if (subtype === selectedSubtype) {
      setSelectedSubtype('');
    } else {
      setSelectedSubtype(subtype);
    }
    return;
  };

  const foodSubtypeList = [
    {
      imageUrl: '/log/breakfast.svg',
      subtype: 'Breakfast',
      backgroundColor: '#E0FFFD',
      color: '#11B7AD',
    },
    {
      imageUrl: '/log/lunch.svg',
      subtype: 'Lunch',
      backgroundColor: '#FFF1E0',
      color: '#E57E00',
    },
    {
      imageUrl: '/log/dinner.svg',
      subtype: 'Dinner',
      backgroundColor: '#E6E0FF',
      color: '#4226B4',
    },
    {
      imageUrl: '/log/snack.svg',
      subtype: 'Snacks',
      backgroundColor: '#FFE0EB',
      color: '#BD1383',
    },
  ];

  return (
    <Div>
      <Title>{type} type</Title>
      <ButtonContainer>
        {foodSubtypeList.map((object) => (
          <Button
            isSelected={object.subtype === selectedSubtype}
            backgroundColor={object.backgroundColor}
            selectedBorderColor={object.color}
            onClick={() => toggleSelectedSubtype(object.subtype)}
          >
            <Icon src={object.imageUrl} />
            <Text color={object.color}>{object.subtype}</Text>
          </Button>
        ))}
      </ButtonContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  margin: 16px auto;
`;
const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 16px;
`;
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 8px;
  width: 100%;
`;
const Button = styled.button<{
  isSelected: boolean;
  backgroundColor: string;
  selectedBorderColor: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid
    ${(props) => (props.isSelected ? props.selectedBorderColor : 'transparent')};
  border-radius: 16px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  outline: none;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 8px;
`;
const Text = styled.p<{ color: string }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${(props) => props.color};
`;
