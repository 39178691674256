import React from 'react';
import { toast } from 'react-toastify';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled from 'styled-components';
import { numberToGameUnits } from 'src/utils/game';

interface ShopItemProps {
  title: string;
  imageUrl: string;
  description: string;
  price: number;
  isPurchasable: boolean;
}

export const ShopItem: React.FC<ShopItemProps> = ({
  title,
  imageUrl,
  description,
  price,
  isPurchasable,
}) => {
  const { user } = useUserStore();

  const handleClickPurchase = () => {
    toast('🚧 Coming Soon!');
    logEvent('touch_purchase_item', user);
  };

  return (
    <Div>
      <Title>{title}</Title>
      <Image src={imageUrl} alt="" />
      <Description>{description}</Description>
      <Button isPurchasable={isPurchasable} onClick={handleClickPurchase}>
        <Icon src={'/home/coin_header.svg'} alt="" />
        {numberToGameUnits(price)}
      </Button>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0f0f140a;
  padding: 16px;
  border-radius: 12px;
`;
const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #2e2e33;
  width: 100%;
  text-align: center;
`;
const Image = styled.img`
  width: 112px;
  height: 112px;
  object-fit: contain;
`;
const Description = styled.p`
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #717175;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`;
const Button = styled.button<{ isPurchasable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  padding: 5px 12px;
  cursor: pointer;
  border: none;
  outline: none;
  background: ${({ isPurchasable }) => (isPurchasable ? '#0bab7c' : '#c2efe2')};
  box-shadow: 0px 4px 20px 0px #0bab7c4d;

  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
`;
const Icon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 12px;
`;
