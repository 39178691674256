// Map keyboard key codes to controller's state keys
const keyMap = {
  Space: 'space',
  KeyW: 'up',
  ArrowUp: 'up',
  KeyA: 'left',
  ArrowLeft: 'left',
  KeyS: 'down',
  ArrowDown: 'down',
  KeyD: 'right',
  ArrowRight: 'right',
};

// Class for handling keyboard inputs.
export class Controller {
  keys: {
    up: { pressed: boolean; doubleTap: boolean; timestamp: number };
    left: { pressed: boolean; doubleTap: boolean; timestamp: number };
    down: { pressed: boolean; doubleTap: boolean; timestamp: number };
    right: { pressed: boolean; doubleTap: boolean; timestamp: number };
    space: { pressed: boolean; doubleTap: boolean; timestamp: number };
  };
  constructor() {
    // The controller's state.
    this.keys = {
      up: { pressed: false, doubleTap: false, timestamp: 0 },
      left: { pressed: false, doubleTap: false, timestamp: 0 },
      down: { pressed: false, doubleTap: false, timestamp: 0 },
      right: { pressed: false, doubleTap: false, timestamp: 0 },
      space: { pressed: false, doubleTap: false, timestamp: 0 },
    };
  }
}
