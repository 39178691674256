import React, { useState } from 'react';
import usePlayerStore from 'src/store/usePlayerStore';
import styled from 'styled-components';
import { GNB } from 'src/components/common/GNB';
import { PetInfo } from './components/PetInfo';
import { ProfileHeader } from './components/ProfileHeader';

const Home = () => {
  return (
    <Div>
      <ProfileHeader />
      <PetContainer>
        <PetInfo />
      </PetContainer>
      <GNB />
    </Div>
  );
};

export default Home;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100dvh;
  max-width: 500px;
  margin: 0 auto;

  background: linear-gradient(
    180deg,
    #a9faff 0%,
    rgba(169, 250, 255, 0.5) 52%,
    rgba(169, 250, 255, 0.15) 100%
  );
`;
const PetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
