import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';
import Head from './components/common/Head';
import 'react-toastify/dist/ReactToastify.css';
import CustomToastContainer from './components/common/CustomToastContainer';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_ID } from './environment';
import LogRocket from 'logrocket';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

  @font-face {
    font-family: 'Pretendard';
    src: url('./public/fonts/Pretendard-Regular.woff2') format('woff2');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    font-family: "Rubik", Pretendard, Arial, sans-serif !important;
    font-optical-sizing: auto;
  }
`;

// Initialize Amplitude
amplitude.init(AMPLITUDE_ID);
LogRocket.init('hcrxaj/sugar-walkin');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Head />
    <GlobalStyle />
    <App />
    <CustomToastContainer />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
