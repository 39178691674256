import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { libreLinkupLogin } from 'src/api/LibreProxyApiService';
import { createUser, login } from 'src/api/UserApiService';
import styled from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';
import useAuthStore from 'src/store/useAuthStore';
import { logEvent, setUserProperties } from 'src/utils';
import { useSearchParams } from 'react-router-dom';
import { BackButton } from '../Signup/SignUpFormPage';

declare global {
  interface Window {
    MSStream?: any;
  }
}

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const [searchParams] = useSearchParams();
  const isLibreLinkUpUser =
    (searchParams.get('isLibreLinkUpUser') || 'false') === 'true';
  const device = searchParams.get('device') || 'Libre';
  const subDevice = searchParams.get('subDevice') || '';

  useEffect(() => {
    logEvent('view_web_login', {});
  }, []);

  const handleFowardingToAppStore = () => {
    if (!window) return;

    // Define the URLs for iOS and Android
    const IOS_URL = 'https://apps.apple.com/us/app/librelinkup/id1234323923';
    const ANDROID_URL =
      'https://play.google.com/store/apps/details?id=org.nativescript.LibreLinkUp&hl=en_US';

    // Detect the OS
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window['MSStream'];

    // Determine the destination based on the OS
    const destination = isAndroid ? ANDROID_URL : IOS_URL;

    // Navigate to the appropriate page, on a separate window
    logEvent('touch_librelinkup_url', destination);
    window.open(destination, '_blank');
  };

  const handleClickSignup = () => {
    navigate('/signup');
  };

  const handleLogin = async (email: string, password: string) => {
    const data = await login(email.trim(), password);
    return data;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!email || !password) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }

    try {
      const data = await handleLogin(email, password);
      // Assuming status 0 indicates success
      if (data.status === 0 || data.data?.authTicket) {
        console.log('Login successful, data: ', data);
        // Set user details and token
        amplitude.setUserId(email);
        setUserProperties('userCgmType', `${device} ${subDevice}`);
        logEvent('login_success', { email: email });
        authStore.loginWithLibreLinkUp(data.data.authTicket, email);

        navigate('/');
      } else if (data.data?.success) {
        console.log('Login successful, data: ', data);
        // Set user details and token
        amplitude.setUserId(email);
        setUserProperties('userCgmType', `${device} ${subDevice}`);
        logEvent('login_success', { email: email });
        authStore.login(email);

        navigate('/');
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.error('Failed to submit:', error);
      logEvent('login_failure', { error: error });
      setShowError(true);
    }
  };

  const handleTooltipClick = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <Div>
      {isLibreLinkUpUser && (
        <BackButtonWrapper>
          <BackButton onClick={() => navigate(-1)}>← Back</BackButton>
        </BackButtonWrapper>
      )}
      <TitleContainer>
        <Title>Sugar Walkin'</Title>
        <Subtitle>
          "What if managing diabetes could be fun?"
          <br />
          Stay healthy and help your pet duck reach amazing destinations!
          <br />
          Developed by a medical doctor.
        </Subtitle>
      </TitleContainer>
      <ContentContainer>
        <InputContainer>
          <KeyText>
            {isLibreLinkUpUser ? 'Libre Linkup Email' : "Sugar Walkin' Email"}
            <HelpIcon onClick={handleTooltipClick}>
              <img
                src="/icons/icon-circle-question.svg"
                width={'20px'}
                height={'20px'}
                alt="help"
              />
              {showTooltip && (
                <Tooltip>
                  {isLibreLinkUpUser
                    ? `Please enter your Libre LinkUp username and password.`
                    : `Please enter your login information based on the CGM sensor you selected at registration. If you registered with Libre, enter your Libre LinkUp username and password. For other users, please enter the email and password you registered with.`}
                </Tooltip>
              )}
            </HelpIcon>
          </KeyText>
          <Input
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <KeyText>Password</KeyText>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        <ErrorContainer>
          {showError && (
            <ErrorText>Please enter a correct username and password.</ErrorText>
          )}
        </ErrorContainer>
        <SubmitButton onClick={handleSubmit}>Start Journey</SubmitButton>
        {isLibreLinkUpUser ? (
          <BottomText>Don't you have an Libre Linkup account?</BottomText>
        ) : (
          <BottomText>Don't you have an account?</BottomText>
        )}
        {isLibreLinkUpUser ? (
          <SignupText onClick={handleFowardingToAppStore}>Sign Up</SignupText>
        ) : (
          <SignupText onClick={handleClickSignup}>Sign Up</SignupText>
        )}
      </ContentContainer>
    </Div>
  );
};

export default Login;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100dvh;
  overflow-y: hidden;
  position: relative;
  padding: 2rem;

  background: url('/login/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  height: 150px;
`;
const Title = styled.h1`
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  color: #110073;
  margin-bottom: 16px;
`;
const Subtitle = styled.h2`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #110073;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  padding: 0.5rem;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
`;
const KeyText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const HelpIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  color: #fff;
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 120%;
  left: -55px;
  transform: translateX(-25%);
  background: var(--Gray-600, #4b4b4f);

  color: var(--White, var(--Gray-White, #fff));
  /* Body/14px/Regular */
  text-align: left;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  white-space: pre-wrap;
  z-index: 10;
  width: 350px;
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 55.5%;
    transform: translateX(-47px);
    border-width: 6px;
    border-style: solid;
    border-color: #4b4b4f transparent transparent transparent;
  }
`;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdde1;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 12px 16px;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #110073;

  &:focus {
    outline: none;
    color: black;
  }

  &::placeholder {
    color: #abb0ba;
  }
`;
const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-bottom: 4px;
`;
const ErrorText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: #ff7762;
`;
const SubmitButton = styled.button`
  background-color: #0bab7c;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #0bab7c4d;
  border-radius: 14px;

  width: 100%;
  height: 56px;
  padding: 16px 24px;
  margin-bottom: 16px;
  z-index: 100;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fbfaf3;
`;
const BottomText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: #b4b4b8;
  margin-bottom: 8px;
`;
const SignupText = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
`;
