import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OnboardingNextProps } from '.';
import OnboardingGameCard from './components/OnboardingGameCard';
import SpineAnimation from '../Journey/DuckWalk';
import PixiComponent from '../Journey/Pixi';
import { ActionButton } from './EntrancePage';
import useJourneyStore from 'src/store/useJourneyStore';
import { TopBar } from '../Home/components/TopBar';
import { LottieContainer } from '../Home';
import Lottie from 'lottie-react';
import coinAnimation from '../Home/lottie/coin.json';
import { CharacterAbsoluteWrapper } from '../Journey/JourneyPage';
import { logEvent } from 'src/utils';

// const btnTextArray = ['If not?', 'Got it', "That's great"];
const btnTextArray = [
  `Wow, you're linked to me!`,
  `I'll make sure to log my meals :)`,
  `Let's start our journey!`,
];
const displayedTexts = [
  `In this game, I'm always walking, starting from my forest village. I'm linked to your glucose monitor, and your glucose levels will affect how fast I walk.`,
  `If your glucose is out of range, steep hills will stop me in my tracks. You can log your meals and activities to make the hills disappear`,
  `I'm earning gold coins as I walk. You can use coins to buy me costumes, vehicles, and energy boosts that helps my journey.`,
];
const glucoseValues = [90, 195];
const glucoseStatuses = ['Good', 'Poor', 'Good', 'Good', 'Good'];
const worldIndexes = [2, 5, 11, 11];

const TravelTutorialPage: React.FC<OnboardingNextProps> = ({
  onClickNext,
}: OnboardingNextProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showGameCard, setShowGameCard] = useState<boolean>(true);
  const [showTopBar, setShowTopBar] = useState<boolean>(false);
  const [showCoinAnimation, setShowCoinAnimation] = useState<boolean>(false);
  const [displayCoins, setDisplayCoins] = useState<number>(4000);
  const { handleAnimation } = useJourneyStore();

  useEffect(() => {
    logEvent('view_web_onboarding_tutorial', {});
  }, []);

  const handleClick = () => {
    if (currentIndex === btnTextArray.length - 1) {
      onClickNext();
    }
    if (currentIndex >= glucoseValues.length - 1) {
      setShowGameCard(false);
      setShowTopBar(true);
      setShowCoinAnimation(true);
    }
    setCurrentIndex((prev) => prev + 1);
  };

  useEffect(() => {
    if (glucoseStatuses[currentIndex] === 'Good') {
      handleAnimation('walk_flat');
    }
    if (glucoseStatuses[currentIndex] === 'Poor') {
      handleAnimation('walk_uphill');
    }
  }, [currentIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayCoins((prev) => prev + 1000);
    }, 500);

    if (!showTopBar) clearInterval(interval);

    return () => clearInterval(interval);
  }, [showTopBar]);

  return (
    <PageContainer>
      {showTopBar && <TopBar coins={displayCoins} highlightShadow />}
      <JourneyContainer>
        <CharacterAbsoluteWrapper bottomHeight={'45%'}>
          {showCoinAnimation && (
            <LottieContainer
              width="60px"
              height="60px"
              top="-80px"
              left="calc(50% - 30px)"
            >
              <Lottie animationData={coinAnimation} loop={true} />
            </LottieContainer>
          )}
          <SpineAnimation
            atlasUrl="/sprites/duck/duck.atlas"
            jsonUrl="/sprites/duck/duck.json"
          />
        </CharacterAbsoluteWrapper>
        <SpeechBubble top={showTopBar ? ' 72px' : '16px'}>
          {displayedTexts[currentIndex]}
        </SpeechBubble>
        <PixiComponent
          height={window.innerHeight}
          currentWorldIndex={worldIndexes[currentIndex]}
        />
      </JourneyContainer>
      {showGameCard && (
        <OnboardingGameCard
          glucoseData={{
            Value: glucoseValues[currentIndex],
            Timestamp: new Date().toISOString(),
          }}
        />
      )}
      <ButtonWrapper>
        <ActionButton onClick={handleClick}>
          {btnTextArray[currentIndex]}
        </ActionButton>
      </ButtonWrapper>
    </PageContainer>
  );
};

export default TravelTutorialPage;

const PageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
  height: 100dvh;
  background-color: #e0f7fa;
`;

const SpeechBubble = styled.div<{ top: string }>`
  position: absolute;
  top: ${(props) => props.top};
  display: flex;
  width: 343px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #fdf4db;
  box-shadow: 0px 5px 0px 0px #eacea5;

  color: var(--Green-800, #00422f);
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const JourneyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  z-index: 0;
  position: absolute;
  bottom: 0;
  padding: 0 16px;
  padding-top: 30%;
  width: calc(100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
`;
