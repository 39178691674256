import { Application, Assets, Graphics } from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from './Controller';
import { Scene } from './Scene';
import { Duck } from './Duck';
import useJourneyStore from '../../store/useJourneyStore';
import useGameStore from 'src/store/useGameStore';
import { backgrounds } from './assets';
import { WORLDS } from 'src/models/worlds';

interface PixiComponentProps {
  height: number;
  currentWorldIndex: number;
}

const PixiComponent: React.FC<PixiComponentProps> = ({
  height,
  currentWorldIndex,
}) => {
  const pixiContainer = useRef<HTMLDivElement>(null);
  const { scene, setScene, backgroundSpeed, animationState } =
    useJourneyStore();
  const { setIsBackgorundLoading } = useGameStore();

  const width = window.innerWidth;
  useEffect(() => {
    const init = async () => {
      setIsBackgorundLoading(true);
      const app = new Application();
      await app.init({
        width: width,
        height: height,
        backgroundColor: 0x1099bb,
        preference: 'webgpu',
      });

      if (pixiContainer.current?.childElementCount) {
        return;
      }

      pixiContainer.current?.appendChild(app.canvas);

      try {
        const assetList = backgrounds.images.map((fileName: string) => {
          const alias = fileName.split('.')[0];
          const src = `/sprites/backgrounds/${fileName}`;
          return { alias, src };
        });

        Assets.add(assetList);

        await Assets.load(assetList);
        console.log('Assets loaded successfully');
      } catch (error) {
        console.error('Failed to load assets:', error);
      }

      const controller = new Controller();
      const scene = new Scene(app.screen.width, app.screen.height);
      setScene(scene);
      // const duck = new Duck();

      scene.view.y = app.screen.height;
      // duck.view.x = app.screen.width;
      // duck.view.y = app.screen.height - 100;
      // duck.spine.scale.set(scene.scale);

      app.stage.addChild(scene.view);

      scene.rotatePlatform(0);

      app.ticker.add(() => {
        // Ignore the update loops while the character is doing the spawn animation.

        // Update character's state based on the controller's input.
        // duck.state.walk_flat = true;

        // Update character's animation based on the latest state.
        // duck.update();

        // Determine the scene's horizontal scrolling speed based on the character's state.
        let adjustSpeed = 1;

        // if (duck.state.walk_flat) adjustSpeed = 1;
        // else if (duck.state.walk_uphill) adjustSpeed *= 0.5;
        // else if (duck.state.walk_downhill) adjustSpeed = 1;

        // Shift the scene's position based on the character's facing direction, if in a movement state.
        // if (
        //   duck.state.walk_flat ||
        //   duck.state.walk_uphill ||
        //   duck.state.walk_downhill
        // )
        scene.positionX -= adjustSpeed / 10;
      });
      setIsBackgorundLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    if (!scene) return;

    const changeWorld = async () => {
      const nextWorld = WORLDS[currentWorldIndex];
      await scene.setBackgroundLayers(nextWorld.sprites);
    };
    changeWorld();
  }, [scene, currentWorldIndex]);

  useEffect(() => {
    if (!scene) return;
    if (animationState === 'walk_uphill') {
      scene.setBackgroundSpeed(backgroundSpeed / 2);
    } else {
      scene.setBackgroundSpeed(backgroundSpeed);
    }
  }, [scene, backgroundSpeed, animationState]);

  return <div id="pixi-container" ref={pixiContainer} />;
};

export default PixiComponent;
