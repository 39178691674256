import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GNB } from 'src/components/common/GNB';
import { TopBar } from './components/TopBar';
import { GameCard } from './components/GameCard';
import Lottie from 'lottie-react';
import heartAnimation from './lottie/heart.json';
import coinAnimation from './lottie/coin.json';
import coinDropAnimation from './lottie/coin-drop.json';
import useAudio from 'src/hooks/useAudio';
import {
  CharacterAbsoluteWrapper,
  JourneyContainer,
} from '../Journey/JourneyPage';
import SpineAnimation from '../Journey/DuckWalk';
import PixiComponent from '../Journey/Pixi';
import useGameStore from 'src/store/useGameStore';
import { logEvent } from 'src/utils';
import useAuthStore from 'src/store/useAuthStore';
import { libreGlucoseGraphData } from 'src/api/LibreProxyApiService';
import { determineGlucoseTrend, numberToGameUnits } from 'src/utils/game';
import useJourneyStore from 'src/store/useJourneyStore';
import useUserStore from 'src/store/useUserStore';
import { GlucoseAnimationRecord, LifelogData } from 'src/models';
import {
  convertGlucoseRawDataToGlucoseAnimationRecords,
  mergeGlucoseData,
} from 'src/utils/glucose-parser';
import { SyncPopup } from './components/SyncPopup';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon, LoadingText } from 'src/components/common/Loading';
import { NextWorldPopup } from './components/NextWorldPopup';
import useBottomSheetStore from 'src/store/useBottomSheetStore';
import { Bottomsheet } from 'src/components/common/BottomSheet';
import { JourneyRewardContent } from '../Journey/components/JourneyRewardContent';
import { ActionButton } from '../Onboarding/EntrancePage';
import { getLocalStorageEmail } from 'src/storage/user.localstorage';
import { getLifelog } from 'src/api/LifelogService';

const Home: React.FC = () => {
  const [showLottie, setShowLottie] = useState(false);
  const [showEventCard, setShowEventCard] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lifelogs, setLifelogs] = useState<LifelogData[]>([]);
  const [lifelogLoading, setLifelogLoading] = useState(false);
  const [replayDirections, setReplayDirections] = useState<
    GlucoseAnimationRecord[]
  >([]);
  const [glucoseTrend, setGlucoseTrend] = useState(0);
  const { token } = useAuthStore();
  const { getState: getUserState, setUser } = useUserStore();
  const [isPaused, setIsPaused] = useState(false);
  const [pausedCount, setPausedCount] = useState(0);
  const [showRefreshLoading, setShowRefreshLoading] = useState(false);
  const { backgroundSpeed, handleAnimation, scene, setSpeed } =
    useJourneyStore();
  const {
    pause,
    resum,
    currentWorldIndex,
    coins,
    isShowNextWorldModal,
    totalDistanceTraveled,
    setIsShowNextWorldModal,
    tickCoins,
    isShowJourneyRewardAnimation,
    setIsJourneyRewardAnimation,
    journeyReward,
    resetJourneyReward,
    isBackgroundLoading,
    isCharacterLoading,
    isLoading,
  } = useGameStore();
  const { user } = getUserState();
  const librenToken = token();

  const {
    isBottomsheetVisible,
    setIsBottomsheetVisible,
    isFirstBgmUserPopupVisible,
    setIsFirstBgmUserPopupVisible,
  } = useBottomSheetStore();
  const navigate = useNavigate();

  const [showSyncPopup, setShowSyncPopup] = useState(false);

  // const [isBGMPlaying, toggleBGM] = useAudio(
  //   '/audio/suno_serene_reverie.mp3',
  //   true,
  // );
  // const [isDuckPlaying, toggleDuck] = useAudio('/audio/duck.wav', false);

  useEffect(() => {
    logEvent('view_web_home', getUserState());
  }, []);

  const handleClickPet = () => {
    if (showLottie) return;
    logEvent('touch_duck_home', getUserState());
    setShowLottie(true);
    setTimeout(() => {
      setShowLottie(false);
    }, 3000);
  };

  const fetchCurrentGlucoseData = async (isClicked: boolean = false) => {
    if (!librenToken) return;
    try {
      setShowRefreshLoading(true);
      const response = await libreGlucoseGraphData(librenToken);
      let glucoseTrend = 0;
      if (response) {
        const { currentGlucose, glucoseGraphData, lastGlucoseTime } = response;
        console.log('currentGlucose user', user);
        const newGlucoseGraphData = mergeGlucoseData(
          user?.data?.glucoseGraphData || [],
          glucoseGraphData,
        );
        if (user) {
          const currentLastGlucoseTime = new Date(
            user.data?.lastGlucoseTime || 0,
          );
          if (
            new Date(lastGlucoseTime).getTime() ===
              currentLastGlucoseTime.getTime() &&
            isClicked
          ) {
            alert('No new data. Please try again later.');
            return;
          }

          setUser({
            ...user,
            data: {
              ...user.data,
              currentGlucose,
              lastGlucoseTime,
              glucoseGraphData: newGlucoseGraphData,
            },
          });
        }
        glucoseTrend = determineGlucoseTrend(response.glucoseGraphData);
        setGlucoseTrend(glucoseTrend);
      }
    } finally {
      setShowRefreshLoading(false);
    }
  };

  useEffect(() => {
    if (glucoseTrend >= 20) {
      handleAnimation('walk_uphill');
    } else if (glucoseTrend <= -20) {
      handleAnimation('walk_downhill');
    } else {
      handleAnimation('walk_flat');
    }
  }, [glucoseTrend, handleAnimation]);

  useEffect(() => {
    setSpeed(1);
    if (journeyReward > 0) {
      setIsBottomsheetVisible(true);
    } else {
      setIsBottomsheetVisible(false);
    }
  }, [journeyReward]);

  useEffect(() => {
    fetchCurrentGlucoseData(); // Call the API when the component first renders
    const intervalId = setInterval(fetchCurrentGlucoseData, 300000); // Call the API every 5 minutes
    return () => clearInterval(intervalId); // Clean up dthe interval on component unmount
  }, []);

  useEffect(() => {
    const { user } = getUserState();
    const lastEarnedDailyGlucoseRewardAt =
      user?.data?.lastEarnedDailyGlucoseRewardAt || 0;
    const lastGlucoseTime = new Date(
      user?.data?.lastGlucoseTime || 0,
    ).getTime();

    const lifeGlucoseLevelLogs = lifelogs
      ?.filter((lifelog) => lifelog.type === 'glucoseLevel')
      .sort(
        (a, b) =>
          new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
      );

    let lastBgmGlucoseTime = new Date(0).getTime();

    if (lifeGlucoseLevelLogs && lifeGlucoseLevelLogs?.length > 0) {
      lastBgmGlucoseTime = new Date(lifeGlucoseLevelLogs[0].datetime).getTime();
    }

    if (
      lastGlucoseTime === new Date(0).getTime() &&
      lastBgmGlucoseTime === new Date(0).getTime()
    ) {
      return;
    }
    if (!user) {
      return;
    }

    // lastEarnedDailyGlucoseRewardAt이 user.data.lastGlucoseTime과 N분 차이난다면
    if (
      (lastGlucoseTime - lastEarnedDailyGlucoseRewardAt >= 1000 * 60 * 3 ||
        lastBgmGlucoseTime - lastEarnedDailyGlucoseRewardAt >= 1000 * 30) &&
      journeyReward === 0
    ) {
      console.log(
        'last earned daily reward at',
        new Date(lastEarnedDailyGlucoseRewardAt).toISOString(),
      );
      console.log(
        'last bgm glucose time',
        new Date(lastBgmGlucoseTime).toISOString(),
      );
      console.log('last glucose time - ', lastGlucoseTime);
      console.log(
        'lastEarnedDailyGlucoseRewardAt, lastGlucoseTime',
        lastGlucoseTime - lastEarnedDailyGlucoseRewardAt,
      );
      setShowSyncPopup(true);
      pause();
    }
  }, [
    journeyReward,
    lifelogs,
    user,
    user?.data?.glucoseGraphData,
    user?.data?.lastGlucoseTime,
  ]);

  useEffect(() => {
    if (isPaused) {
      setShowEventCard(true);
    }
  }, [isPaused]);

  useEffect(() => {
    if (isShowNextWorldModal) {
      pause();
    } else {
      resum();
    }
  }, [isShowNextWorldModal]);

  useEffect(() => {
    const email = getLocalStorageEmail();
    const get = async () => {
      try {
        setLifelogLoading(true);
        if (email) {
          const result = await getLifelog(email, 'all');
          setLifelogs(result);
        }
      } finally {
        setLifelogLoading(false);
      }
    };
    get();
  }, []);

  useEffect(() => {
    if (lifelogs) {
      const glucoseLevelLogs = lifelogs.filter(
        (lifelog) => lifelog.type === 'glucoseLevel',
      );
      if (glucoseLevelLogs.length === 0 && !user?.data?.currentGlucose) {
        setIsFirstBgmUserPopupVisible(true);
      } else {
        setIsFirstBgmUserPopupVisible(false);
      }
    }
  }, [
    lifelogs,
    setLifelogs,
    isFirstBgmUserPopupVisible,
    user?.data?.currentGlucose,
    setIsFirstBgmUserPopupVisible,
  ]);

  const handleClickSyncPopup = () => {
    setTimeout(() => {
      navigate('/journey');
      setShowSyncPopup(false);
      logEvent('sync_start', getUserState());
    }, 300);
  };

  return (
    <Div>
      {showModal && <Overlay />}
      {showSyncPopup && !isShowNextWorldModal && (
        <SyncPopup handler={handleClickSyncPopup} />
      )}
      {isShowNextWorldModal && (
        <NextWorldPopup
          currentWorldIndex={currentWorldIndex}
          totalDistance={totalDistanceTraveled}
          tickCoins={tickCoins}
          handler={() => {
            setIsShowNextWorldModal(false);
          }}
        />
      )}
      {isShowJourneyRewardAnimation && (
        <LottieContainer width="100vw" height="90%" top="-150px" left="0px">
          <Lottie animationData={coinDropAnimation} loop={false} />
        </LottieContainer>
      )}
      {/* {isLoading() && (
        <LoadingScreen>
          <LoadingIcon />
        </LoadingScreen>
      )} */}
      <TopBar coins={coins} />
      <JourneyContainer>
        <CharacterAbsoluteWrapper bottomHeight={'45%'} onClick={handleClickPet}>
          {showLottie ? (
            <LottieContainer width="100%" height="100px" top="-150px" left="0">
              <Lottie animationData={heartAnimation} loop={true} />
            </LottieContainer>
          ) : (
            <LottieContainer
              width="60px"
              height="60px"
              top="-80px"
              left="calc(50% - 30px)"
            >
              <Lottie animationData={coinAnimation} loop={true} />
            </LottieContainer>
          )}
          <SpineAnimation
            atlasUrl="/sprites/duck/duck.atlas"
            jsonUrl="/sprites/duck/duck.json"
          />
        </CharacterAbsoluteWrapper>
        <PixiComponent
          height={window.innerHeight}
          currentWorldIndex={currentWorldIndex}
        />
        <GameCard
          onClickRefresh={() => {
            fetchCurrentGlucoseData(true);
            setTimeout(() => {
              setShowRefreshLoading(false);
            }, 1000);
          }}
          isRefreshLoading={showRefreshLoading}
          showRefresh={!!librenToken}
        />
      </JourneyContainer>

      {isFirstBgmUserPopupVisible && !lifelogLoading && (
        <Bottomsheet
          title=""
          contentComponent={
            <BgmPopupContainer>
              <GifWrapper>
                <img
                  src={'/home/duck-flat-walk.gif'}
                  width={55}
                  height={72}
                  alt="Duck Gif"
                />
                <img
                  src={'/home/arrow-purple.gif'}
                  width={88}
                  height={35}
                  alt="Arrow Gif"
                />
                <img
                  src={'/home/flag-purple.gif'}
                  width={80}
                  height={80}
                  alt="Flag Gif"
                />
              </GifWrapper>
              <BgmPopupMessage>
                Enter your <span>glucose level</span> to see how far your ducky
                walked!
              </BgmPopupMessage>
              <ActionButton
                onClick={() => {
                  setIsFirstBgmUserPopupVisible(false);
                  navigate('/lifelog/glucoseLevel');
                }}
              >
                Enter Glucose Level
              </ActionButton>
            </BgmPopupContainer>
          }
        ></Bottomsheet>
      )}

      {isBottomsheetVisible && (
        <Bottomsheet
          title={''}
          contentComponent={
            <JourneyRewardContent
              message={
                <p>
                  You've earned <span>{numberToGameUnits(journeyReward)}</span>{' '}
                  coins!
                </p>
              }
              buttonMessage="Receive Coins"
              handleClickButton={() => {
                resetJourneyReward();
                setIsJourneyRewardAnimation(true);
                logEvent('sync_complete', {
                  ...user,
                  reward: numberToGameUnits(journeyReward),
                });
                if (user) {
                  setUser({
                    ...user,
                    data: {
                      ...user.data,
                      lastEarnedDailyGlucoseRewardAt: new Date().getTime(),
                    },
                  });
                }
                setTimeout(() => {
                  setIsJourneyRewardAnimation(false);
                }, 3000);
              }}
            />
          }
        />
      )}
      <GNB />
    </Div>
  );
};

export default Home;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100dvh;
  background-size: 100% auto;
`;
export const LottieContainer = styled.div<{
  width: string;
  height: string;
  top: string;
  left: string;
}>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 101;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
`;

const LoadingScreen = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  background-color: #989898; /* 로딩 화면 배경색 */
  color: #fff; /* 로딩 텍스트 색상 */
  font-size: 2rem;
`;

const GifWrapper = styled.div`
  background-color: #f3eaff;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const BgmPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const BgmPopupMessage = styled.div`
  color: var(--Gray-700, var(--Gray-700, #2e2e33));
  text-align: center;
  /* Title/24px/Semibold */
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;

  span {
    color: var(--Green-500, #0bab7c);
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
