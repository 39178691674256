import React, { useEffect, useState } from 'react';
import usePlayerStore from 'src/store/usePlayerStore';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const ProfileHeader = () => {
  const { xp, coins, evolutionCoins } = usePlayerStore();
  const navigate = useNavigate();

  return (
    <Div>
      <BackButton onClick={() => navigate('/home')}>
        <img src="/navigation/header_back.svg" alt="" />
      </BackButton>
      <HeaderItem>
        <ItemIcon src="/home/gem.png" alt="" />
        <ValueText>{xp}</ValueText>
        <PlusButton>+</PlusButton>
      </HeaderItem>
      <HeaderItem>
        <ItemIcon src="/home/coin.svg" alt="" />
        <ValueText>{coins}</ValueText>
        <PlusButton>+</PlusButton>
      </HeaderItem>
      <HeaderItem>
        <ItemIcon src="/home/evolution_coin.png" alt="" />
        <ValueText>{evolutionCoins}</ValueText>
        <PlusButton>+</PlusButton>
      </HeaderItem>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #fff;
  justify-content: center;
`;
const BackButton = styled.button`
  display: flex;
  position: absolute;
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
`;
const HeaderItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0 1rem;
`;
const ItemIcon = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  margin-right: 0.5rem;
`;
const ValueText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #0b6579;
  margin: 0;
  margin-right: 0.5rem;
`;
const PlusButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #0b6579;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
`;
