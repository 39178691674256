import * as amplitude from '@amplitude/analytics-browser';
import { VERSION } from 'src/environment';

// Function to get the page domain
const getPageDomain = () => {
  return window.location.hostname;
};

// Function to log events
export const logEvent = (eventName: string, payload: any) => {
  const domain = getPageDomain();
  const eventPayload = {
    ...payload,
    domain: domain,
    version: VERSION,
  };

  // Check if environment is production
  if (process.env.NODE_ENV === 'production') {
    amplitude.logEvent(eventName, eventPayload);
  } else {
    console.log(`Event logged in development: ${eventName}`, eventPayload);
  }
};

export const setUserProperties = (key: string, value: any) => {
  const identify = new amplitude.Identify().set(key, value);

  // Check if environment is production
  if (process.env.NODE_ENV === 'production') {
    amplitude.identify(identify);
  } else {
    console.log(`Amplitude identify: ${key}`, value);
  }
};
