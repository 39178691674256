// src/App.tsx
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useJourneyStore from '../../store/useJourneyStore';
import SpineAnimation from './DuckWalk';
import PixiComponent from './Pixi';
import { libreGlucoseGraphData } from '../../api/LibreProxyApiService';
import useAuthStore from '../../store/useAuthStore';
import { determineGlucoseTrend } from '../../utils/game';
import FloatingIconButton from 'src/components/common/FloatingIconButton';
import LeftIconButton from 'src/components/common/LeftIconButton';
import { formatDateToString, formatTime } from 'src/utils/strings';
import { useNavigate } from 'react-router-dom';
import Slider from 'src/components/common/Slider';
import { RowDiv } from 'src/components/common/RowDiv';
import { convertGlucoseRawDataToGlucoseAnimationRecords } from 'src/utils/glucose-parser';
import {
  GlucoseAnimationRecord,
  GlucoseGraphData,
  GlucoseGraphRecord,
  LifelogData,
  User,
} from 'src/models';
import { TopBar } from '../Home/components/TopBar';
import Clock from './components/Clock';
import useGameStore from 'src/store/useGameStore';
import GameCard from '../Home/components/GameCard';
import OnboardingGameCard from '../Onboarding/components/OnboardingGameCard';
import GlucoseCharts from './components/GlucoseCharts';
import { WORLDS } from 'src/models/worlds';
import { EventCard } from '../Home/components/EventCard';
import { Bottomsheet } from 'src/components/common/BottomSheet';
import useBottomSheetStore from 'src/store/useBottomSheetStore';
import { JourneyRewardContent } from './components/JourneyRewardContent';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import { getLifelog } from 'src/api/LifelogService';
import { getLocalStorageEmail } from 'src/storage/user.localstorage';

const MockData = [
  {
    FactoryTimestamp: '5/22/2024 7:58:47 PM',
    Timestamp: '5/23/2024 4:58:47 AM',
    type: 0,
    ValueInMgPerDl: 90,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 90,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 8:13:47 PM',
    Timestamp: '5/23/2024 5:13:47 AM',
    type: 0,
    ValueInMgPerDl: 130,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 130,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 7:58:47 PM',
    Timestamp: '5/23/2024 4:58:47 AM',
    type: 0,
    ValueInMgPerDl: 150,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 150,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 10:43:47 PM',
    Timestamp: '5/23/2024 7:43:47 AM',
    type: 0,
    ValueInMgPerDl: 90,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 90,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 10:58:47 PM',
    Timestamp: '5/23/2024 7:58:47 AM',
    type: 0,
    ValueInMgPerDl: 91,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 91,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 11:13:47 PM',
    Timestamp: '5/23/2024 8:13:47 AM',
    type: 0,
    ValueInMgPerDl: 95,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 95,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 11:28:47 PM',
    Timestamp: '5/23/2024 8:28:47 AM',
    type: 0,
    ValueInMgPerDl: 96,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 96,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 11:43:47 PM',
    Timestamp: '5/23/2024 8:43:47 AM',
    type: 0,
    ValueInMgPerDl: 93,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 93,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/22/2024 11:58:47 PM',
    Timestamp: '5/23/2024 8:58:47 AM',
    type: 0,
    ValueInMgPerDl: 94,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 94,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 12:13:47 AM',
    Timestamp: '5/23/2024 9:13:47 AM',
    type: 0,
    ValueInMgPerDl: 101,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 101,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 12:28:47 AM',
    Timestamp: '5/23/2024 9:28:47 AM',
    type: 0,
    ValueInMgPerDl: 105,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 105,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 12:43:47 AM',
    Timestamp: '5/23/2024 9:43:47 AM',
    type: 0,
    ValueInMgPerDl: 101,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 101,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 12:58:47 AM',
    Timestamp: '5/23/2024 9:58:47 AM',
    type: 0,
    ValueInMgPerDl: 99,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 99,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 1:13:47 AM',
    Timestamp: '5/23/2024 10:13:47 AM',
    type: 0,
    ValueInMgPerDl: 96,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 96,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 1:28:47 AM',
    Timestamp: '5/23/2024 10:28:47 AM',
    type: 0,
    ValueInMgPerDl: 96,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 96,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 1:43:47 AM',
    Timestamp: '5/23/2024 10:43:47 AM',
    type: 0,
    ValueInMgPerDl: 95,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 95,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 1:58:47 AM',
    Timestamp: '5/23/2024 10:58:47 AM',
    type: 0,
    ValueInMgPerDl: 94,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 94,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 2:13:47 AM',
    Timestamp: '5/23/2024 11:13:47 AM',
    type: 0,
    ValueInMgPerDl: 94,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 94,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 2:28:47 AM',
    Timestamp: '5/23/2024 11:28:47 AM',
    type: 0,
    ValueInMgPerDl: 95,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 95,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 2:44:35 AM',
    Timestamp: '5/23/2024 11:44:35 AM',
    type: 0,
    ValueInMgPerDl: 97,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 97,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 2:59:35 AM',
    Timestamp: '5/23/2024 11:59:35 AM',
    type: 0,
    ValueInMgPerDl: 98,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 98,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 3:14:35 AM',
    Timestamp: '5/23/2024 12:14:35 PM',
    type: 0,
    ValueInMgPerDl: 99,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 99,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 3:29:01 AM',
    Timestamp: '5/23/2024 12:29:01 PM',
    type: 0,
    ValueInMgPerDl: 110,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 102,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 3:44:22 AM',
    Timestamp: '5/23/2024 12:44:22 PM',
    type: 0,
    ValueInMgPerDl: 125,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 113,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 3:59:22 AM',
    Timestamp: '5/23/2024 12:59:22 PM',
    type: 0,
    ValueInMgPerDl: 150,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 150,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 4:14:56 AM',
    Timestamp: '5/23/2024 1:14:56 PM',
    type: 0,
    ValueInMgPerDl: 130,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 130,
    isHigh: false,
    isLow: false,
  },
];
const MockBgmData = [
  {
    FactoryTimestamp: '5/22/2024 7:58:47 PM',
    Timestamp: '5/23/2024 4:58:47 AM',
    type: 0,
    ValueInMgPerDl: 90,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 90,
    isHigh: false,
    isLow: false,
  },
  {
    FactoryTimestamp: '5/23/2024 4:14:56 AM',
    Timestamp: '5/23/2024 1:14:56 PM',
    type: 0,
    ValueInMgPerDl: 130,
    MeasurementColor: 1,
    GlucoseUnits: 1,
    Value: 130,
    isHigh: false,
    isLow: false,
  },
];
const getLastEarnedDate = (
  lastEarnedDailyGlucoseRewardAt: number | null,
): Date => {
  const now = new Date();
  const eightHoursAgo = new Date(now.getTime() - 8 * 60 * 60 * 1000);
  return lastEarnedDailyGlucoseRewardAt
    ? new Date(lastEarnedDailyGlucoseRewardAt)
    : eightHoursAgo;
};

const filterGlucoseData = (glucoseData: any[], lastEarnedDate: Date): any[] => {
  return glucoseData
    .filter((record) => record !== null && record !== undefined)
    .filter((record) => new Date(record.Timestamp) > lastEarnedDate);
};

const getFilteredGlucoseData = (user: User | null): any[] => {
  if (!user) return [];
  const lastEarnedDate = getLastEarnedDate(
    user?.data?.lastEarnedDailyGlucoseRewardAt ?? null,
  );
  // console.log(lastEarnedDate);
  // console.log([...user?.data?.glucoseGraphData, user.data.currentGlucose]);
  return filterGlucoseData(
    [...(user?.data?.glucoseGraphData || []), user.data?.currentGlucose],
    lastEarnedDate,
  );
};

const getBgmGlucoseDatas = (
  user: User | null,
  lifelogs: LifelogData[],
): GlucoseGraphRecord[] => {
  if (!user) return [];
  const glucoseLevels = lifelogs
    .filter((lifelog) => lifelog.type === 'glucoseLevel')
    .sort(
      (o1, o2) =>
        new Date(o2.datetime).getTime() - new Date(o1.datetime).getTime(),
    )
    .slice(0, 2)
    .map((lifelog) => {
      return {
        Timestamp: lifelog.datetime,
        Value: parseFloat(lifelog.content),
      };
    });

  if (glucoseLevels.length === 0) {
    return [];
  }
  if (glucoseLevels.length >= 2) {
    return glucoseLevels.map((glucoseLevels) => {
      return {
        Timestamp: formatDateToString(new Date(glucoseLevels.Timestamp)),
        Value: glucoseLevels.Value,
      };
    });
  }
  const oneHoursAgo = new Date(
    new Date(glucoseLevels[0].Timestamp).getTime() - 60 * 60 * 1000,
  );
  const addedGlucoseLevels = [
    {
      Timestamp: oneHoursAgo.toISOString(),
      Value: 100,
    },
    ...glucoseLevels,
  ];

  return addedGlucoseLevels.map((glucoseLevels) => {
    return {
      Timestamp: formatDateToString(new Date(glucoseLevels.Timestamp)),
      Value: glucoseLevels.Value,
    };
  });
};

const App: React.FC = () => {
  const { handleAnimation, scene, setSpeed } = useJourneyStore();
  const {
    pause,
    resum,
    currentWorldIndex,
    coins,
    initialized,
    setJourneyReward,
  } = useGameStore();
  const { user, setUser } = useUserStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [replayDirections, setReplayDirections] = useState<
    GlucoseAnimationRecord[]
  >([]);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [pausedCount, setPausedCount] = useState(0);
  const [showEventModal, setShowEventModal] = useState(false);
  const navigate = useNavigate();
  const { isBottomsheetVisible, setIsBottomsheetVisible } =
    useBottomSheetStore();

  // Main handleReplay function
  const handleReplay = (lifelogs: LifelogData[] = []) => {
    try {
      if (user) {
        let filteredData = getFilteredGlucoseData(user);

        if (filteredData.length === 0) {
          filteredData = getBgmGlucoseDatas(user, lifelogs);
        }
        const result =
          convertGlucoseRawDataToGlucoseAnimationRecords(filteredData);
        setReplayDirections(result);
      }
    } catch (error) {
      alert('Something went wrong - ' + error);
      navigate('/');
    }
  };

  const handleLaterClick = (show: boolean) => {
    setIsBottomsheetVisible(!show);
    setShowEventModal(show);
  };

  const handleEventClick = () => {
    if (user) {
      setUser({
        ...user,
        data: {
          ...user.data,
          lastEarnedDailyGlucoseRewardAt: new Date().getTime(),
        },
      });
      setJourneyReward(getFilteredGlucoseData(user).length || 2 * 15);
    }
  };

  const playAnimation = (index: number) => {
    if (index >= replayDirections.length) return;

    const totalDuration = 5000; // 5 seconds
    const intervalDuration = totalDuration / replayDirections.length;
    const { animation, animationSpeed, rotate } = replayDirections[index];

    if (scene) {
      scene.rotatePlatform(rotate);
    }
    if (animation === 'walk_uphill' && pausedCount === 0) {
      setTimeout(() => {
        setSpeed(0.01);
      }, 500);
    } else {
      setSpeed(animationSpeed);
    }

    if (animation === 'walk_uphill' && pausedCount === 0) {
      setIsAnimationPaused(true);
      setShowEventModal(true);
      setPausedCount((prev) => prev + 1);
      return;
    }

    setTimeout(() => {
      setCurrentIndex(index + 1);
      playAnimation(index + 1);
    }, intervalDuration);
  };

  useEffect(() => {
    if (replayDirections.length > 0 && !isAnimationPaused) {
      playAnimation(currentIndex);
    }
    if (currentIndex === replayDirections.length - 1) {
      setCurrentIndex(0);
      setReplayDirections([]);
      setSpeed(1);
      setJourneyReward(getFilteredGlucoseData(user).length || 2 * 15);
      navigate('/');
    }
  }, [replayDirections, currentIndex, isAnimationPaused]);

  useEffect(() => {
    if (initialized && user) {
      const lastEarnedDailyGlucoseRewardAt =
        user?.data?.lastEarnedDailyGlucoseRewardAt || 0;
      const lastGlucoseTime = new Date(
        user?.data?.lastGlucoseTime || 0,
      ).getTime();
      if (
        lastGlucoseTime - lastEarnedDailyGlucoseRewardAt <
        3 * 60 * 60 * 1000
      ) {
        // navigate('/');
      }

      const email = getLocalStorageEmail();
      const get = async () => {
        if (email) {
          const result = await getLifelog(email, 'all');
          pause();
          handleReplay(result);
        }
      };
      get();
    }
    return () => {
      resum();
    };
  }, [initialized, user]);

  useEffect(() => {}, []);
  return (
    <StyledGameContainer>
      <TopBar coins={coins} showStore={false} />
      <JourneyContainer>
        <ClockPositionHolder>
          {replayDirections.length > 0 &&
            currentIndex < replayDirections.length && (
              <>
                <Clock time={replayDirections[currentIndex].Timestamp} />
                {/* <p style={{ color: 'white' }}>
                  {replayDirections[currentIndex].Value}
                </p> */}
              </>
            )}
        </ClockPositionHolder>

        <CharacterAbsoluteWrapper
          bottomHeight={'45%'}
          rotate={replayDirections[currentIndex]?.rotate + 'deg'}
        >
          <SpineAnimation
            atlasUrl="sprites/duck/duck.atlas"
            jsonUrl="sprites/duck/duck.json"
          />
        </CharacterAbsoluteWrapper>
        <PixiComponent
          height={window.innerHeight}
          currentWorldIndex={currentWorldIndex}
        />
        <ChartsContainer
          onClick={() => {
            if (scene) {
              // scene.rotatePlatform(0);
            }
          }}
        >
          <GlucoseCharts
            glucoseData={replayDirections}
            highlightIndex={currentIndex}
          />
        </ChartsContainer>
      </JourneyContainer>

      {showEventModal && (
        <EventCard
          onClickEventCard={() => {
            handleEventClick();
          }}
          setShowEventCard={handleLaterClick}
        />
      )}

      {isBottomsheetVisible && (
        <Bottomsheet
          title={''}
          contentComponent={
            <JourneyRewardContent
              message={
                <p>
                  No gold found here. <br /> Continue your quest to gather more
                  gold!
                </p>
              }
              buttonMessage="Continue"
              handleClickButton={() => {
                setIsBottomsheetVisible(false);
                logEvent('sync_complete', { ...user, reward: 'none' });
                if (user) {
                  setUser({
                    ...user,
                    data: {
                      ...user.data,
                      lastEarnedDailyGlucoseRewardAt: new Date().getTime(),
                    },
                  });
                }
                navigate('/');
              }}
            />
          }
        />
      )}

      {/* <OnboardingGameCard
        glucoseData={{
          Value: replayDirections[currentIndex]?.Value || 0,
          Timestamp: replayDirections[currentIndex]?.Timestamp || '',
        }}
        showEnergyBoostButton={false}
        showRefreshButton={false}
        showSpeed={false}
        currentWorldIndex={currentWorldIndex}
      /> */}
      {/* <Slider>
        <WalkStatusContainter>
          <ButtonContainer>
            <FloatingIconButton
              title="Back in"
              time={formatTime(secondsRemaining)}
              icon="/icons/icon-run-shoes.svg"
              onClick={() => {}}
            />
            <FloatingIconButton
              title="Best Record"
              time="3.7 Miles"
              icon="/icons/icon-medal.svg"
              onClick={() => {}}
            />
          </ButtonContainer>
          <VerticalButtonContainer
            onClick={() => {
              setJourneyStatusCurrentIndex(journeyStatusCurrentIndex + 1);
            }}
          >
            <VerticalButton>SHOP</VerticalButton>
          </VerticalButtonContainer>
        </WalkStatusContainter>
        <WalkStatusContainter>
          <VerticalButtonContainer
            onClick={() => {
              setJourneyStatusCurrentIndex(journeyStatusCurrentIndex - 1);
            }}
          >
            <VerticalButton>STATUS</VerticalButton>
          </VerticalButtonContainer>
          <ButtonContainer>
            <RowDiv gap={16}>
              <LeftIconButton
                label="Uphill"
                subLabel=""
                icon="/icons/icon-run-shoes.svg"
                style={{ width: '50%' }}
                onClick={() => {
                  handleUphill();
                }}
              />
              <LeftIconButton
                label="Idle"
                subLabel=""
                icon="/icons/icon-run-shoes.svg"
                style={{ width: '50%' }}
                onClick={() => {
                  handleIdle();
                }}
              />
            </RowDiv>
            <RowDiv gap={16}>
              <LeftIconButton
                label="Play"
                subLabel=""
                icon="/icons/icon-medal.svg"
                onClick={() => {
                  handlePausetoPlay();
                }}
              />
              <LeftIconButton
                label="Animation"
                subLabel=""
                icon="/icons/icon-medal.svg"
                onClick={() => {
                  handleReplay();
                }}
              />
            </RowDiv>
          </ButtonContainer>
        </WalkStatusContainter>
      </Slider> */}
    </StyledGameContainer>
  );
};

export default App;

const StyledGameContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100dvh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const JourneyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CharacterAbsoluteWrapper = styled.div<{
  bottomHeight: string;
  rotate?: string;
}>`
  position: absolute;
  transform: rotate(${(props) => props.rotate || '0deg'});
  bottom: ${(props) => props.bottomHeight};
`;

const TopBarContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  z-index: 1000;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: auto;
  padding: 16px;
  width: 100%;
`;

const WalkStatusContainter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const VerticalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const VerticalButton = styled.button`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: #1c8679;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
`;

const ClockPositionHolder = styled.div`
  position: absolute;
  top: 20%;
`;

const ChartsContainer = styled.div`
  position: absolute;
  bottom: 10%;
  display: flex;
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: var(--Ivory, #fbfaf3);
`;
