import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EntrancePage from './EntrancePage';
import HatchEggPage from './HatchEggPage';
import PronounSelectionPage from './PronounSelectionPage';
import NameYourPetPage from './NameYourPetPage';
import InteractiveProfilePage from './InteractiveProfilePage';
import CompletionPage from './CompletionPage';
import PersonalitySelectionPage from './PersonalitySelectionPage';
import GoalSettingPage from '../goal';
import { useNavigate, useParams } from 'react-router-dom';
import LinkupLoginPage from '../LinkupLogin';
import TravelTutorialPage from './TravelTutorialPage';
import useGameStore from 'src/store/useGameStore';
import OnboardingRoadMapPage from './OnboardingRoadMapPage';
import Login from '../Login';
import useUserStore from 'src/store/useUserStore';
import Signup from '../Signup';
import { getIsTutorialCompleted } from 'src/storage/user.localstorage';
import CheckLibre from './CheckLibre';

const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
`;

export type OnboardingNextProps = {
  onClickNext: () => void;
};

export type OnboardingQueryParams = {
  petGender: 'male' | 'female' | 'non-binary' | undefined;
  petName: string | undefined;
  petBirthDay: string | undefined; // Treat dates as strings
  personality: string | undefined;
  userOnlineGoal: string | undefined;
};

const Onboarding = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { pause, resum, initialized, getState } = useGameStore();
  const { user } = useUserStore();
  const isOnboardingCompleted = getIsTutorialCompleted();

  const query = useParams as Partial<OnboardingQueryParams>;
  const navigate = useNavigate();

  const goToNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
  };

  useEffect(() => {
    const allFieldsPresent = Object.values(query).every(
      (value) => value !== undefined,
    );
    if (allFieldsPresent) {
      console.log(query);
    }
  }, [query]);

  useEffect(() => {
    console.log(getState());
    if (initialized) {
      pause();
    }
  }, [initialized]);

  useEffect(() => {
    // if (getIsTutorialCompleted()) {
    //   navigate('/login', { replace: true });
    // }
  }, []);

  const pages = [
    // <HatchEggPage onClickNext={goToNextPage} />,
    // <TravelTutorialPage onClickNext={goToNextPage} />,
    <NameYourPetPage onClickNext={goToNextPage} />,
    // <OnboardingRoadMapPage onClickNext={goToNextPage} />,
    // <Signup />,
    <CheckLibre />,
  ];

  return <OnboardingContainer>{pages[currentPage]}</OnboardingContainer>;
};

export default Onboarding;
