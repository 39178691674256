import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { addWaitlist } from 'src/api/WaitlistService';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a;
  color: #fff;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffcc00;
  text-shadow: 2px 2px #000;
`;

const WaitList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const ListItem = styled.li<{ isRemoving?: boolean; isNew?: boolean }>`
  background: #333;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: left;
  font-size: 1.2rem;
  color: #ffcc00;
  animation: ${({ isRemoving, isNew }) =>
      isRemoving ? slideOut : isNew ? slideIn : 'none'}
    0.5s ease-in-out;
  border: 2px solid #ffcc00;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ffcc00;
  border-radius: 5px;
  width: 100%;
  font-size: 1.1rem;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ffcc00;
  color: #1a1a1a;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  &:hover {
    background-color: #e6b800;
  }
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  color: #ffcc00;
  cursor: pointer;
  font-size: 1.2rem;
  &:hover {
    color: #ff0000;
  }
`;

const maskEmail = (email: string) => {
  const [user, domain] = email.split('@');
  if (!domain) return email;
  const maskedUser =
    user.length > 2
      ? user[0] + '*'.repeat(user.length - 2) + user[user.length - 1]
      : user;
  return `${maskedUser}@${domain}`;
};

const generateRandomEmail = () => {
  const domains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'aol.com',
    'icloud.com',
  ];
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let user = '';
  for (let i = 0; i < 8; i++) {
    user += chars[Math.floor(Math.random() * chars.length)];
  }
  const domain = domains[Math.floor(Math.random() * domains.length)];
  return `${user}@${domain}`;
};

const getTimeAgo = (dateTime: string) => {
  const now = new Date();
  const time = new Date(dateTime);
  const diffInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (diffInSeconds < 61) return 'just now';
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
};

const generateRandomPastDate = () => {
  const now = new Date();
  const pastTime = new Date(now.getTime() - Math.random() * 40 * 60 * 1000);
  return pastTime;
};

interface Player {
  email: string;
  maskedEmail: string;
  dateTime: string;
}

const GameWaitList: React.FC<{ gameName: string }> = ({
  gameName,
}: {
  gameName: string;
}) => {
  //   const location = useLocation();
  //   const params = new URLSearchParams(location.search);
  //   const gameName = params.get('gameName') || 'Unknown Game';

  const generateInitialPlayers = (): Player[] => {
    const initialPlayers: Player[] = [];
    for (let i = 0; i < 5; i++) {
      const email = generateRandomEmail();
      const dateTime = generateRandomPastDate().toISOString();
      initialPlayers.push({ email, maskedEmail: maskEmail(email), dateTime });
    }
    return initialPlayers.sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
    );
  };

  const loadPlayersFromSession = (): Player[] => {
    const storedPlayers = sessionStorage.getItem('players');
    if (storedPlayers) {
      return JSON.parse(storedPlayers).sort(
        (a: Player, b: Player) =>
          new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
      );
    }
    return generateInitialPlayers();
  };

  const [email, setEmail] = useState('');
  const [players, setPlayers] = useState<Player[]>(loadPlayersFromSession);
  const [removingPlayers, setRemovingPlayers] = useState<string[]>([]);
  const [newPlayerEmail, setNewPlayerEmail] = useState<string>('');

  useEffect(() => {
    sessionStorage.setItem('players', JSON.stringify(players));
  }, [players]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlayers((prevPlayers) => {
        const email = generateRandomEmail();
        const newPlayer = {
          email,
          maskedEmail: maskEmail(email),
          dateTime: new Date().toISOString(),
        };
        const updatedPlayers = [newPlayer, ...prevPlayers].slice(0, 7);
        setNewPlayerEmail(email); // Set new player email for animation
        return updatedPlayers.sort(
          (a, b) =>
            new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
        );
      });
    }, 10000); // 10초마다 추가
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (email) {
      const newPlayer = {
        email,
        maskedEmail: maskEmail(email),
        dateTime: new Date().toISOString(),
      };
      setPlayers(
        [newPlayer, ...players]
          .slice(0, 7)
          .sort(
            (a, b) =>
              new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime(),
          ),
      );
      setNewPlayerEmail(email); // Set new player email for animation
      await addWaitlist(email, '');
      setEmail('');
    }
  };

  const handleRemove = (index: number) => {
    setRemovingPlayers((prev) => [...prev, players[index].email]);
    setTimeout(() => {
      setPlayers((prev) => prev.filter((_, i) => i !== index));
      setRemovingPlayers((prev) =>
        prev.filter((email) => email !== players[index].email),
      );
    }, 500);
  };

  return (
    <Container>
      <Title>{gameName} is launching soon</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <Button type="submit">Register</Button>
      </Form>
      <WaitList>
        {players
          .filter((p) => p.email)
          .map((player, index) => (
            <ListItem
              key={index}
              isRemoving={removingPlayers.includes(player.email)}
              isNew={player.email === newPlayerEmail}
              onAnimationEnd={() =>
                removingPlayers.includes(player.email) && handleRemove(index)
              }
            >
              {player.maskedEmail} <br />
              <small>{getTimeAgo(player.dateTime)}</small>
            </ListItem>
          ))}
      </WaitList>
    </Container>
  );
};

export default GameWaitList;
