import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { logEvent } from 'src/utils';
import useUserStore from 'src/store/useUserStore';

interface Props {
  type: string;
  handleSelectDatetime: (time: string) => void;
}

export const LogTime: React.FC<Props> = ({ type, handleSelectDatetime }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [showPicker, setShowPicker] = useState(false);
  const { user } = useUserStore();

  const handleDisplayClick = () => {
    setShowPicker(!showPicker);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setShowPicker(false);
  };

  useEffect(() => {
    if (showPicker) {
      logEvent('touch_lifelog_time', { user: user, type: type.toLowerCase() });
    }
  }, [showPicker]);

  useEffect(() => {
    if (selectedDate) {
      handleSelectDatetime(selectedDate.toISOString());
    }
  }, [selectedDate]);

  return (
    <Div>
      <Title>{type} Time & Date</Title>

      <Display onClick={handleDisplayClick}>
        {moment(selectedDate).format('MMMM D, h:mm A')}
      </Display>
      {showPicker && (
        <PickerPopup onClick={() => setShowPicker(false)}>
          <Inner onClick={(e) => e.stopPropagation()}>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="Pp"
              inline
            />
          </Inner>
        </PickerPopup>
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 2rem);
  margin: 16px auto;
`;
const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 16px;
`;

const Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background: #0f0f140a;
  border-radius: 12px;

  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
`;

const PickerPopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  padding-bottom: 1rem;
  z-index: 200;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;
const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
`;
