import React, { useState } from 'react';
import useGameStore from 'src/store/useGameStore';
import useJourneyStore from 'src/store/useJourneyStore';
import useUserStore from 'src/store/useUserStore';
import styled from 'styled-components';
import { logEvent } from 'src/utils';
import { formatDate, formatTime } from 'src/utils/strings';
import { numberToGameUnits } from 'src/utils/game';
import { WORLDS } from 'src/models/worlds';
import { GlucoseGraphRecord } from 'src/models';

interface GameCardProps {
  glucoseData: GlucoseGraphRecord;
  showEnergyBoostButton?: boolean;
  showRefreshButton?: boolean;
  showSpeed?: boolean;
  progress?: number;
  currentWorldIndex?: number;
}

export const OnboardingGameCard: React.FC<GameCardProps> = ({
  glucoseData,
  showEnergyBoostButton = true,
  showRefreshButton = true,
  currentWorldIndex = 6,
  showSpeed = true,
}) => {
  const energyBoostPrice = 500;
  const displayCharacterSpeed = 6;
  const currentProgress = 0.4;
  const currentLeftDistance = 2.1;

  const glucoseStatus = (glucoseData.Value || 0) >= 180 ? 'Poor' : 'Good';

  const handleClickEnergyBoost = () => {};

  return (
    <Div>
      <HeaderContainer status={glucoseStatus}>
        <TitleContainer>
          <Row>
            <Title>Glucose Level</Title>
            {showRefreshButton && (
              <RefreshButton onClick={handleClickEnergyBoost}>
                <RefreshIcon src={'/home/refresh.svg'} alt="refresh" />
              </RefreshButton>
            )}
          </Row>
          <Row>
            <TimeText>Last {formatDate(new Date().toISOString())}</TimeText>
          </Row>
        </TitleContainer>
        <GlucoseContainer>
          <NumberContainer>
            <NumberText>{glucoseData.Value}</NumberText>
          </NumberContainer>
          <StatusContainer>
            <StatusText status={glucoseStatus}>{glucoseStatus}</StatusText>
            <UnitText>mg/dL</UnitText>
          </StatusContainer>
        </GlucoseContainer>
      </HeaderContainer>
      <BodyContainer>
        <ProgressBarContainer>
          <DuckIcon
            progress={currentProgress}
            src={'/home/mini_duck.svg'}
            alt="Duck"
          />
          <LocationIconContainer>
            <LocationIcon src={`/home/mini_hawaii.svg`} alt="" />
          </LocationIconContainer>
          <ProgressTrack>
            <ProgressFill progress={currentProgress} />
          </ProgressTrack>
          <LocationContainer>
            <LocationItem>
              <LocationText>
                {WORLDS[currentWorldIndex].displayName}
              </LocationText>
            </LocationItem>
            <LocationItem>
              <LocationText>
                {WORLDS[currentWorldIndex + 1].displayName}
              </LocationText>
            </LocationItem>
          </LocationContainer>
          <SpeedContainer>
            {showSpeed && (
              <>
                <SpeedText>{displayCharacterSpeed}mph Speed</SpeedText>
                <SpeedText>
                  {currentLeftDistance.toFixed(3)} miles left
                </SpeedText>
              </>
            )}
          </SpeedContainer>
        </ProgressBarContainer>
        {showEnergyBoostButton && (
          <EnergyBoostContainer onClick={handleClickEnergyBoost}>
            <SmallIcon src={'/home/energy.svg'} alt="⚡" />
            <EnergyText>Energy Boost</EnergyText>
            <EnergyBoostButton>
              <SmallIcon src={'/home/coin_header.svg'} alt="" />
              <CostText>{numberToGameUnits(energyBoostPrice)}</CostText>
            </EnergyBoostButton>
          </EnergyBoostContainer>
        )}
      </BodyContainer>
    </Div>
  );
};

const Div = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  padding: 0;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 5rem;
  left: 1rem;
`;

const HeaderContainer = styled.div<{ status: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  background-color: ${(props) =>
    props.status === 'Good'
      ? '#C7F4C2'
      : props.status === 'Poor'
        ? '#FFEBD2'
        : '#FFD2D2'};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
  color: #4b4b4f;
`;

const RefreshButton = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
`;

const RefreshIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TimeText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1rem;
  color: #909093;
`;

const GlucoseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const NumberText = styled.p`
  font-size: 34px;
  line-height: 41px;
  font-weight: 700;
  color: #4b4b4f;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatusText = styled.span<{ status: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) =>
    props.status === 'Good'
      ? '#0BAB7C'
      : props.status === 'Poor'
        ? '#E0533D'
        : '#FFB74D'};
  margin-right: 4px;
`;

const UnitText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1rem;
  color: #909093;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  position: relative;
`;

const LocationIconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 20px;
`;
const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 16px;
`;
const LocationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const LocationText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4b4b4f;
`;

const DuckIcon = styled.img<{ progress: number }>`
  position: absolute;
  width: 20px;
  height: 20px;
  object-fit: contain;
  left: ${(props) => props.progress * 100}%;
  top: 4px;
  transform: translateX(-50%);
  z-index: 101;
`;

const LocationIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  object-fit: contain;
`;

const ProgressTrack = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 8px 0;
`;

const ProgressFill = styled.div<{ progress: number }>`
  height: 100%;
  width: ${(props) => props.progress * 100}%;
  background-color: #2e2e33;
  border-radius: 4px;
`;

const SpeedContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const SpeedText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #909093;
`;

const EnergyBoostContainer = styled.div`
  margin-top: 16px;
  padding: 7px 12px;
  background-color: rgba(15, 15, 20, 0.04);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const SmallIcon = styled.img`
  height: 14px;
  width: 14px;
  object-fit: contain;
  margin-right: 4px;
`;

const EnergyBoostButton = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #5f48e6;
  padding: 5px 6px;
  border-radius: 6px;
  cursor: pointer;
`;

const EnergyText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4f;
  margin-left: 4px;
  margin-right: 8px;
`;

const CostText = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: white;
`;

export default OnboardingGameCard;
