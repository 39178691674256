import React, { useEffect } from 'react';
import { formatDate } from 'src/utils/strings';
import styled from 'styled-components';

interface ClockProps {
  time: string;
}

const Clock: React.FC<ClockProps> = ({ time }: ClockProps) => {
  return <ClockContainer>{formatDate(time)}</ClockContainer>;
};

export const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 24px;
  color: white;
  font-weight: bold;
  position: relative;
  top: 20px;
`;

export default Clock;
