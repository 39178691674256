// src/components/LogEntry.tsx
import React, { forwardRef } from 'react';
import { LifelogData } from 'src/models';
import { formatDate, formatFullDate } from 'src/utils/strings';
import styled from 'styled-components';

const LogEntryContainer = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 4px;
  border: ${(props) =>
    props.isHighlighted ? '2px solid var(--Green-500, #0BAB7C)' : ''};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const IconContainer = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  img {
    width: 36px;
    height: 36px;
  }
`;

const LogDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LogTypeTimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LogTypeTitle = styled.p`
  color: var(--Gray-700, var(--Gray-700, #2e2e33));
  text-align: justify;
  /* Label/Medium/Regular */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const LogTime = styled.div`
  color: var(--Gray-350, var(--Gray-350, #b4b4b8));
  text-align: justify;
  /* Caption/Regular */
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const LogDetails = styled.div`
  font-size: 1rem;
  color: #333;
`;

interface LogEntryProps {
  lifelogData: LifelogData;
  isHighlighted: boolean;
}

const LogEntry = forwardRef<HTMLDivElement, LogEntryProps>(
  ({ lifelogData, isHighlighted }, ref) => {
    const { type, datetime, subtype, content } = lifelogData;
    const getIconPath = () => {
      switch (lifelogData.type) {
        case 'meal':
          return '/navigation/gnb_overlay_meal.svg';
        case 'activity':
          return '/navigation/gnb_overlay_activity.svg';
        case 'medicine':
          return '/navigation/gnb_overlay_medication.svg';
        case 'glucoseLevel':
          return '/navigation/gnb_overlay_pulse.svg';
        default:
          return '';
      }
    };

    const isToday = (date: Date) => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    };

    const formattedDate = isToday(new Date(datetime))
      ? formatDate(datetime)
      : formatFullDate(datetime);

    return (
      <LogEntryContainer ref={ref} isHighlighted={isHighlighted}>
        <IconContainer>
          <img src={getIconPath()} alt={`${type} icon`} />
        </IconContainer>
        <LogDetailsContainer>
          <LogTypeTimeContainer>
            <LogTypeTitle>{subtype || type}</LogTypeTitle>
            <LogTime>{formattedDate}</LogTime>
          </LogTypeTimeContainer>
          <LogDetails>{content}</LogDetails>
        </LogDetailsContainer>
      </LogEntryContainer>
    );
  },
);

export default LogEntry;
