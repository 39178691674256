import { create } from 'zustand';

interface State {
  isBottomsheetVisible: boolean;
  isFirstBgmUserPopupVisible: boolean;
  setIsBottomsheetVisible: (visible: boolean) => void;
  setIsFirstBgmUserPopupVisible: (visible: boolean) => void;
}

const useBottomSheetStore = create<State>((set, get) => ({
  isBottomsheetVisible: false,
  isFirstBgmUserPopupVisible: false,
  setIsBottomsheetVisible: (visible: boolean) => {
    set((state) => ({ ...state, isBottomsheetVisible: visible }));
  },
  setIsFirstBgmUserPopupVisible: (visible: boolean) => {
    set((state) => ({ ...state, isFirstBgmUserPopupVisible: visible }));
  },
}));

export default useBottomSheetStore;
