import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled from 'styled-components';

interface EventCardProps {
  setShowEventCard: (showEventCard: boolean) => void;
  onClickEventCard: () => void;
}

export const EventCard: React.FC<EventCardProps> = ({
  setShowEventCard,
  onClickEventCard,
}) => {
  const { user } = useUserStore();
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickLifelogButton = (type: string) => {
    logEvent('sync_event_start', { type: type, user: user });
    navigate(`/lifelog/${type}`);
  };

  return (
    <Div transform={showModal ? '100%' : '150%'}>
      {showModal ? (
        <Modal>
          <LifelogButtonsContainer onClick={(e) => e.stopPropagation()}>
            <LifelogButton
              onClick={() => {
                onClickEventCard();
                handleClickLifelogButton('meal');
              }}
            >
              <LifelogIcon src={'/navigation/gnb_overlay_meal.svg'} alt="" />
              <LifelogText>Add a Meal</LifelogText>
            </LifelogButton>
            <LifelogButton
              onClick={() => {
                onClickEventCard();
                handleClickLifelogButton('activity');
              }}
            >
              <LifelogIcon
                src={'/navigation/gnb_overlay_activity.svg'}
                alt=""
              />
              <LifelogText>Add Activity</LifelogText>
            </LifelogButton>
            <LifelogButton
              onClick={() => {
                onClickEventCard();
                handleClickLifelogButton('medicine');
              }}
            >
              <LifelogIcon
                src={'/navigation/gnb_overlay_medication.svg'}
                alt=""
              />
              <LifelogText>Add Medicine Intake</LifelogText>
            </LifelogButton>
            <LifelogButton
              onClick={() => {
                logEvent('sync_event_idontknow', user);
                setShowEventCard(false);
              }}
            >
              <LifelogIcon
                src={'/navigation/gnb_overlay_idontknow.svg'}
                alt=""
              />
              <LifelogText>I don't know what happened</LifelogText>
            </LifelogButton>
          </LifelogButtonsContainer>
          <ActionButton onClick={() => setShowModal(false)}>
            <ActionIcon src={'/navigation/header_close_light.svg'} alt="" />
            Close
          </ActionButton>
        </Modal>
      ) : (
        <>
          <GuideContainer>
            <GuideText>
              It looks like something might be affecting your glucose levels.
              Please log this event.
            </GuideText>
          </GuideContainer>
          <ActionButton onClick={() => setShowModal(true)}>
            <ActionIcon src={'/navigation/header_plus_light.svg'} alt="" />
            Add Event
          </ActionButton>
        </>
      )}
    </Div>
  );
};

const Div = styled.div<{ transform: string }>`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 100%;
  position: absolute;
  transform: translateY(${(props) => props.transform});
  transition: transform 0.3s ease-in-out;
  z-index: 3000;
`;
const Modal = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;
const LifelogButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  gap: 10px;
`;
const LifelogButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;
const LifelogIcon = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;
const LifelogText = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #4b4b4f;
`;
const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #0bab7c;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #0bab7c4d;
  border-radius: 12px;

  height: 48px;
  padding: 12px 20px;
  margin: 16px auto;
  z-index: 100;

  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
`;
const ActionIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 16px;
`;
const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  /* background: #0f0f1475; */
  background: rgba(15, 15, 20, 0.65);
`;
const GuideText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 8px;
`;
const GuideButton = styled.button`
  border-radius: 8px;
  border: 1px solid #fff;
  outline: none;
  cursor: pointer;
  padding: 5px 24px;
  background-color: transparent;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
`;
