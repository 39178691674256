export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )}:${String(secs).padStart(2, '0')}`;
};

// '5/23/2024 4:58:47 AM'
export function formatDateToString(date: Date): string {
  const pad = (num: number) => (num < 10 ? '0' + num : num);

  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // 0 => 12
  const strHours = pad(hours);

  return `${month}/${day}/${year} ${strHours}:${minutes}:${seconds} ${ampm}`;
}

// "5/26/2024 9:45:52 AM"
// "9:45AM"
export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();

  return hours + ':' + minutesStr + ampm;
}

//2024-06-05T09:08:40.038Z
// "2024-06-05 09:08AM"
export const formatFullDate = (datetime: string) => {
  const date = new Date(datetime);
  const pad = (num: number) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear().toString().slice(-2);
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  let hours = date.getHours();
  const minutes = pad(date.getMinutes());
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strTime = `${pad(hours)}:${minutes}${ampm}`;

  return `${year}-${month}-${day} ${strTime}`;
};
