import { GameState } from 'src/store/useGameStore';

export type User = {
  id: number;
  email: string;
  data: UserData;
};

export const DEFAULT_USER_EMAIL = 'ryuil.dev@gmail.com';

export type LifelogData = {
  type: 'meal' | 'activity' | 'medicine' | 'glucoseLevel';
  subtype?: string;
  datetime: string;
  content: string;
};

export type UserData = {
  lastGlucoseTime: string;
  glucoseGraphData: GlucoseGraphRecord[];
  currentGlucose: GlucoseGraphData | null;
  currentCoins: number;
  lastEarnedDailyGlucoseRewardAt?: number;
  tasks?: Task[];
  lifelogs?: LifelogData[];
  gameState?: GameState | null;
  authTicket?: AuthTicket | null;
};

export type Task = {
  id: string;
  title: string;
  description: string;
  status: string;
  date: string;
};

export type GlucoseGraphRecord = {
  Timestamp: string;
  Value: number;
};

export type GlucoseAnimationRecord = GlucoseGraphRecord & {
  animation: DuckAnimation;
  animationSpeed: number;
  rotate: number;
};

export type DuckAnimation = 'walk_flat' | 'walk_downhill' | 'walk_uphill';

const d = new Date();
const formatted = `${
  d.getMonth() + 1
}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${
  d.getHours() >= 12 ? 'PM' : 'AM'
}`;

export type GlucoseGraphData = {
  FactoryTimestamp: string;
  Timestamp: string; //"3/23/2024 4:06:06 PM";
  type: number; //1;
  ValueInMgPerDl: number; //97;
  TrendArrow?: number; //3;
  TrendMessage?: null | string; // null;
  MeasurementColor: number; //1;
  GlucoseUnits: number; //1;
  Value: number; //97;
  isHigh: boolean;
  isLow: boolean;
};

export type Pet = {
  image: string;
  timeInRange: number;
  lastEaredDailyGlucoseRewardAt: number;
  balance: number;
  remainedMissionSeconds: number;
  libreLink?: {
    glucoseMeasurement?: GlucoseGraphData;
    glucoseGraphData?: GlucoseGraphData[];
  };
  id: string;
  name: string;
  email: string;
  password: string;
  role: string;
  social_id: string;
  image_url: string;
  phone_number: string;
  imp_uid: string;
  address: string;
};

export type PetState = {
  petName: string;
  currentLevel: number;
  currentXP: number;
  currentMaxXP: number;
  currentPetImage: string;
  currentPetDimed: boolean;
  messages?: PetMessage[];
};

export type PetMessage = {
  text?: string;
  url?: string;
};

export type LoginResponse = {
  status: number;
  data?: {
    authTicket: AuthTicket;
  };
  message?: string;
};

export type AuthTicket = {
  token: string;
  expires: number;
  duration: number;
};
