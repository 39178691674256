import React, { useRef, useEffect } from 'react';
import { OnboardingNextProps, OnboardingQueryParams } from '.';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionButton } from './EntrancePage';
import styled, { css, keyframes } from 'styled-components';
import useGameStore from 'src/store/useGameStore';
import { logEvent } from 'src/utils';
import { signupAnonymous } from 'src/api/UserApiService';

const tiltAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(-5deg);
  }
  50% {
    transform: translateY(0) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(-5deg);
  }
`;

const PageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 100dvh;
  width: 100%;
  justify-content: flex-start;

  background: url('/onboarding/background-forest-day.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  // gradient to bottom
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    url('/onboarding/background-forest-day.png');
`;

const Title = styled.h1`
  font-size: 1.75rem;
  color: white;
  color: #333;
  margin-top: 7.5rem;
`;

const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 0.75rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  outline: none;
  border: 1px solid var(--Gray-300, #dbdde1);
  background: var(--White, #fff);
  font-size: 1rem; // Responsive font size
  margin-top: 2rem;

  text-align: left;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    border: 1px solid var(--Green-400, #13cd97);
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
  width: calc(100% - 3rem);
  margin: 0 auto;
`;

const ImageView = styled.img`
  display: flex;
  width: 6.40663rem;
  height: 8.342rem;
  object-fit: contain;
  z-index: 10;
  margin: 1rem auto;
  animation: ${css`
    ${tiltAnimation} 1s infinite ease-in-out
  `};
`;

const NameYourPetPage = ({ onClickNext }: OnboardingNextProps) => {
  // const [nameInput, setNameInput] = React.useState('');
  // const inputRef = useRef<HTMLInputElement>(null);
  const { setPetName } = useGameStore();

  useEffect(() => {
    logEvent('view_web_onboarding_name', {});
  }, []);

  // useEffect(() => {
  //   inputRef.current?.focus();
  // }, []);

  // const handleOnInputChange = () => {
  //   setNameInput(inputRef.current!.value);
  // };

  const handleNext = () => {
    // set pet name
    // logEvent('onboarding_submit_name', {
    //   petName: nameInput,
    // });
    const randomString = Math.random().toString(36).substring(2, 8);
    setPetName(randomString);
    onClickNext();
  };

  return (
    <PageContainer>
      <Title>
        {/* Hi! I'm your newest diabetes buddy. Could you give me a name? */}
        "What if managing diabetes could be fun?"
        <br />
        <br />a game developed by a medical doctor.
      </Title>
      {/* <Input
        ref={inputRef}
        type="text"
        placeholder="Enter a name of your duck"
        onChange={handleOnInputChange}
      /> */}
      <BottomContainer>
        <ImageView src="/onboarding/duck_front.png" alt="Duck" />
        <ActionButton
          onClick={handleNext}
          // disabled={!nameInput}
          isAnimated={false}
        >
          {/* I'll call you {nameInput.trim().length > 0 ? nameInput : '(?)'} */}
          Start Game
        </ActionButton>
      </BottomContainer>
    </PageContainer>
  );
};

export default NameYourPetPage;
