import React from 'react';
import { useNavigate } from 'react-router-dom';
import useGameStore from 'src/store/useGameStore';
import { numberToGameUnits } from 'src/utils/game';
import styled from 'styled-components';

export const ShopHeader = () => {
  const { coins } = useGameStore();
  const navigate = useNavigate();
  const handleClickCoin = () => {};
  const handleClickClose = () => {
    navigate('/');
  };

  return (
    <Div>
      <CoinContainer onClick={handleClickCoin}>
        <CoinIcon src={'/shop/coin.svg'} alt="" />
        <CoinText>{numberToGameUnits(coins)}</CoinText>
      </CoinContainer>
      <CloseContainer onClick={handleClickClose}>
        <CloseIcon src={'/shop/close_purple.svg'} alt="" />
      </CloseContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: transparent;
  z-index: 100;
`;
const CoinContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  height: 2rem;
  width: 6rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-left: 0.5rem;
  cursor: pointer;
  border: none;
  outline: none;
`;
const CoinIcon = styled.img`
  position: absolute;
  top: -0.25rem;
  left: -0.5rem;
  z-index: 101;
  width: 2.5rem;
  height: 2.5rem;
`;
const CoinText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: black;
`;
const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
  width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
  border: none;
  outline: none;
`;
const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
