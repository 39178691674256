import React from 'react';
import styled from 'styled-components';

interface CircleProfileProps {
  imageUrl: string;
  size: number; // Size in pixels
  margin?: number;
}

const CircleProfile: React.FC<CircleProfileProps> = ({
  imageUrl,
  size,
  margin,
}) => {
  return <StyledProfile size={size} imageUrl={imageUrl} margin={margin} />;
};

export default CircleProfile;

const StyledProfile = styled.div<{
  size: number;
  imageUrl: string;
  margin?: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: ${({ margin }) => margin || 0}px;
`;
