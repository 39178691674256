import { Container, Sprite, Texture, Ticker, TilingSprite } from 'pixi.js';
import { World } from 'src/store/useGameStore';

// Class for handling the environment.
export class Scene {
  view: any;
  ticker: any;
  currentAngle: number;
  targetAngle: number;
  sky: Sprite;
  scale: number;
  backgroundSpeed: number;
  backgroundLayer1: TilingSprite;
  backgroundLayer2: TilingSprite;
  backgroundLayer3: TilingSprite;
  platform: TilingSprite;
  planet: TilingSprite;
  floorHeight: number;
  mainContainer: Container;

  constructor(width: number, height: number) {
    // Create a main view that holds all layers.
    this.view = new Container();
    this.mainContainer = new Container();
    this.ticker = new Ticker();
    this.currentAngle = 0;
    this.targetAngle = 0;
    this.ticker.add(this.updateRotation, this);
    this.ticker.start();
    this.backgroundSpeed = 0.1;

    // Create the stationary sky that fills the entire screen.
    this.sky = Sprite.from('sky_day');
    this.sky.anchor.set(0, 1);
    this.sky.width = width;
    this.sky.height = height;

    // Create textures for the background, mid-ground, and platform.
    const backgroundLayer1 = Texture.from('flower_sprout_day');
    const backgroundLayer2 = Texture.from('bush_sprout_day');
    const backgroundLayer3 = Texture.from('tree_sprout_day');
    const backgroundPlanet = Texture.from('sun');
    const platformTexture = Texture.from('grass_day');

    // Calculate the ideal platform height depending on the passed-in screen height.
    const maxPlatformHeight = Math.min(platformTexture.height, height * 0.7);
    const platformHeight = height * 0.75;

    // Calculate the scale to be apply to all tiling textures for consistency.
    const scale = (this.scale = platformHeight / maxPlatformHeight);

    const baseOptions = {
      tileScale: { x: scale, y: scale },
      anchor: { x: 0, y: 1 },
      applyAnchorToTexture: true,
    };

    // Create the tiling sprite layers.
    this.backgroundLayer1 = new TilingSprite({
      texture: backgroundLayer1,
      width: width * 3.5,
      height: backgroundLayer1.height,
      ...baseOptions,
    });
    this.backgroundLayer2 = new TilingSprite({
      texture: backgroundLayer2,
      width: width * 3.5,
      height: backgroundLayer2.height,
      ...baseOptions,
    });
    this.backgroundLayer3 = new TilingSprite({
      texture: backgroundLayer3,
      width: width * 3.5,
      height: backgroundLayer3.height,
      ...baseOptions,
    });
    this.platform = new TilingSprite({
      texture: platformTexture,
      width: width * 4,
      height: platformHeight,
      ...baseOptions,
    });

    this.planet = new TilingSprite({
      texture: backgroundPlanet,
      width: width,
      height: backgroundPlanet.height * 2,
      ...baseOptions,
    });
    // Calculate the floor height for external referencing.
    this.floorHeight = platformHeight;

    // Add all layers to the main container.
    this.mainContainer.addChild(
      this.platform,
      this.backgroundLayer3,
      this.backgroundLayer1,
      this.backgroundLayer2,
    );

    // Set the position of the main container to ensure that the content can be rendered beyond the X=0 boundary.
    this.mainContainer.x = -width;
    this.mainContainer.y = 130;

    this.planet.y = -this.floorHeight * 0.5;

    // Add the sky and main container to the view.
    this.view.addChild(this.sky, this.planet, this.mainContainer);
  }

  rotatePlatform(angleDegrees: number) {
    this.targetAngle = (angleDegrees * Math.PI) / 180;
  }

  private updateRotation(delta: number) {
    const rotationSpeed = 0.02; // Adjust this value to change the speed of rotation

    if (Math.abs(this.targetAngle - this.currentAngle) > rotationSpeed) {
      const deltaAngle = this.targetAngle - this.currentAngle;
      this.currentAngle += deltaAngle > 0 ? rotationSpeed : -rotationSpeed;

      this.applyRotation(this.currentAngle);
    } else {
      this.applyRotation(this.targetAngle);
      this.currentAngle = this.targetAngle; // Ensure the angles are perfectly aligned
    }
  }

  private applyRotation(angleRadians: number) {
    const centerX = this.backgroundLayer1.width / 2;
    const centerY = 50;

    const backgroundCenterX = this.backgroundLayer1.width / 2;
    const midgroundCenterX = this.backgroundLayer2.width / 2;
    const layer3CenterX = this.backgroundLayer3.width / 2;

    // 피벗을 platform의 가로 길이 중앙으로 설정하고, 세로 길이는 전체 높이를 기준으로 설정하여 X축을 기준으로 회전하도록 합니다.
    this.platform.pivot.x = centerX * 1.5; // 가로 중앙
    this.platform.pivot.y = 50; // 세로 길이 (바닥)

    this.backgroundLayer1.pivot.x = this.platform.width / 2;
    this.backgroundLayer1.pivot.y = this.floorHeight / 5;

    this.backgroundLayer2.pivot.x = this.platform.width / 2;
    this.backgroundLayer2.pivot.y = this.floorHeight / 5;

    this.backgroundLayer3.pivot.x = this.platform.width / 2;
    this.backgroundLayer3.pivot.y = this.floorHeight / 5 - 10;

    // 피벗 위치 조정으로 인한 X 위치 보정
    this.platform.x += this.platform.width / 2;
    this.backgroundLayer1.x += this.platform.width / 2;
    this.backgroundLayer2.x += this.platform.width / 2;
    this.backgroundLayer3.x += this.platform.width / 2;

    // 회전 적용
    this.platform.rotation = angleRadians;
    this.backgroundLayer1.rotation = angleRadians;
    this.backgroundLayer2.rotation = angleRadians;
    this.backgroundLayer3.rotation = angleRadians;

    this.platform.x = centerX;
    this.platform.y = centerY;

    this.backgroundLayer1.x = backgroundCenterX;
    this.backgroundLayer1.y = centerY;

    this.backgroundLayer2.x = midgroundCenterX;
    this.backgroundLayer2.y = centerY;

    this.backgroundLayer3.x = layer3CenterX;
    this.backgroundLayer3.y = centerY;
  }

  toggleVisibility(layer: 'background' | 'midground' | 'platform') {
    switch (layer) {
      case 'background':
        this.backgroundLayer1.visible = !this.backgroundLayer1.visible;
        break;
      case 'midground':
        this.backgroundLayer2.visible = !this.backgroundLayer2.visible;
        break;
      case 'platform':
        this.platform.visible = !this.platform.visible;
        break;
    }
  }

  setBackgroundSpeed(speed: number) {
    this.backgroundSpeed = speed;
  }

  setBackgroundLayers(next: World['sprites']) {
    const {
      bgLayers: nextLayers,
      sky: nextSky,
      planet: nextPlanet,
      platform: nextPlatform,
    } = next;

    this.sky.texture = Texture.from(nextSky);
    this.sky.anchor.set(0, 1);
    this.sky.width = this.view.width;
    this.sky.height = this.view.height;
    this.platform.texture = Texture.from(nextPlatform);
    this.planet.texture = Texture.from(nextPlanet);

    // Create textures from the given file names and assign them to the appropriate layers
    if (nextLayers.length > 0) {
      const texture1 = Texture.from(nextLayers[0]);
      this.backgroundLayer1.texture = texture1;
    }
    if (nextLayers.length > 1) {
      const texture2 = Texture.from(nextLayers[1]);
      this.backgroundLayer2.texture = texture2;
    }
    if (nextLayers.length > 2) {
      const texture3 = Texture.from(nextLayers[2]);
      this.backgroundLayer3.texture = texture3;
    }
  }

  // Use the platform's horizontal position as the key position for the scene.
  get positionX() {
    return this.platform.tilePosition.x;
  }

  // Set the horizontal position of the platform layer while applying parallax scrolling to the backdrop layers.
  set positionX(value) {
    value -= this.backgroundSpeed;
    this.backgroundLayer1.tilePosition.x = value * 0.45;
    this.backgroundLayer2.tilePosition.x = value * 0.5;
    this.backgroundLayer3.tilePosition.x = value * 0.4;
    this.planet.tilePosition.x = value * 0.15;
    this.platform.tilePosition.x = value;
  }

  get platformHeight() {
    return this.platform.height;
  }
}
