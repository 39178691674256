// App.js
import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface JourneyRewardContentProps {
  message: ReactElement;
  buttonMessage: string;
  handleClickButton: () => void;
}

export const JourneyRewardContent: React.FC<JourneyRewardContentProps> = ({
  message,
  buttonMessage,
  handleClickButton,
}) => {
  return (
    <Container>
      <Icon
        src="/home/coin_header.svg"
        width={64}
        height={64}
        alt="Gold Icon"
      />
      <Message>{message}</Message>
      <Button onClick={handleClickButton}>{buttonMessage}</Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Icon = styled.img`
  margin-bottom: 20px;
`;

const Message = styled.p`
  color: var(--Gray-700, var(--Gray-700, #2e2e33));
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;

  span {
    color: var(--Green-500, #0bab7c);
    font-family: Rubik;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem;
  }
`;

const Button = styled.button`
  background: var(--Green-500, #0bab7c);
  color: white;
  font-size: 24px;
  font-weight: bold;
  border: none;
  border-radius: 14px;
  padding: 16px 24px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
`;
