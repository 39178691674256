import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TimeScale,
} from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { GlucoseGraphRecord } from 'src/models';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const GreenBoxPlugin = {
  id: 'greenBoxPlugin',
  beforeDraw: (chart: any, args: any, options: any) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right },
      scales: { y },
    } = chart;
    const { minY, maxY } = options;

    ctx.save();
    ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
    ctx.fillRect(
      left,
      y.getPixelForValue(maxY),
      right - left,
      y.getPixelForValue(minY) - y.getPixelForValue(maxY),
    );
    ctx.restore();
  },
};

export default function ReportGlucoseCharts({
  glucoseData,
  onDataPointChange,
}: {
  glucoseData: GlucoseGraphRecord[];
  onDataPointChange: (point: any) => void;
}) {
  const [graphData, setData] = useState<GlucoseGraphRecord[]>([]);
  const [highlightIndex, setHighlightIndex] = useState<number>(
    glucoseData.length - 1,
  );
  const chartRef = useRef(null);

  useEffect(() => {
    const filledData = glucoseData?.map((data) => ({
      Timestamp: data.Timestamp,
      Value: data.Value,
    }));

    setData(filledData);
  }, [glucoseData]);

  const handleClick = (event: any, elements: any) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      setHighlightIndex(index);
    }
  };

  const formatTooltipLabel = (tooltipItem: any) => {
    const dataIndex = tooltipItem.dataIndex;
    const data = graphData[dataIndex];
    const date = new Date(data.Timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return data.Value;
  };

  const data = {
    labels: graphData?.map((data) => formatTimeLabel(data.Timestamp)),
    datasets: [
      {
        data: graphData?.map((data) => data.Value),
        fill: true,
        borderColor: '#0BAB7C',
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 6,
        pointBackgroundColor: '#0BAB7C',
        pointHoverBackgroundColor: '#0BAB7C',
        pointBorderColor: '#0BAB7C',
        pointHoverBorderColor: '#0BAB7C',
      },
    ],
  };

  const options: ChartOptions<'line'> | any = {
    animation: false,
    scales: {
      y: {
        beginAtZero: false,
        display: true,
        min: 30,
        suggestedMax: 200,
        ticks: {
          maxTicksLimit: 5,
        },
      },
      x: {
        display: true,
        ticks: {
          maxTicksLimit: 4,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      greenBoxPlugin: {
        minY: 70,
        maxY: 180,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            formatTooltipLabel(context);
            if (shouldUpdateTooltip(context)) {
              onDataPointChange(context);
            }
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 6,
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    onClick: handleClick,
    onHover: (event: any, elements: any) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        setHighlightIndex(index);
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{ position: 'relative', height: '300px' }}>
      <Line
        ref={chartRef}
        data={data}
        options={options}
        plugins={[GreenBoxPlugin]}
      />
    </div>
  );
}

const formatTimeLabel = (timestamp: string) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours}:${minutes} ${ampm}`;
};

let lastTooltipData: any = null;

function shouldUpdateTooltip(newData: any) {
  if (
    lastTooltipData === null ||
    lastTooltipData.label !== newData.label ||
    lastTooltipData.value !== newData.value
  ) {
    lastTooltipData = newData;
    return true;
  }
  return false;
}
