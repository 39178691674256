// src/store/useGameStore.ts
import { create } from 'zustand';
import { Scene } from '../pages/Journey/Scene';
import { DuckAnimation } from 'src/models';

interface GameState {
  animationState: DuckAnimation;
  backgroundSlot: string[];
  characterSpeed: number;
  backgroundSpeed: number;
  scene?: Scene;
  setAnimationState: (animationState: GameState['animationState']) => void;
  setSpeed: (speed: number) => void;
  setChracterSpeed: (speed: number) => void;
  setBacgroundSpeed: (speed: number) => void;
  setScene: (scene: Scene) => void;
  handleAnimation: (animationState: GameState['animationState']) => void;
  journeyStatusCurrentIndex: number;
  setJourneyStatusCurrentIndex: (index: number) => void;
  reset: () => void;
}

const DEFAULT_CHARACTER_SPEED = 1;
const DEFAULT_BACKGROUND_SPEED = 1;

const useJourneyStore = create<GameState>((set, get) => ({
  animationState: 'walk_flat',
  journeyStatusCurrentIndex: 0,
  backgroundSlot: [],
  characterSpeed: DEFAULT_CHARACTER_SPEED,
  backgroundSpeed: DEFAULT_BACKGROUND_SPEED,
  scene: undefined,
  setAnimationState: (animationState) =>
    set((state) => ({ ...state, animationState: animationState })),
  setChracterSpeed: (speed) => {
    set((state) => ({
      ...state,
      characterSpeed: speed,
    }));
  },
  setSpeed: (speed: number) => {
    const { backgroundSpeed } = get();
    if (backgroundSpeed === speed) return;
    set((state) => {
      state.backgroundSpeed = speed;
      state.characterSpeed = speed;
      return {
        ...state,
      };
    });
  },
  setBacgroundSpeed: (speed) => {
    const { scene } = get();
    if (!scene) return;
    scene.setBackgroundSpeed(speed / 2);
    set((state) => ({
      scene,
      backgroundSpeed: speed / 2,
    }));
  },
  setScene: (scene) => set((state) => ({ ...state, scene })),
  handleAnimation: (animationState) => {
    const { scene } = get();
    if (!scene) return;
    let rotatePlatformDegree = 0;
    if (animationState === 'walk_uphill') {
      rotatePlatformDegree = -20;
    }
    if (animationState === 'walk_downhill') {
      rotatePlatformDegree = 20;
    }
    scene.rotatePlatform(rotatePlatformDegree);
    set((state) => {
      state.animationState = animationState;
      return {
        ...state,
      };
    });
  },
  reset: () =>
    set((state) => ({
      animationState: 'walk_flat',
      journeyStatusCurrentIndex: 0,
      backgroundSlot: [],
      characterSpeed: DEFAULT_CHARACTER_SPEED,
      backgroundSpeed: DEFAULT_BACKGROUND_SPEED,
      scene: undefined,
    })),
  setJourneyStatusCurrentIndex: (index) =>
    set((state) => ({ ...state, journeyStatusCurrentIndex: index })),
}));

export default useJourneyStore;
