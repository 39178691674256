import React from 'react';
import { WORLDS } from 'src/models/worlds';
import { ActionButton } from 'src/pages/Onboarding/EntrancePage';
import useUserStore from 'src/store/useUserStore';
import { logEvent } from 'src/utils';
import styled from 'styled-components';

interface NextWorldPopupProps {
  currentWorldIndex: number;
  totalDistance: number;
  tickCoins: number;
  handler: () => void;
}

export const NextWorldPopup: React.FC<NextWorldPopupProps> = ({
  currentWorldIndex,
  totalDistance,
  tickCoins,
  handler,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const currentSprites = WORLDS[currentWorldIndex - 1].sprites;
  const nextSprites = WORLDS[currentWorldIndex]?.sprites || currentSprites;
  const spriteChanges = compareSprites(currentSprites, nextSprites);
  const { user } = useUserStore();

  return (
    <Div>
      <Text>Move to {WORLDS[currentWorldIndex].displayName}</Text>
      <SubText>Traveled {totalDistance.toFixed(3)} miles to get here</SubText>
      <IconTextContainer>
        <Image src={'/home/coin_header.svg'} alt="" width={24} height={24} />{' '}
        {((tickCoins * 2 * 6) / 1.1).toFixed(2)}/s
        <Image
          style={{
            rotate: '180deg',
          }}
          src={'/icons/icon-left-arrow.svg'}
          alt=""
          width={36}
          height={36}
        ></Image>{' '}
        <Image src={'/home/coin_header.svg'} alt="" width={24} height={24} />{' '}
        {(tickCoins * 2 * 6).toFixed(2)}/s
      </IconTextContainer>
      {spriteChanges.map((change, index) => (
        <IconTextContainer key={index}>
          <Image
            src={`/sprites/background-icons/${change.current}.svg`}
            alt=""
          />
          <Image
            style={{ transform: 'rotate(180deg)' }}
            src={'/icons/icon-left-arrow.svg'}
            width={36}
            height={36}
            alt=""
          />
          <Image src={`/sprites/background-icons/${change.next}.svg`} alt="" />
        </IconTextContainer>
      ))}
      <ActionButton
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);
          logEvent('world_complete', {
            ...user,
            currentWorldIndex,
            totalDistance,
            tickCoins,
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 5000);
          handler();
        }}
      >
        Start New World
      </ActionButton>
    </Div>
  );
};
interface SpriteChange {
  current: string;
  next: string;
}

const compareSprites = (
  currentSprites: any,
  nextSprites: any,
): SpriteChange[] => {
  const changes: SpriteChange[] = [];
  const spriteKeys = Object.keys(currentSprites);

  spriteKeys.forEach((key) => {
    if (Array.isArray(currentSprites[key])) {
      currentSprites[key].forEach((sprite: string, index: number) => {
        if (sprite !== nextSprites[key][index]) {
          changes.push({
            current: sprite,
            next: nextSprites[key][index],
          });
        }
      });
    } else if (
      key !== 'sky' &&
      key !== 'platform' &&
      currentSprites[key] !== nextSprites[key]
    ) {
      changes.push({
        current: currentSprites[key],
        next: nextSprites[key],
      });
    }
  });

  return changes;
};

const Div = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 15%;
  max-width: 100%;
  width: 90%;
  margin: 20px;
  padding: 20px;
  z-index: 102;
  border-radius: 13px;
  border: 1px solid #fff;
  background: #f6dbb4;
  box-shadow:
    0px 4px 4px 0px #eecaa2 inset,
    0px 3px 3px 0px rgba(255, 255, 255, 0.6);
`;
const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #0bab7c;
  margin-bottom: 16px;
`;

const IconTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  color: #0bab7c;
  margin-bottom: 8px;
`;

const SubText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  color: #0bab7c;
  margin-bottom: 16px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 60px; /* 최대 높이를 제한합니다 */
  margin: 0 8px; /* 이미지 간격을 조정합니다 */
`;
