import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import CharacterProfilePage from './CharacterProfilePage';

const CharacterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
`;

export type CharacterNextProps = {
  onClickNext: () => void;
};

export type CharacterQueryParams = {
  petGender: 'male' | 'female' | 'non-binary' | undefined;
  petName: string | undefined;
  petBirthDay: string | undefined; // Treat dates as strings
  personality: string | undefined;
  userOnlineGoal: string | undefined;
};

const Character = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const query = useParams as Partial<CharacterQueryParams>;

  useEffect(() => {
    const allFieldsPresent = Object.values(query).every(
      (value) => value !== undefined,
    );
    if (allFieldsPresent) {
      console.log(query);
    }
  }, [query]);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
  };

  const pages = [<CharacterProfilePage onClickNext={goToNextPage} />];

  return <CharacterContainer>{pages[currentPage]}</CharacterContainer>;
};

export default Character;
