import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  handleSubmit: () => void;
  isLoading?: boolean;
}

export const LogSubmitButton: React.FC<Props> = ({
  handleSubmit,
  isLoading,
}) => {
  return (
    <Button onClick={handleSubmit} isLoading={isLoading}>
      Add
    </Button>
  );
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Button = styled.button<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #0bab7c;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px #0bab7c4d;
  border-radius: 14px;

  width: calc(100% - 2rem);
  height: 56px;
  padding: 16px 24px;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 100;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #fbfaf3;

  ${(props) =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      color: transparent; // 텍스트를 숨깁니다.
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-top: 2px solid white;
        border-radius: 50%;
        animation: ${spin} 1s linear infinite;
      }
    `}
`;
