import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import LogEntry from './components/LogEntry';
import ReportGlucoseCharts from './components/ReportGlucoseCharts';
import { GNB } from 'src/components/common/GNB';
import useUserStore from 'src/store/useUserStore';
import { GlucoseGraphRecord, LifelogData } from 'src/models';
import { getLifelog } from 'src/api/LifelogService';
import { getLocalStorageEmail } from 'src/storage/user.localstorage';

const AppContainer = styled.div`
  background: var(--Ivory, #fbfaf3);
  height: 100vh;
  width: 100vw;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
`;

const Header = styled.header`
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
`;

const Title = styled.p<{ fontSize: string }>`
  color: var(--Gray-600, #4b4b4f);
  font-family: Rubik;
  font-size: ${(props) => props.fontSize};
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 16px;
`;

const ChartsContainer = styled.div`
  height: 316px; /* Adjust this height as needed */
  margin-bottom: 16px;
  flex-shrink: 0;
`;

const Tabs = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  button {
    white-space: nowrap;
    margin-right: 0.5rem;
    padding: 5px 12px;
    border: none;
    background: var(--Gray-Opacity-150, rgba(15, 15, 20, 0.07));
    cursor: pointer;
    border-radius: 8px;

    color: var(--Green-800, var(--Green-800, #00422f));
    text-align: justify;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    &.active {
      background: var(--Green-800, #00422f);
      color: var(--Gray-white, var(--Gray-White, #fff));
    }
  }
`;

const LogContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 50px;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
`;

const ReportPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('1day');
  const [selectedDataPoint, setSelectedDataPoint] = useState<number | null>(
    null,
  );
  const [lifelogs, setLifelogs] = useState<LifelogData[]>([]);
  const [highlightedLogs, setHighlightedLogs] = useState<any[]>([]);
  const { user } = useUserStore();

  const glucoseData = useMemo(() => {
    const cgmDatas = user?.data?.glucoseGraphData?.map((data) => ({
      Timestamp: data.Timestamp,
      Value: data.Value,
    }));
    const bgmDatas = lifelogs
      ?.filter((log) => log.type === 'glucoseLevel')
      .map(
        (log) =>
          ({
            Timestamp: log.datetime,
            Value: parseFloat(log.content.trim()),
          }) as GlucoseGraphRecord,
      );

    if (cgmDatas?.length) {
      return cgmDatas;
    } else if (bgmDatas?.length) {
      return bgmDatas;
    } else {
      return [];
    }
  }, [lifelogs, user?.data?.glucoseGraphData]);

  const tabs = ['1day', '7days', '14days', '30days', '60days'];

  const highlightedLogRefs = useRef<(HTMLDivElement | null)[]>([]);

  const filterGlucoseData = (tab: string) => {
    const now = new Date();
    let startDate = new Date();

    switch (tab) {
      case '1day':
        startDate.setDate(now.getDate() - 1);
        break;
      case '7days':
        startDate.setDate(now.getDate() - 7);
        break;
      case '14days':
        startDate.setDate(now.getDate() - 14);
        break;
      case '30days':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case '60days':
        startDate.setMonth(now.getMonth() - 2);
        break;
      default:
        startDate = now;
    }

    return glucoseData.filter((data) => new Date(data.Timestamp) >= startDate);
  };

  const filteredGlucoseData = filterGlucoseData(selectedTab);

  const handleDataPointChange = (point: any) => {
    setSelectedDataPoint(point.dataIndex);
  };

  useEffect(() => {
    const newHighlightedLogs = lifelogs.map((log) => {
      const logTime = new Date(log.datetime);
      const selectedPointTime =
        selectedDataPoint !== null
          ? new Date(glucoseData[selectedDataPoint].Timestamp)
          : null;
      const isHighlight =
        selectedPointTime &&
        Math.abs(logTime.getTime() - selectedPointTime.getTime()) <=
          30 * 60 * 1000; // 30 minutes
      return { ...log, isHighlight };
    });
    setHighlightedLogs(newHighlightedLogs);
  }, [selectedDataPoint, glucoseData]);

  useEffect(() => {
    const email = getLocalStorageEmail();
    const get = async () => {
      if (email) {
        const result = await getLifelog(email, 'all');
        setLifelogs(result);
      }
    };
    get();
  }, []);

  useEffect(() => {
    if (selectedDataPoint !== null) {
      const highlightedIndex = highlightedLogs.findIndex(
        (log) => log.isHighlight,
      );
      if (
        highlightedIndex !== -1 &&
        highlightedLogRefs.current[highlightedIndex]
      ) {
        const ref = highlightedLogRefs.current[
          Math.min(
            highlightedIndex === 0 ? highlightedIndex : highlightedIndex + 1,
            highlightedLogRefs.current.length - 1,
          )
        ] as any;
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [selectedDataPoint, highlightedLogs]);

  return (
    <AppContainer>
      <Header>
        <Title fontSize="20px">Glucose Chart</Title>
      </Header>
      <ChartsContainer>
        <Tabs>
          {tabs.map((tab) => (
            <button
              key={tab}
              className={selectedTab === tab ? 'active' : ''}
              onClick={() => setSelectedTab(tab)}
            >
              {tab.replace('day', ' day').replace('days', ' days')}
            </button>
          ))}
        </Tabs>
        <ReportGlucoseCharts
          glucoseData={filteredGlucoseData}
          onDataPointChange={handleDataPointChange}
        />
      </ChartsContainer>
      <Title style={{ marginTop: '16px' }} fontSize="16px">
        Your Log
      </Title>
      <LogContainer>
        {highlightedLogs
          .sort((o1, o2) => {
            const date1 = new Date(o1.datetime);
            const date2 = new Date(o2.datetime);
            return date2.getTime() - date1.getTime();
          })
          .map((log, index) => (
            <LogEntry
              key={index}
              ref={(el) => (highlightedLogRefs.current[index] = el)}
              lifelogData={log}
              isHighlighted={log.isHighlight || false}
            />
          ))}
      </LogContainer>
      <GNB />
    </AppContainer>
  );
};

export default ReportPage;
