import { create } from 'zustand';

export interface Mission {
  id: number;
  title: string;
  reward: number;
  currentProgress: number;
  maxProgress: number;
  completed: boolean;
  claimedReward: boolean;
}

export interface Pet {
  name: string;
  birthday: Date;
  level: number;
  happiness: 100;
  hunger: 0;
}

interface State {
  xp: number;
  coins: number;
  evolutionCoins: number;
  missions: Mission[];
  journeyActive: boolean;
  journeyEndTime: number | null; // UNIX timestamp for when the journey ends
  addXp: (amount: number) => void;
  addCoins: (amount: number) => void;
  addEvolutionCoins: (amount: number) => void;
  completeMission: (missionId: number) => void;
  updateMission: (missionId: number, newMissionObject: Mission) => void;
  startJourney: (duration: number) => void; // duration in milliseconds
  endJourney: () => void;
  pet: Pet;
  updatePet: (newPetObject: Pet) => void;
  dailyStreak: number;
  maxDailyStreak: number;
  updateDailyStreak: (newStreak: number) => void;
  updateMaxDailyStreak: (newMaxStreak: number) => void;
}

const usePlayerStore = create<State>((set) => ({
  xp: 0,
  coins: 0,
  evolutionCoins: 0,
  missions: [
    {
      id: 1,
      title: 'Check glucose 4 times',
      reward: 250,
      currentProgress: 2,
      maxProgress: 4,
      completed: false,
      claimedReward: false,
    },
    {
      id: 3,
      title: 'Log what you ate today',
      reward: 500,
      currentProgress: 3,
      maxProgress: 3,
      completed: true,
      claimedReward: true,
    },
    {
      id: 2,
      title: 'Drink water',
      reward: 250,
      currentProgress: 0,
      maxProgress: 1,
      completed: false,
      claimedReward: false,
    },
    {
      id: 4,
      title: 'Exercise for 20 min',
      reward: 250,
      currentProgress: 1,
      maxProgress: 1,
      completed: true,
      claimedReward: false,
    },
  ],
  journeyActive: false,
  journeyEndTime: null,
  addXp: (amount) =>
    set((state) => ({
      ...state,
      xp: state.xp + amount,
    })),
  addCoins: (amount) =>
    set((state) => ({
      ...state,
      coins: state.coins + amount,
    })),
  addEvolutionCoins: (amount) =>
    set((state) => ({
      ...state,
      coins: state.evolutionCoins + amount,
    })),
  completeMission: (missionId) =>
    set((state) => {
      const mission = state.missions.find((m) => m.id === missionId);
      if (mission && !mission.completed) {
        mission.completed = true;
        state.xp += 50; // Assume completing a mission always gives 50 XP
      }
      return state;
    }),
  updateMission: (missionId, newMissionObject) =>
    set((state) => {
      const missions = [...state.missions]; // Create a shallow copy of missions
      const missionIndex = missions.findIndex((m) => m.id === missionId);
      if (missionIndex !== -1) {
        missions[missionIndex] = {
          ...missions[missionIndex],
          ...newMissionObject,
        };
      }
      return { ...state, missions }; // Return new state object
    }),
  startJourney: (duration) =>
    set((state) => {
      state.journeyActive = true;
      state.journeyEndTime = Date.now() + duration;
      return state;
    }),
  endJourney: () =>
    set((state) => {
      state.journeyActive = false;
      state.journeyEndTime = null;
      return state;
    }),
  pet: {
    name: 'Lucy',
    birthday: new Date('2021-01-01'),
    happiness: 100,
    hunger: 0,
    level: 1,
  },
  updatePet: (newPetObject) =>
    set((state) => ({
      ...state,
      pet: { ...state.pet, ...newPetObject },
    })),
  dailyStreak: 1,
  maxDailyStreak: 1,
  updateDailyStreak: (newStreak) =>
    set((state) => ({
      ...state,
      dailyStreak: newStreak,
    })),
  updateMaxDailyStreak: (newMaxStreak) =>
    set((state) => ({
      ...state,
      maxDailyStreak: newMaxStreak,
    })),
}));

export default usePlayerStore;
